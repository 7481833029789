var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import objectArraySort from '../../../../utils/objectArraySort';
import ItemChoosePresentationType7ProductComponent from './Item';
import ContinueButtonChoosePresentationType7ProductComponent from './ContinueButton';
var ChoosePresentationType7ProductComponent = function (props) {
    var _a = useState(null), productPresentationId = _a[0], setProductPresentationId = _a[1];
    var handleClick = function (id) {
        setProductPresentationId(id);
    };
    return (React.createElement("div", { className: 'px-2 sm:px-20 py-6' },
        React.createElement("div", { className: 'text-2xl' }, props.Product.name),
        React.createElement("div", { className: 'flex flex-row my-5 flex-wrap' }, objectArraySort(__spreadArray([], props.Product.ProductPresentations, true), 'id', 'int').map(function (el) {
            return el.name !== 'PIEZA' && (React.createElement(ItemChoosePresentationType7ProductComponent, { key: el.id, productName: props.Product.name, productPresentationName: el.name, productPresentationId: el.id, price: el.price, Files: el.ProductPresentationFiles, isSelected: productPresentationId === el.id, onClick: handleClick }));
        })),
        React.createElement(ContinueButtonChoosePresentationType7ProductComponent, { productPresentationId: productPresentationId, onClick: props.onContinue })));
};
export default ChoosePresentationType7ProductComponent;
