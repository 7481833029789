var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { setContext } from '@apollo/client/link/context';
import getSession from '../utils/getSession';
var authLink = setContext(function (_, _a) {
    var headers = _a.headers;
    var session = getSession();
    var token = session === null || session === void 0 ? void 0 : session.accessToken;
    var authorization = token ? { authorization: "Bearer ".concat(token) } : {};
    return {
        headers: __assign(__assign({}, headers), authorization),
    };
});
export default authLink;
