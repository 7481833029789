import React from 'react';
var OptionColorInfoProductImageComponent = function (props) {
    var handleClick = function () {
        props.onClick(props.index);
    };
    return (React.createElement("div", { onClick: handleClick, role: 'button', tabIndex: -1, className: 'w-5 h-5 flex items-center justify-center cursor-pointer border border-solid', style: {
            borderColor: props.isSelected ? '#000' : '#e1e1e1',
        } },
        React.createElement("div", { className: 'w-3 h-3', style: { backgroundColor: props.attribute || '#fff' } })));
};
export default OptionColorInfoProductImageComponent;
