import React from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import LogoNavigationComponent from '../../../Landing/Navigation/Logo';
import CopyrightUtilsComponent from '../../../../utils/Copyright';
var RootComponent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        '&.root': {
            display: 'flex',
            flex: 1,
            height: '100vh',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(7),
        },
        '& .container': {
            width: '35%',
        },
        '& .title': {
            marginTop: theme.spacing(2),
            color: '#313131',
            fontWeight: 300,
        },
        '& .subtitle': {
            color: '#595959',
            lineHeight: 1.5,
        },
        '& .body': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
        },
    });
});
var BaseComponentUtilsSessionEcommerce = function (props) {
    return (React.createElement(RootComponent, { className: 'root' },
        React.createElement("div", { className: 'container text-center' },
            React.createElement(LogoNavigationComponent, { height: '15vh' }),
            React.createElement(Typography, { variant: 'h4', className: 'title' }, props.title),
            React.createElement(Typography, { variant: 'subtitle1', className: 'subtitle' }, props.subtitle),
            React.createElement("div", { className: 'body' }, props.children),
            React.createElement(CopyrightUtilsComponent, null))));
};
export default BaseComponentUtilsSessionEcommerce;
