var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Button } from '@mui/material';
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { extraProductPrice, inputError, type2ProductCart } from '../../../../../apollo/cache';
import getInputErrorFormat from '../../../../../utils/getInputErrorFormat';
import validateProductInputs from '../../../Type1/AddToCartButton/helpers/validateProductInputs';
import setAddedAttributesArray from '../../../../../utils/setAddedAttributesArray';
import getProductPrice from '../../../Type1/helpers/getProductPrice';
var ContinueStep2Type2ProductComponent = function (props) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var extraProductPriceVar = useReactiveVar(extraProductPrice);
    var productPresentationId = searchParams.get('productPresentationId');
    var typographyId = searchParams.get('typographyId');
    var textOrientationId = searchParams.get('textOrientationId');
    var colorId = searchParams.get('colorId');
    var secondaryColorId = searchParams.get('secondaryColorId');
    var label = searchParams.get('label');
    var sizeId = searchParams.get('sizeId');
    var typeId = searchParams.get('typeId');
    var emoji = searchParams.get('emoji');
    var emojiPosition = searchParams.get('emojiPosition');
    var fontSize = searchParams.get('fontSize');
    var handleAction = function () {
        try {
            inputError({});
            validateProductInputs({
                productPresentationId: productPresentationId,
                typographyId: typographyId,
                textOrientationId: textOrientationId,
                colorId: colorId,
                secondaryColorId: secondaryColorId,
                sizeId: sizeId,
                typeId: typeId,
                label: label,
                SelectedProductIngredients: extraProductPriceVar,
            }, props.Product);
            var ProductPresentation = props.Product.ProductPresentations.find(function (el) { return el.id === productPresentationId; });
            if (!ProductPresentation)
                return;
            var AttributesAdded = setAddedAttributesArray({
                color: colorId,
                typography: typographyId,
                secondaryColor: secondaryColorId,
                emoji: emoji,
                emojiPosition: emojiPosition,
                orientation: parseInt(textOrientationId || '2'),
                text: label || '',
                size: parseInt(fontSize || '30'),
            }, props.Product.ProductAttributes);
            var price = getProductPrice(ProductPresentation.price, extraProductPriceVar);
            var type2ProductCartVar = type2ProductCart();
            type2ProductCart(__spreadArray(__spreadArray([], type2ProductCartVar, true), [
                {
                    productIngredientId: null,
                    productId: props.Product.id,
                    productPresentationId: ProductPresentation.id,
                    productName: props.Product.name,
                    productPresentationName: ProductPresentation.name,
                    ProductAttributes: AttributesAdded,
                    step: props.currentStep,
                    fileId: null,
                    price: price,
                },
            ], false));
            setSearchParams({});
            props.onClick();
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(Button, { className: 'px-8 py-4  bg-[#00bb2d] text-white text-base hover:bg-[#009424]', onClick: handleAction }, props.currentStep === props.totalStep ? 'Enviar al carrito' : 'Continuar'));
};
export default ContinueStep2Type2ProductComponent;
