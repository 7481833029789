var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { Button as ButtonMui, TextField as TextFieldMui } from '@mui/material';
import BaseComponent from '../utils/BaseComponent';
import ReturnLink from '../ForgotPassword/ReturnLink';
import GQL from './_gql';
import SignedUpDialog from './SignedUpDialog';
import getInputErrorFormat from '../../../utils/getInputErrorFormat';
import setInputErrorFormat from '../../../utils/setInputErrorFormat';
import { inputError } from '../../../apollo/cache';
var Button = styled(ButtonMui)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2, 8, 2, 8),
        backgroundColor: '#333',
        color: '#fff',
        fontSize: 16,
        '&:hover': {
            backgroundColor: '#474747',
        },
    });
});
var TextField = styled(TextFieldMui)(function (_a) {
    var theme = _a.theme;
    return ({
        marginTop: theme.spacing(1),
    });
});
var defaultValues = {
    name: '',
    email: '',
    phone: '',
    password: '',
};
var SignupComponent = function () {
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var companyName = useParams().companyName;
    var createECCustomer = useMutation(GQL.CREATE_ECCUSTOMER)[0];
    var handleAction = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    inputError({});
                    if (!values.name || values.name === '')
                        throw new Error(setInputErrorFormat('name', 'El nombre es obligatorio.'));
                    if (!values.phone || values.phone === '')
                        throw new Error(setInputErrorFormat('phone', 'El teléfono es obligatorio.'));
                    if (!values.password || values.password === '')
                        throw new Error(setInputErrorFormat('password', 'La contraseña es obligatoria.'));
                    return [4 /*yield*/, createECCustomer({
                            variables: {
                                ecName: companyName,
                                firstName: values.name,
                                phone: values.phone,
                                email: values.email,
                                password: values.password,
                            },
                        })];
                case 1:
                    _a.sent();
                    setIsOpen(true);
                    setValues(defaultValues);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleChange = function (e) {
        var name = e.target.name;
        var value = e.target.value;
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(BaseComponent, { title: 'Crear cuenta', subtitle: 'Tendr\u00E1s acceso a ofertas exclusivas, cup\u00F3nes de descuento y m\u00E1s.' },
            React.createElement(TextField, { label: 'Nombre', fullWidth: true, name: 'name', onChange: handleChange, value: values.name, error: !!errors.name, helperText: errors.name && errors.name }),
            React.createElement(TextField, { label: 'Tel\u00E9fono', fullWidth: true, name: 'phone', onChange: handleChange, value: values.phone, error: !!errors.phone, helperText: errors.phone && errors.phone }),
            React.createElement(TextField, { label: 'Correo electr\u00F3nico', fullWidth: true, name: 'email', onChange: handleChange, value: values.email, error: !!errors.email, helperText: errors.email && errors.email }),
            React.createElement(TextField, { label: 'Contrase\u00F1a', type: 'password', fullWidth: true, name: 'password', onChange: handleChange, value: values.password, error: !!errors.password, helperText: errors.password && errors.password }),
            React.createElement(Button, { variant: 'contained', onClick: handleAction, color: 'primary', size: 'large', fullWidth: true }, "Registrar"),
            React.createElement(ReturnLink, null)),
        React.createElement(SignedUpDialog, { isOpen: isOpen })));
};
export default SignupComponent;
