import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import getSession from '../../../../../../../utils/getSession';
import capitalize from '../../../../../../../utils/capitalize';
import closeSession from '../../../../../../../utils/closeSession';
var LogoutSessionActionForm1BodyPaymentComponent = function () {
    var _a = useState('Invitado'), name = _a[0], setName = _a[1];
    useEffect(function () {
        var _a;
        var session = getSession();
        if (!session)
            return;
        var tmpName = ((_a = session === null || session === void 0 ? void 0 : session.name) === null || _a === void 0 ? void 0 : _a.split(' ')) || [''];
        setName(capitalize(tmpName[0]));
    }, []);
    var handleLogout = function () {
        closeSession();
        document.location.reload();
    };
    return (React.createElement("div", null,
        "Hola, ",
        name,
        ".",
        ' ',
        React.createElement(Link, { className: 'cursor-pointer', onClick: handleLogout }, "Cerrar sesi\u00F3n")));
};
export default LogoutSessionActionForm1BodyPaymentComponent;
