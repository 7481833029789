import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/system';
import { Link, Typography } from '@mui/material';
import { ECShipping, checkCart } from '../../../../../../../../../apollo/cache';
import getCart from '../../../../../../../../../utils/getCart';
import currencyFormat from '../../../../../../../../../utils/currencyFormat';
var RootComponent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        '&.root': {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
        },
        '& .titleContainer': {
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(0, 2, 0, 2),
        },
        '& .title': {
            fontWeight: '700',
        },
        '& .divider': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            borderTop: '1px solid #dadada',
        },
    });
});
var TotalFooterDialogCartActionNavigationSecondaryContentComponent = function (props) {
    var checkCartVar = useReactiveVar(checkCart);
    var shippingVar = useReactiveVar(ECShipping);
    var _a = useState(0), subtotal = _a[0], setSubtotal = _a[1];
    var _b = useState(0), taxes = _b[0], setTaxes = _b[1];
    var _c = useState(0), total = _c[0], setTotal = _c[1];
    useEffect(function () {
        var f = function () {
            var currentCart = getCart();
            var tmpTotal = currentCart.reduce(function (total, el) { return total + el.price; }, 0);
            var tmpSubtotal = tmpTotal / 1.16;
            var tmpTaxes = tmpSubtotal * 0.16;
            setTaxes(tmpTaxes);
            setSubtotal(tmpSubtotal);
            setTotal(tmpTotal);
        };
        f();
    }, [checkCartVar]);
    return (React.createElement(RootComponent, { className: 'root' },
        React.createElement("div", { className: 'titleContainer' },
            React.createElement(Typography, { variant: 'subtitle1', className: 'title' }, "SUBTOTAL:"),
            React.createElement(Typography, { variant: 'subtitle1', className: 'title' },
                "MXN ",
                currencyFormat(subtotal))),
        React.createElement("div", { className: 'titleContainer' },
            React.createElement(Typography, { variant: 'subtitle1', className: 'title' }, "IVA:"),
            React.createElement(Typography, { variant: 'subtitle1', className: 'title' },
                "MXN ",
                currencyFormat(taxes))),
        React.createElement("div", { className: 'titleContainer' },
            React.createElement(Typography, { variant: 'subtitle1', className: 'title' }, "ENV\u00CDO:"),
            React.createElement(Typography, { variant: 'subtitle1', className: 'title' },
                "MXN ",
                currencyFormat(shippingVar))),
        !props.inCart && (React.createElement("div", { className: 'titleContainer' },
            React.createElement(Link, null, "C\u00E1lculo de env\u00EDo en el siguiente paso."))),
        React.createElement("div", { className: 'titleContainer divider' },
            React.createElement(Typography, { variant: 'h6', className: 'title' }, "TOTAL:"),
            React.createElement(Typography, { variant: 'h6', className: 'title' },
                "MXN ",
                currencyFormat(total + shippingVar)))));
};
export default TotalFooterDialogCartActionNavigationSecondaryContentComponent;
