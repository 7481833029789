import React from 'react';
import LogoComponent from './Logo';
import StickyNavigationComponent from './Sticky';
var NavigationComponent = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'hidden sm:flex bg-white py-2 justify-center items-center' },
            React.createElement(LogoComponent, null)),
        React.createElement(StickyNavigationComponent, null)));
};
export default NavigationComponent;
