import React, { useEffect, useState } from 'react';
import InfoProductImageComponent from './Info';
import getColorsFromProductAttributes from '../../utils/getColorsFromProductAttributes';
import ContainerProductImageComponent from './Container';
var ProductImageComponent = function (props) {
    var _a = useState([]), selectedFiles = _a[0], setSelectedFiles = _a[1];
    var _b = useState(null), productAttributeId = _b[0], setProductAttributeId = _b[1];
    var _c = useState([]), Colors = _c[0], setColors = _c[1];
    useEffect(function () {
        setSelectedFiles(props.Product.Images[0].Files);
    }, [props.Product.Images]);
    useEffect(function () {
        if (props.Product.ProductAttributes) {
            var Colors_1 = getColorsFromProductAttributes(props.Product.ProductAttributes);
            setColors(Colors_1);
        }
    }, [props.Product.ProductAttributes]);
    var handleColor = function (id) {
        var ColorSelected = props.Product.Images.find(function (el) { return el.id === id && el.type === 'color'; });
        if (ColorSelected)
            setSelectedFiles(ColorSelected.Files);
        setProductAttributeId(id);
    };
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement(ContainerProductImageComponent, { productId: props.Product.id, productName: props.Product.name, Files: selectedFiles, productAttributeId: productAttributeId }),
        !props.hideTitle && (React.createElement(InfoProductImageComponent, { productId: props.Product.id, productName: props.Product.name, productPrice: props.Product.price, productAltPrice: props.Product.altPrice || 0, ProductPresentations: props.Product.ProductPresentations, onClick: handleColor, Colors: Colors }))));
};
export default ProductImageComponent;
