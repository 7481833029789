import React from 'react';
import CopyrightComponent from './Copyright';
var LaChimeEnTemplateComponent = function () {
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'mb-24' }),
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("img", { className: 'w-full', src: 'https://storage.capitalike.com/files/28308', alt: 'IMAGEN' })),
            React.createElement("div", { className: 'flex flex-col items-center mt-5' },
                React.createElement("h1", { className: 'text-center text-2xl text-bold' },
                    React.createElement("span", { className: 'text-chime-pink' }, "LA CHIME"),
                    " ",
                    React.createElement("br", null),
                    "\u00A1THE BEST BOBA YOU CAN TRY!"),
                React.createElement("div", null,
                    React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-screen mt-5' }))),
            React.createElement("div", { className: 'flex flex-row bg-chime-brown' },
                React.createElement("div", { className: 'flex flex-1 flex-col text-white font-bold items-center text-xl' },
                    React.createElement("p", { className: 'flex px-20 justify-normal' }, "La Chime was born from a shared passion for boba drink culture and a desire to provide unique experiences to drink lovers. It was founded in a small place in the heart of the city."),
                    React.createElement("p", { className: 'flex px-20 justify-normal' }, "Over time, her reputation grew, attracting a loyal and diverse clientele seeking not only a delicious drink, but also a welcoming and vibrant atmosphere. Inspired by the initial success, the team behind La Chime embarked on a journey of expansion, opening several locations across the city and beyond.")),
                React.createElement("div", { className: 'flex place-content-end mr-5' },
                    React.createElement("img", { className: 'w-100 h-80 m-2', src: 'https://storage.capitalike.com/files/28332', alt: 'IMAGEN 2' }))),
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-screen' }),
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("h1", null,
                    "\u00A1WE CREATE ",
                    React.createElement("span", { className: 'text-chime-pink' }, "UNIQUE MOMENTS!"))),
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-80 ' }),
                React.createElement("h4", { className: 'flex whitespace-nowrap' }, "WE CREATE VISUAL DELIGHTS AND EXQUISITE FLAVORS FROM THE 2020"),
                React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-80 ' })),
            React.createElement("div", { className: 'flex flex-row bg-chime-pink p-2' },
                React.createElement("div", { className: 'flex flex-1 place-content-center' },
                    React.createElement("img", { className: 'w-60 h-50 p-2', src: 'https://storage.capitalike.com/files/28784', alt: 'IMAGEN 2' }),
                    React.createElement("img", { className: 'w-60 h-50 p-2', src: 'https://storage.capitalike.com/files/28786', alt: 'IMAGEN 2' }),
                    React.createElement("img", { className: 'w-60 h-50 p-2', src: 'https://storage.capitalike.com/files/28785', alt: 'IMAGEN 2' }))),
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-80 ' }),
                React.createElement("h4", { className: 'flex whitespace-nowrap' }, "SALTED CHEESE. ALWAYS IMITATED BUT NEVER DUPLICATED"),
                React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-80 ' })),
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'flex place-content-center' },
                    React.createElement("h1", { className: 'text-chime-pink' }, "OUR HISTORY")),
                React.createElement("div", { className: 'flex flex-row ' },
                    React.createElement("div", { className: 'flex flex-1' }, " "),
                    React.createElement("div", { className: 'flex flex-1' },
                        React.createElement("p", { className: 'text-justify' },
                            React.createElement("span", { className: 'text-chime-pink font-bold' }, "La Chime"),
                            " is an innovator company founded in 2020 in the vibrant city of Culiac\u00E1n, Sinaloa, Mexico. Our mission is to offer delicious and visually striking products that delight our clients. We specialize in three main products: boba, bread and soft ice cream, each made with the highest quality standards.")),
                    React.createElement("div", { className: 'flex flex-1' }, " ")),
                React.createElement("div", { className: 'flex place-content-center' },
                    React.createElement("img", { className: 'w-56 mt-2', src: 'https://storage.capitalike.com/files/28831', alt: 'LOGO' })),
                React.createElement("div", { className: 'space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent mt-5 place-content-center' },
                    React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active' },
                        React.createElement("div", { className: 'flex items-center mr-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-pink text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2020"),
                        React.createElement("div", { className: 'md:w-[calc(40%-1rem)] bg-white p-4  rounded border shadow-md shadow-chime-pink mr-7' },
                            React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-justify' },
                                "La Chime opens its doors in Culiac\u00E1n, Sinaloa, offering a unique experience by fusing boba, bread and ice cream in one place, conquering palates local since its inception.",
                                React.createElement("img", { className: 'w-20 ml-10 mt-2 rounded-lg', src: 'https://storage.capitalike.com/files/28674', alt: 'LOGO' })))),
                    React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active' },
                        React.createElement("div", { className: 'flex items-center ml-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-blue-800 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2021"),
                        React.createElement("div", { className: 'md:w-[calc(40%-1rem)] bg-white p-4 rounded border border-slate-200 shadow-md shadow-blue-800 ml-7' },
                            React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-justify' },
                                React.createElement("img", { className: 'w-20 mr-10 mt-2 rounded-lg', src: 'https://storage.capitalike.com/files/29115', alt: 'LOGO' }),
                                "La Chime expands its presence in Culiac\u00E1n with the opening of a second location, cementing its reputation as the preferred destination for lovers of boba, bread and ice cream in the region."))),
                    React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active' },
                        React.createElement("div", { className: 'flex items-center mr-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-[#fcb404]  text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2022"),
                        React.createElement("div", { className: 'md:w-[calc(40%-1rem)] bg-white  rounded border border-slate-200 shadow-md shadow-[#fcb404] p-4 mr-7' },
                            React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-justify' },
                                "La Chime expands its reach through strategic alliances with suppliers local, ensuring fresh, high-quality ingredients for their products exclusive boba, bread and ice cream.",
                                React.createElement("img", { className: 'w-20 ml-10 mt-2 rounded-lg', src: 'https://storage.capitalike.com/files/29091', alt: 'LOGO' })))),
                    React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active' },
                        React.createElement("div", { className: 'flex items-center ml-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-[#fb341c] text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2023"),
                        React.createElement("div", { className: 'md:w-[calc(40%-1rem)] bg-white  rounded border border-slate-200 shadow-md shadow-[#fb341c] p-4 ml-7' },
                            React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-justify' },
                                React.createElement("img", { className: 'w-20 mr-10 mt-2 rounded-lg', src: 'https://storage.capitalike.com/files/29088', alt: 'LOGO' }),
                                "The popularity of La Chime transcends the borders of Culiac\u00E1n, attracting the attention of clients from neighboring cities and tourists who seek to delight in its innovative gastronomic offer."))),
                    React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active' },
                        React.createElement("div", { className: 'flex ml-2 items-center mr-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-[#946c44] text-white group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2024"),
                        React.createElement("div", { className: 'md:w-[calc(40%-1rem)] bg-white p-4 rounded border border-slate-200 shadow-md shadow-[#946c44] mr-7' },
                            React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-justify' },
                                "La Chime celebrates its fourth anniversary with the launch of a line of exclusive products, including limited editions of boba, bread and ice cream, consolidating its position as a reference in the culinary scene of Sinaloa and more there.",
                                React.createElement("img", { className: 'w-20 ml-10 mt-2 rounded-lg', src: 'https://storage.capitalike.com/files/29094', alt: 'LOGO' })))),
                    React.createElement(CopyrightComponent, null))))));
};
export default LaChimeEnTemplateComponent;
