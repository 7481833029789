import React from 'react';
import { styled } from '@mui/system';
import PayButton from './PayButton';
import Total from './Total';
var RootComponent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        '&.root': {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
        },
        '& .titleContainer': {
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(0, 2, 0, 2),
        },
        '& .divider': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            borderTop: '1px solid #dadada',
        },
    });
});
var FooterCartDialogComponent = function () {
    return (React.createElement(RootComponent, { className: 'root' },
        React.createElement(Total, null),
        React.createElement("div", { className: 'titleContainer divider' },
            React.createElement(PayButton, null))));
};
export default FooterCartDialogComponent;
