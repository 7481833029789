import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ItemProductPresentationType1ProductComponent from '../ProductPresentation/Item';
import setSearchQueryParam from '../../../../utils/setSearchQueryParam';
import { inputError } from '../../../../apollo/cache';
var TypeType1ProductComponent = function (props) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var typeId = searchParams.get('typeId');
    var handleClick = function (Size) {
        inputError({});
        var selectedSize = props.Types.find(function (el) { return el.id === Size.id; });
        if (!selectedSize)
            return;
        setSearchParams(setSearchQueryParam('typeId', selectedSize.id.toString(), searchParams));
    };
    if (props.Types.length === 0)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'text-xl tracking-tighter mb-2' }, "Selecciona el tipo de producto"),
        props.Types.map(function (el) { return (React.createElement(ItemProductPresentationType1ProductComponent, { key: el.id, isSelected: el.id === typeId, label: el.attribute, params: el, onClick: handleClick })); })));
};
export default TypeType1ProductComponent;
