import React from 'react';
import RelevantProductComponent from './RelevantProduct';
import RandomProductComponent from './RandomProduct';
import HeaderComponent from './Header';
var LandingComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(HeaderComponent, null),
        React.createElement(RelevantProductComponent, null),
        React.createElement(RandomProductComponent, null)));
};
export default LandingComponent;
