import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
var Root = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return ({
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        '& .content': (_b = {
                padding: theme.spacing(2, 18, 2, 18),
                borderBottom: '1px solid #ccc',
                background: '#fff'
            },
            _b[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(2),
            },
            _b),
        '& .subTitle': {
            color: '#4f4f4f',
        },
        '& .ol': {
            marginTop: theme.spacing(0),
        },
    });
});
var OurShopsStaticInformationComponent = function () {
    return (React.createElement(Root, null,
        React.createElement("div", { className: 'content' },
            React.createElement(Grid, { container: true, spacing: 4 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: 'h6', display: 'block', className: 'subTitle', gutterBottom: true }, "Nuestras Tiendas")),
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(Typography, { variant: 'h4' },
                        React.createElement("strong", null, "Suc. Guadalupe Victoria")),
                    React.createElement(Typography, { variant: 'body1' }, "Avenida Nakayama #645. Colonia Guadalupe Victoria. Culiac\u00E1n Sinaloaxw"),
                    React.createElement("br", null),
                    React.createElement(Typography, { variant: 'body1' }, "Abierto de lunes a jueves 10am a 1pm y 4pm a 8pm"),
                    React.createElement(Typography, { variant: 'body1' }, "Viernes 4pm a 8pm."),
                    React.createElement(Typography, { variant: 'body1' }, "S\u00E1bado 10am a 3.30pm."),
                    React.createElement("br", null),
                    React.createElement("iframe", { title: 'map', src: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7243.660003627402!2d-107.366313!3d24.80127400000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd76ce1e227c3%3A0xf7092bb46f26b7f5!2sEncantacln!5e0!3m2!1ses!2smx!4v1660582805338!5m2!1ses!2smx', width: '100%', height: '300', className: 'border-none', allowFullScreen: true, loading: 'lazy' }))))));
};
export default OurShopsStaticInformationComponent;
