import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
var CloseIconButtonUtilsComponent = function (props) {
    var handleClose = function () {
        props.onClose();
    };
    return (React.createElement(IconButton, { className: 'm-0 p-0 text-base hover:bg-transparent', onClick: handleClose, size: 'small' },
        React.createElement(CloseIcon, null)));
};
export default CloseIconButtonUtilsComponent;
