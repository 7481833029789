import React from 'react';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
var SelectProductPresentationField = function (props) {
    var handleChange = function (name, value) {
        var ProductPresentation = props.options.find(function (el) { return el.id === value; });
        ProductPresentation && props.onChange(ProductPresentation);
    };
    return (React.createElement(SelectFieldComponent, { name: 'productPresentation', size: 'small', value: props.value || '', onChange: handleChange, options: props.options.map(function (el) { return ({ value: el.id, label: el.name }); }) }));
};
export default SelectProductPresentationField;
