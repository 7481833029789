import React from 'react';
import { isECSearchOpen } from '../../../../../../../../apollo/cache';
import CloseIconButtonUtilsComponent from '../../../../../../../../utils/CloseIconButton';
var CloseIconButtonDialogSearchActionNavigationSecondaryContentComponent = function () {
    var handleClose = function () {
        isECSearchOpen(false);
    };
    return React.createElement(CloseIconButtonUtilsComponent, { onClose: handleClose });
};
export default CloseIconButtonDialogSearchActionNavigationSecondaryContentComponent;
