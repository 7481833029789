import React, { useEffect, useState } from 'react';
import ColorProductImageComponent from './Color';
import currencyFormat from '../../../utils/currencyFormat';
import AddToFavoriteButtonComponent from '../../../containers/Product/Type1/AddToFavoriteButton';
var InfoProductImageComponent = function (props) {
    var _a = useState(''), label = _a[0], setLabel = _a[1];
    useEffect(function () {
        if (props.ProductPresentations.length > 1) {
            var lessPresentationPrice = props.ProductPresentations.reduce(function (price, el) { return (el.price < price ? el.price : price); }, 0);
            setLabel("DESDE MXN ".concat(currencyFormat(lessPresentationPrice)));
        }
        else if (props.ProductPresentations.length === 1) {
            setLabel("MXN ".concat(currencyFormat(props.productPrice)));
        }
    }, [props.ProductPresentations]);
    return (React.createElement("div", { className: 'flex items-start' },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'text-base text-[#666666]' }, props.productName),
            props.productAltPrice > 0 ? (React.createElement("div", { className: 'font-semibold flex items-center' },
                React.createElement("div", { className: 'text-red-600' },
                    "MXN ",
                    currencyFormat(props.productAltPrice)),
                React.createElement("div", { className: 'line-through text-sm text-gray-600' }, label))) : (React.createElement("div", { className: 'font-semibold' }, label)),
            React.createElement(ColorProductImageComponent, { onClick: props.onClick, Colors: props.Colors })),
        React.createElement("div", { className: 'flex' },
            React.createElement(AddToFavoriteButtonComponent, { productId: props.productId }))));
};
export default InfoProductImageComponent;
