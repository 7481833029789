import getColorsFromProductAttributes from '../../../../../utils/getColorsFromProductAttributes';
import getSecondaryColorsFromProductAttributes from '../../../../../utils/getSecondaryColorsFromProductAttributes';
import getSizesFromProductAttributes from '../../../../../utils/getSizesFromProductAttributes';
import getTextAttributesFromProductAttributes from '../../../../../utils/getTextAttributesFromProductAttributes';
import getTypesFromProductAttributes from '../../../../../utils/getTypesFromProductAttributes';
import setInputErrorFormat from '../../../../../utils/setInputErrorFormat';
import validateTextOrientationIsRequired from '../../../../../utils/validateTextOrientationIsRequired';
import validateProductVariations from './validateProductVariations';
var validateProductInputs = function (values, Product) {
    var ProductPresentation = Product.ProductPresentations.find(function (el) { return el.id === values.productPresentationId; });
    if (!values.productPresentationId || !ProductPresentation)
        throw new Error(setInputErrorFormat('productPresentation', 'Elige una presentación para el producto.'));
    validateProductVariations(values.SelectedProductIngredients, ProductPresentation.ProductVariations);
    var Colors = getColorsFromProductAttributes(Product.ProductAttributes);
    if (Colors.length > 0 && !values.colorId)
        throw new Error(setInputErrorFormat('color', 'Elige un color para el producto.'));
    var SecondaryColors = getSecondaryColorsFromProductAttributes(Product.ProductAttributes);
    if (SecondaryColors.length > 0 && !values.secondaryColorId)
        throw new Error(setInputErrorFormat('secondaryColor', 'Elige un color para el producto.'));
    //Elige uno o más colores para el producto.
    var Sizes = getSizesFromProductAttributes(Product.ProductAttributes);
    if (Sizes.length > 0 && !values.sizeId)
        throw new Error(setInputErrorFormat('size', 'Elige una talla para tu producto.'));
    var Types = getTypesFromProductAttributes(Product.ProductAttributes);
    if (Types.length > 0 && !values.typeId)
        throw new Error(setInputErrorFormat('type', 'Elige el tipo de producto.'));
    var TextOrientation = validateTextOrientationIsRequired(Product.ProductAttributes);
    if (TextOrientation.length > 0 && !values.textOrientationId && values.label)
        throw new Error(setInputErrorFormat('textOrientationId', 'Elige la orientación del texto para tu producto.'));
    var TextAttributes = getTextAttributesFromProductAttributes(Product.ProductAttributes);
    if (TextAttributes.length > 0 && !values.typographyId && values.label)
        throw new Error(setInputErrorFormat('typographyId', 'Elige la tipografía para tu producto.'));
    return true;
};
export default validateProductInputs;
