import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import CONFIG from '../../../../../../config/config.json';
import getTextColorFromProductAttributes from '../../../../../../utils/getTextColorFromProductAttributes';
import getMarginBottomByProductId from './helpers/getMarginBottomByProductId';
import getMarginLeftByProductId from './helpers/getMarginLeftByProductId';
import getMarginRightByProductId from './helpers/getMarginRightByProductId';
import getMarginTopByProductId from './helpers/getMarginTopByProductId';
var CustomizedItemMainImageType1ProductComponent = function (props) {
    var theme = useTheme();
    var isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var params = useParams();
    var searchParams = useSearchParams()[0];
    var colorId = searchParams.get('colorId');
    var label = searchParams.get('label');
    var typographyId = searchParams.get('typographyId');
    var textOrientationId = searchParams.get('textOrientationId');
    var emoji = searchParams.get('emoji');
    var emojiPosition = searchParams.get('emojiPosition');
    var fontSize = searchParams.get('fontSize');
    var _a = useState('#000'), textColor = _a[0], setTextColor = _a[1];
    /* TMP */
    var marginBottom = getMarginBottomByProductId(params.productId, {
        isSmallScreen: isSmallScreen,
    });
    var marginLeft = getMarginLeftByProductId(params.productId, {
        isSmallScreen: isSmallScreen,
    });
    var marginRight = getMarginRightByProductId(params.productId, {
        isSmallScreen: isSmallScreen,
    });
    var marginTop = getMarginTopByProductId(params.productId, {
        isSmallScreen: isSmallScreen,
    });
    useEffect(function () {
        if (colorId) {
            var textColor_1 = getTextColorFromProductAttributes(props.TextAttributes, colorId);
            setTextColor(textColor_1);
        }
    }, [props.TextAttributes, colorId]);
    return (React.createElement("div", { className: 'relative w-full' },
        React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(props.fileId), alt: 'Imagen principal', className: 'w-full' }),
        props.Proportions[0] && props.Proportions[1] ? (React.createElement("div", { className: 'absolute top-0 left-0 w-full h-full bg-transparent items-center justify-center flex' },
            React.createElement("div", { className: 'text-center items-center flex justify-center border border-solid border-[#525252]', style: {
                    flexDirection: textOrientationId === '1' ? 'column' : 'row',
                    width: textOrientationId === '1' ? props.Proportions[1] : props.Proportions[0],
                    height: textOrientationId === '1' ? props.Proportions[0] : props.Proportions[1],
                    marginLeft: marginLeft,
                    marginRight: marginRight,
                    marginTop: marginTop,
                    marginBottom: marginBottom,
                } },
                React.createElement("div", { className: 'whitespace-pre-wrap flex items-center', style: {
                        transform: textOrientationId === '1' ? 'rotate(180deg)' : 'none',
                        writingMode: textOrientationId === '1' ? 'vertical-rl' : 'horizontal-tb',
                        fontFamily: typographyId || undefined,
                        color: textColor,
                        fontSize: "".concat(fontSize || 30, "px"),
                    } },
                    emoji && emojiPosition === 'start' && (React.createElement("img", { alt: 'emoji', src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(emoji), className: 'w-8 h-8 mr-2' })),
                    label,
                    emoji && emojiPosition === 'end' && (React.createElement("img", { alt: 'emoji', src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(emoji), className: 'w-8 h-8 ml-2' })))))) : (React.createElement("div", null))));
};
export default CustomizedItemMainImageType1ProductComponent;
