var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Button } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { extraProductPrice, inputError, isECCartOpen, snackbar } from '../../../../apollo/cache';
import getInputErrorFormat from '../../../../utils/getInputErrorFormat';
import validateProductInputs from './helpers/validateProductInputs';
import setAddedAttributesArray from '../../../../utils/setAddedAttributesArray';
import addToCart from '../../../../utils/addToCart';
import getProductPrice from '../helpers/getProductPrice';
import getImages from '../../../../utils/getImages';
var AddToCartButtonComponent = function (props) {
    var searchParams = useSearchParams()[0];
    var extraProductPriceVar = useReactiveVar(extraProductPrice);
    var productPresentationId = searchParams.get('productPresentationId');
    var typographyId = searchParams.get('typographyId');
    var textOrientationId = searchParams.get('textOrientationId');
    var colorId = searchParams.get('colorId');
    var secondaryColorId = searchParams.get('secondaryColorId');
    var label = searchParams.get('label');
    var sizeId = searchParams.get('sizeId');
    var fontSize = searchParams.get('fontSize');
    var typeId = searchParams.get('typeId');
    var emoji = searchParams.get('emoji');
    var emojiPosition = searchParams.get('emojiPosition');
    var handleAction = function () { return __awaiter(void 0, void 0, void 0, function () {
        var AttributesAdded, ProductPresentation, price, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    inputError({});
                    validateProductInputs({
                        productPresentationId: productPresentationId,
                        typographyId: typographyId,
                        textOrientationId: textOrientationId,
                        colorId: colorId,
                        secondaryColorId: secondaryColorId,
                        sizeId: sizeId,
                        typeId: typeId,
                        label: label,
                        SelectedProductIngredients: extraProductPriceVar,
                    }, props.Product);
                    AttributesAdded = setAddedAttributesArray({
                        color: colorId,
                        typography: typographyId,
                        secondaryColor: secondaryColorId,
                        emoji: emoji,
                        emojiPosition: emojiPosition,
                        orientation: parseInt(textOrientationId || '2'),
                        text: label || '',
                        size: parseInt(fontSize || '30'),
                    }, props.Product.ProductAttributes);
                    ProductPresentation = props.Product.ProductPresentations.find(function (el) { return el.id === productPresentationId; });
                    if (!ProductPresentation)
                        return [2 /*return*/];
                    price = getProductPrice(ProductPresentation.price, extraProductPriceVar);
                    return [4 /*yield*/, addToCart({
                            attributesAdded: AttributesAdded,
                            price: price,
                            productId: props.Product.id,
                            productPresentationId: ProductPresentation.id,
                            productName: props.Product.name,
                            productPresentationName: ProductPresentation.name,
                            qty: 1,
                            fileId: getImages({
                                ProductImages: props.Product.ProductFiles,
                                ProductPresentation: props.Product.ProductPresentations,
                                ProductAttributes: props.Product.ProductAttributes,
                            })[0].Files[0],
                        })];
                case 1:
                    _a.sent();
                    isECCartOpen(true);
                    snackbar({
                        isOpen: true,
                        label: 'El producto se añadió con éxito al carrito de compras.',
                        severity: 'success',
                    });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Button, { className: 'px-8 py-4  bg-[#00bb2d] text-white text-base hover:bg-[#009424]', onClick: handleAction }, "Agregar al carrito"));
};
export default AddToCartButtonComponent;
