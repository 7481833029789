import React, { useEffect } from 'react';
import SocketHandlerComponent from '../../../socket/handler';
import socket from '../../../socket';
import BodyRoomChatComponent from './Body';
import FormRoomChatComponent from './Form';
var RoomChatComponent = function (props) {
    useEffect(function () {
        socket.emit('join', props.session.publicUserRoom);
    }, [props.session.publicUserRoom]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'w-full h-96 max-h-96 flex flex-col' },
            React.createElement(BodyRoomChatComponent, null),
            React.createElement(FormRoomChatComponent, null)),
        React.createElement(SocketHandlerComponent, { token: props.session.publicUserToken, mode: 2 })));
};
export default RoomChatComponent;
