import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import Header from './Header';
import FooterPaymentComponent from './Footer';
import Body from './Body';
var RootComponent = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100vh',
});
var PaymentComponentEcommerce = function () {
    useEffect(function () {
        var script = document.createElement('script');
        script.src = 'https://pay.conekta.com/v1.0/js/conekta-checkout.min.js';
        script.async = true;
        document.body.appendChild(script);
        return function () {
            document.body.removeChild(script);
        };
    }, []);
    return (React.createElement(RootComponent, null,
        React.createElement(Header, null),
        React.createElement(Body, null),
        React.createElement(FooterPaymentComponent, null)));
};
export default PaymentComponentEcommerce;
