var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getImages from '../../../../../utils/getImages';
var getRelevantProducts = function (Products, Classifications) {
    return Classifications.map(function (el) {
        if (!el.id)
            return null;
        var currentProduct = Products.find(function (elCh) { return elCh.Classification && elCh.Classification.id === el.id; });
        if (!currentProduct)
            return null;
        var Images = getImages({
            ProductImages: currentProduct.ProductFiles,
            ProductPresentation: currentProduct.ProductPresentations,
            ProductAttributes: currentProduct.ProductAttributes,
            settings: {
                reverse: true,
            },
        }).map(function (elCh) { return (__assign(__assign({}, elCh), { Files: elCh.Files.sort().filter(function (key, i) { return elCh.Files.length - 1 === i; }) })); });
        return __assign(__assign({}, currentProduct), { Images: Images.sort() });
    }).filter(function (el) { return el; });
};
export default getRelevantProducts;
