var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { inputError } from '../../../apollo/cache';
import TextFieldComponent from '../../../presentationals/FormComponents/TextField';
import TermsLinkFormChatComponent from './TermsLink';
import StartChatButtonFormChatComponent from './StartChatButton';
var defaultValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
};
var FormChatComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TextFieldComponent, { name: 'name', defaultLabel: 'Nombre', value: values.name, error: errors.name, onChange: handleChange, size: 'medium' }),
        React.createElement(TextFieldComponent, { name: 'phone', type: 'phone', defaultLabel: 'Tel\u00E9fono', value: values.phone, error: errors.phone, onChange: handleChange, size: 'medium' }),
        React.createElement(StartChatButtonFormChatComponent, { onCreatedSession: props.onCreatedSession, phone: values.phone, name: values.name }),
        React.createElement(TermsLinkFormChatComponent, null)));
};
export default FormChatComponent;
