import React from 'react';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import IconButton from '@mui/material/IconButton';
import { isECSearchOpen } from '../../../../../../apollo/cache';
var SearchButtonNavigationComponent = function () {
    var handleClick = function () {
        isECSearchOpen(true);
    };
    return (React.createElement(IconButton, { onClick: handleClick },
        React.createElement(SearchIcon, null)));
};
export default SearchButtonNavigationComponent;
