import { Grid } from '@mui/material';
import { styled } from '@mui/system';
var GridContainer = styled(Grid)(function (_a) {
    var theme = _a.theme;
    return ({
        '& .spaceBetween': {
            marginTop: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    });
});
export default GridContainer;
