import { ApolloClient, from } from '@apollo/client';
import errorLink from './errorLink';
import authLink from './authLink';
import httpLink from './httpLink';
import { cache } from './cache';
var client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: cache,
    connectToDevTools: true,
});
export default client;
