var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Button } from '@mui/material';
import BaseComponent from '../utils/BaseComponent';
import ReturnLink from './ReturnLink';
import { inputError, snackbar } from '../../../apollo/cache';
import setInputErrorFormat from '../../../utils/setInputErrorFormat';
import getInputErrorFormat from '../../../utils/getInputErrorFormat';
import TextFieldComponent from '../../../presentationals/FormComponents/TextField';
var defaultValues = {
    email: '',
};
var ForgotPasswordComponent = function () {
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var loading = false;
    // const [sendEmail, { loading }] = useMutation(SEND_EMAIL);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSendEmail = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                if (values.email === '')
                    throw new Error(setInputErrorFormat('email', 'Proporciona un correo electrónico.'));
                // await sendEmail({ variables: values });
                snackbar({
                    isOpen: true,
                    time: 3000,
                    label: 'Se ha enviado un correo para recuperar su contraseña',
                    severity: 'success',
                });
            }
            catch (e) {
                getInputErrorFormat(e);
            }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(BaseComponent, { title: 'Recuperar contrase\u00F1a', subtitle: 'Un correo de recuperaci\u00F3n ser\u00E1 enviado a la direcci\u00F3n proporcionada.' },
        React.createElement("div", { className: 'flex text-left' },
            React.createElement(TextFieldComponent, { label: 'Correo electr\u00F3nico', name: 'email', value: values.email, error: errors.email, size: 'medium', onChange: handleChange })),
        React.createElement(Button, { variant: 'contained', onClick: handleSendEmail, color: 'primary', size: 'large', disabled: loading, className: 'w-full mt-3 mb-2 bg-[#333] hover:bg-[#474747] font-base py-4 px-12', fullWidth: true }, "Enviar Correo"),
        React.createElement(ReturnLink, null)));
};
export default ForgotPasswordComponent;
