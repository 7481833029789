import React from 'react';
import { Grid, Typography } from '@mui/material';
import WhatsAppComponent from './WhatsApp';
import InstagramComponent from './Instagram';
import FacebookComponent from './Facebook';
import FormComponent from './Form';
var ContactUsStaticInformationComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(Grid, { container: true, spacing: 2, className: 'p-5 md:px-36 border-solid border-[#ccc] border-0 border-b-[1px] bg-white' },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: 'h6', display: 'block', className: 'subTitle', gutterBottom: true }, "Inicio / Cont\u00E1ctanos"),
                React.createElement(Typography, { variant: 'h4' },
                    React.createElement("strong", null, "Cont\u00E1ctanos"),
                    React.createElement("br", null),
                    React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Por cualquiera de estos medios:"))),
            React.createElement(Grid, { item: true, xs: 12, md: 5 },
                React.createElement(FormComponent, null)),
            React.createElement(Grid, { item: true },
                React.createElement("div", { className: 'flex flex-col mt-1' },
                    React.createElement(WhatsAppComponent, null),
                    React.createElement(InstagramComponent, null),
                    React.createElement(FacebookComponent, null))))));
};
export default ContactUsStaticInformationComponent;
