import React from 'react';
import { useSearchParams } from 'react-router-dom';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import setSearchQueryParam from '../../../../../utils/setSearchQueryParam';
var LabelFieldTextType1ProductComponent = function () {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var label = searchParams.get('label');
    var handleChange = function (name, value) {
        setSearchParams(setSearchQueryParam('label', value, searchParams));
    };
    return (React.createElement(TextFieldComponent, { label: 'Texto', name: 'label', size: 'small', onChange: handleChange, value: label || '' }));
};
export default LabelFieldTextType1ProductComponent;
