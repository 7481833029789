import React, { useEffect, useState } from 'react';
import getIsTextAttributeAvailable from '../../helpers/getIsTextAttributeAvailable';
import ItemMainImageType1ProductComponent from './Item';
import CustomizedItemMainImageType1ProductComponent from './CustomizedItem';
var MainImageType1ProductComponent = function (props) {
    var _a = useState(false), isTextAvailable = _a[0], setIsTextAvailable = _a[1];
    useEffect(function () {
        setIsTextAvailable(getIsTextAttributeAvailable(props.TextAttributes));
    }, [props.TextAttributes]);
    if (!props.fileId)
        return React.createElement("div", null);
    if ((isTextAvailable || props.Proportions[0]) && props.isMainImage)
        return (React.createElement(CustomizedItemMainImageType1ProductComponent, { TextAttributes: props.TextAttributes, Proportions: props.Proportions, fileId: props.fileId }));
    return React.createElement(ItemMainImageType1ProductComponent, { fileId: props.fileId });
};
export default MainImageType1ProductComponent;
