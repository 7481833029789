import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import getIfInFavoriteList from '../../../../utils/getIfInFavoriteList';
import addOrRemoveToFavoriteList from '../../../../utils/addOrRemoveToFavoriteList';
var AddToFavoriteButtonComponent = function (props) {
    var _a = useState(false), isFavorite = _a[0], setIsFavorite = _a[1];
    useEffect(function () {
        if (props.productId) {
            var isFavorite_1 = getIfInFavoriteList(props.productId);
            setIsFavorite(isFavorite_1);
        }
    }, [props]);
    var handleClick = function () {
        if (props.productId) {
            setIsFavorite(function (isFavorite) { return !isFavorite; });
            addOrRemoveToFavoriteList(props.productId);
        }
    };
    return (React.createElement(IconButton, { className: 'text-red-500 ml-2', onClick: handleClick }, isFavorite ? (React.createElement(FavoriteOutlinedIcon, { fontSize: 'large' })) : (React.createElement(FavoriteBorderOutlinedIcon, { fontSize: 'large' }))));
};
export default AddToFavoriteButtonComponent;
