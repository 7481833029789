import React from 'react';
import { Button, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CopyrightComponent from '../Copyright';
var FranchiseEnComponent = function () {
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("img", { className: 'w-full mb-20', src: 'https://storage.capitalike.com/files/28542', alt: 'IMAGEN 2' }),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' }),
            React.createElement("h4", { className: 'flex whitespace-nowrap text-3xl text-chime-pink' }, "UNIQUE VISUAL EXPERIENCIE"),
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' })),
        React.createElement("div", { className: 'flex flex-col flex-1' },
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement("img", { className: 'p-2 w-36 flex flex-1 h-80 object-cover object-bottom', src: 'https://storage.capitalike.com/files/28800', alt: 'IMAGEN 2' }),
                React.createElement("div", { className: 'flex flex-1 text-justify p-4 text-xl font-bold' },
                    "En La Chime, We believe that the visual experience is as important as the taste. Our drinks are exquisitely decorated with an adorable character, creating a unique touch and lovely that gets better with every sip. ",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Our facilities are a reflection of our brand: full of life and color. Pink tones and neon details predominate, creating the perfect setting for your photos. Whether you visit us in Mexico or the United States, each La Chime is designed to be a place where you can enjoy and capture moments unforgettable."))),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' }),
            React.createElement("h4", { className: 'flex whitespace-nowrap text-3xl text-chime-pink' }, "OUR MISSION"),
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' })),
        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-screen' }),
        React.createElement("div", { className: 'flex flex-1 flex-row text-black font-bold items-center bg-chime-brown' },
            React.createElement("div", { className: 'flex place-content-end mr-5 p-6' },
                React.createElement("img", { className: 'w-100 h-80 p-8', src: 'https://storage.capitalike.com/files/28541', alt: 'IMAGEN 2' })),
            React.createElement("p", { className: 'flex px-20 justify-center text-3xl text-white' }, "Offer all our clients a service that meets the highest standards of quality, thus guaranteeing unmatched products and flavors."),
            React.createElement("div", { className: 'flex place-content-end mr-5 p-6' },
                React.createElement("img", { className: 'w-100 h-80 p-8', src: 'https://storage.capitalike.com/files/28541', alt: 'IMAGEN 2' }))),
        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-screen' }),
        React.createElement("div", { className: 'mb-20' }),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' }),
            React.createElement("h4", { className: 'flex whitespace-nowrap text-3xl text-chime-pink' }, "\u00A1START WITH US!"),
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' })),
        React.createElement("div", { className: 'mt-5' },
            React.createElement("img", { src: 'https://storage.capitalike.com/files/28581', alt: '', className: 'object-contain w-screen' })),
        React.createElement("div", { className: 'flex flex-1 flex-col mb-5 text-center m-4 p-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'text-center text-2xl font-bold mt-10 mb-10' },
                    "FRANCHISE PRE-APPLICATION ",
                    React.createElement("span", { className: 'text-chime-pink' }, "LA CHIME\u00AE")),
                React.createElement("div", { className: 'mt-3 text-justify mb-5' }, "All information presented within this Pre-application is strictly confidential and will be used only as an evaluation for the granting of a LA CHIME\u00AE franchise to the investor interested in becoming part of our family of franchisees. Once the information has been evaluated, you may receive a call on our part to request additional information to the proposal. For any additional questions please contact us at hola@franquiciasdeexito.com.mx")),
            React.createElement("div", { className: 'text-left text-xl font-bold text-chime-pink' },
                "PERSONAL INFORMATION ",
                React.createElement("br", null),
                React.createElement("span", { className: 'text-black font-normal text-base' }, "Favor de proporcionar la informaci\u00F3n de acuerdo a su credencial INE y/o Pasaporte"))),
        React.createElement("div", { className: 'flex flex-1 flex-col m-4 p-4' },
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Name (s) and Last Name (s) *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Birthdate *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Address *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "City *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "State *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Email *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Phone Number *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "\u00BFHow he met or joined La Chime? *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "\u00BFWhat do you currently do? *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "\u00BFWhat is your currently available investment amount?? *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "\u00BFAccept the Privacy Notice? (This is located in https://franquiciasdeexito.com.mx/aviso-de-privacidad/) *"),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFYou are interested in this project individually or with other partners? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFFor the execution of the project you will use some type of financing? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFYou already have a location seen and/or available? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFHow long have you known the place of interest? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFYou have experience in the administration of a license or franchise? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFWhen would you like to start this project?? *"),
                    React.createElement(TextField, null))),
            React.createElement("div", { className: 'text-center' },
                React.createElement(Button, { variant: 'contained', endIcon: React.createElement(SendIcon, null), className: 'bg-chime-pink hover:bg-pink-600 mb-10 mt-4 w-32' }, "Send"))),
        React.createElement(CopyrightComponent, null)));
};
export default FranchiseEnComponent;
