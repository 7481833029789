import React from 'react';
import CopyrightComponent from './Copyright';
var LaChimeEsTemplateComponent = function () {
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'mb-24' }),
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: 'place-content-center' },
                React.createElement("img", { className: 'w-full ', src: 'https://storage.capitalike.com/files/29298', alt: 'IMAGEN' })),
            React.createElement("div", { className: 'flex flex-col items-center mt-5' },
                React.createElement("h1", { className: 'text-center text-2xl text-bold' },
                    React.createElement("span", { className: 'text-chime-pink' }, "LA CHIME"),
                    " ",
                    React.createElement("br", null),
                    "\u00A1LA MEJOR BOBA QUE PUEDAS PROBAR!"),
                React.createElement("div", null,
                    React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-screen mt-5' }))),
            React.createElement("div", { className: 'flex flex-row bg-chime-brown' },
                React.createElement("div", { className: 'flex flex-1 flex-col text-white font-bold items-center text-xl' },
                    React.createElement("p", { className: 'flex px-20 justify-normal mt-8' }, "La Chime naci\u00F3 de una pasi\u00F3n compartida por la cultura de las bebidas con boba y el deseo de brindar experiencias \u00FAnicas a los amantes de las bebidas. Fundada en un peque\u00F1o local en el coraz\u00F3n de la ciudad."),
                    React.createElement("p", { className: 'flex px-20 justify-normal mt-8' }, "Con el tiempo, su reputaci\u00F3n creci\u00F3, atrayendo a una clientela fiel y diversa que buscaba no solo una deliciosa bebida, sino tambi\u00E9n un ambiente acogedor y vibrante. Inspirados por el \u00E9xito inicial, el equipo detr\u00E1s de La Chime se embarc\u00F3 en un viaje de expansi\u00F3n, abriendo varias ubicaciones en toda la ciudad y m\u00E1s all\u00E1.")),
                React.createElement("div", { className: 'flex place-content-end mr-5' },
                    React.createElement("img", { className: 'w-100 h-80 m-2', 
                        //   src='https://scontent.fcul1-1.fna.fbcdn.net/v/t39.30808-6/445364138_18012145343377728_5342928707079830525_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=DxAige2_fEoQ7kNvgExlmsp&_nc_ht=scontent.fcul1-1.fna&oh=00_AYBXMjagYihclLUr1C2ZHbIZ-knIGI6PIv1sZmSB9IjvFA&oe=665FCD06'
                        //   src='https://storage.capitalike.com/files/28319'
                        src: 'https://storage.capitalike.com/files/29310', alt: 'IMAGEN 2' }))),
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-screen' }),
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("h1", null,
                    "\u00A1CREAMOS ",
                    React.createElement("span", { className: 'text-chime-pink' }, "MOMENTOS UNICOS!"))),
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-80 ' }),
                React.createElement("h4", { className: 'flex whitespace-nowrap' }, "CREAMOS DELICIAS VISUALES Y EXQUISITOS SABORES DESDE EL 2020"),
                React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-80 ' })),
            React.createElement("div", { className: 'flex flex-row bg-chime-pink p-2' },
                React.createElement("div", { className: 'flex flex-1 place-content-center' },
                    React.createElement("img", { className: 'w-72 h-72 object-fill p-2', src: 'https://storage.capitalike.com/files/29311', alt: 'IMAGEN 2' }),
                    React.createElement("img", { className: 'w-72 h-72 object-fill p-2', src: 'https://storage.capitalike.com/files/29314', alt: 'IMAGEN 2' }),
                    React.createElement("img", { className: 'w-72 h-72 object-fill p-2', src: 'https://storage.capitalike.com/files/29313', alt: 'IMAGEN 2' }))),
            React.createElement("div", { className: 'flex place-content-center' },
                React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-80 ' }),
                React.createElement("h4", { className: 'flex whitespace-nowrap text-center' },
                    "VIVE LA DULCE EXPERIENCIAN CON NOSOTROS, TOMA LAS FOTOS ",
                    React.createElement("br", null),
                    "M\u00C1S CUTE Y DISFRUTA CON TUS AMIGOS"),
                React.createElement("span", { className: 'flex border-solid m-auto border-chime-brown bg-chime-brown w-80 ' })),
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'flex place-content-center' },
                    React.createElement("h1", { className: 'text-chime-pink' }, "NUESTRA HISTORIA")),
                React.createElement("div", { className: 'flex flex-row ' },
                    React.createElement("div", { className: 'flex flex-1' }, " "),
                    React.createElement("div", { className: 'flex flex-1' },
                        React.createElement("p", { className: 'text-justify' },
                            React.createElement("span", { className: 'text-chime-pink font-bold leading-none' }, "La Chime"),
                            " es una innovadora empresa fundada en 2020 en la vibrante ciudad de Culiac\u00E1n, Sinaloa, M\u00E9xico. Nuestra misi\u00F3n es ofrecer productos deliciosos y visualmente impactantes que encanten a nuestros clientes. Nos especializamos en tres productos principales: boba, pan y helado suave, cada uno elaborado con los m\u00E1s altos est\u00E1ndares de calidad.")),
                    React.createElement("div", { className: 'flex flex-1' }, " ")),
                React.createElement("div", { className: 'flex place-content-center' },
                    React.createElement("img", { className: 'w-56 mt-2', src: 'https://storage.capitalike.com/files/28831', alt: 'LOGO' })),
                React.createElement("div", { className: 'space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent mt-5 place-content-center' },
                    React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active' },
                        React.createElement("div", { className: 'flex items-center mr-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-chime-pink text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2020"),
                        React.createElement("div", { className: 'md:w-[calc(40%-1rem)] bg-white p-4  rounded border shadow-md shadow-chime-pink mr-7' },
                            React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-justify' },
                                "La Chime abre sus puertas en Culiac\u00E1n, Sinaloa, ofreciendo una experiencia \u00FAnica al fusionar boba, pan y helado en un solo lugar, conquistando los paladares locales desde su inicio.",
                                React.createElement("img", { className: 'w-20 ml-10 mt-2 rounded-lg', src: 'https://storage.capitalike.com/files/28674', alt: 'LOGO' })))),
                    React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active' },
                        React.createElement("div", { className: 'flex items-center ml-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-blue-800 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2021"),
                        React.createElement("div", { className: 'md:w-[calc(40%-1rem)] bg-white p-4 rounded border border-slate-200 shadow-md shadow-blue-800 ml-7' },
                            React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-justify' },
                                React.createElement("img", { className: 'w-20 mr-10 mt-2 rounded-lg', src: 'https://storage.capitalike.com/files/29115', alt: 'LOGO' }),
                                "La Chime expande su presencia en Culiac\u00E1n con la apertura de una segunda ubicaci\u00F3n, consolidando su reputaci\u00F3n como el destino preferido para los amantes de la boba, el pan y el helado en la regi\u00F3n."))),
                    React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active' },
                        React.createElement("div", { className: 'flex items-center mr-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-[#fcb404]  text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2022"),
                        React.createElement("div", { className: 'md:w-[calc(40%-1rem)] bg-white  rounded border border-slate-200 shadow-md shadow-[#fcb404] p-4 mr-7' },
                            React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-justify' },
                                "La Chime ampl\u00EDa su alcance a trav\u00E9s de alianzas estrat\u00E9gicas con proveedores locales, asegurando ingredientes frescos y de alta calidad para sus productos exclusivos de boba, pan y helado.",
                                React.createElement("img", { className: 'w-20 ml-10 mt-2 rounded-lg', src: 'https://storage.capitalike.com/files/29091', alt: 'LOGO' })))),
                    React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active' },
                        React.createElement("div", { className: 'flex items-center ml-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-[#fb341c] text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2023"),
                        React.createElement("div", { className: 'md:w-[calc(40%-1rem)] bg-white  rounded border border-slate-200 shadow-md shadow-[#fb341c] p-4 ml-7' },
                            React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-justify' },
                                React.createElement("img", { className: 'w-20 mr-10 mt-2 rounded-lg', src: 'https://storage.capitalike.com/files/29088', alt: 'LOGO' }),
                                "La popularidad de La Chime trasciende las fronteras de Culiac\u00E1n, atrayendo la atenci\u00F3n de clientes de ciudades vecinas y turistas que buscan deleitarse con su innovadora oferta gastron\u00F3mica."))),
                    React.createElement("div", { className: 'relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active' },
                        React.createElement("div", { className: 'flex ml-2 items-center mr-5 justify-center w-20 h-20 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-[#946c44] text-white group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2 font-bold text-xl' }, "2024"),
                        React.createElement("div", { className: 'md:w-[calc(40%-1rem)] bg-white p-4 rounded border border-slate-200 shadow-md shadow-[#946c44] mr-7' },
                            React.createElement("div", { className: 'text-slate-500 leading-none flex flex-row items-center text-justify' },
                                "La Chime celebra su cuarto aniversario con el lanzamiento de una l\u00EDnea de productos exclusivos, incluyendo ediciones limitadas de boba, pan y helado, consolidando su posici\u00F3n como un referente en la escena culinaria de Sinaloa y m\u00E1s all\u00E1.",
                                React.createElement("img", { className: 'w-20 ml-10 mt-2 rounded-lg', src: 'https://storage.capitalike.com/files/29094', alt: 'LOGO' })))),
                    React.createElement(CopyrightComponent, null))))));
};
export default LaChimeEsTemplateComponent;
