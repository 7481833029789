import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import OptionComponent from './Option';
var ColorInfoProductImageComponent = function (props) {
    var _a = useState(0), selected = _a[0], setSelected = _a[1];
    var handleClick = function (i) {
        setSelected(i);
        props.onClick(props.Colors[i].id);
    };
    return (React.createElement("div", { className: 'flex flex-1 justify-start items-start flex-wrap mt-1' }, props.Colors &&
        props.Colors.map(function (el, i) { return (React.createElement("div", { key: el.id, className: 'mr-2' },
            React.createElement(Tooltip, { title: el.attribute },
                React.createElement("div", null,
                    React.createElement(OptionComponent, { onClick: handleClick, attribute: el.attribute, isSelected: i === selected, index: i }))))); })));
};
export default ColorInfoProductImageComponent;
