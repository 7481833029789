import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import getProductMenuValueFromPath from './getMenuValueFromPath';
import getRouteFromProductMenuValue from './getRouteFromMenuValue';
var MenuComponentEsChimeLayout = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getProductMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var handleChange = function (e, value) {
        console.log(1);
        var route = getRouteFromProductMenuValue(value);
        console.log(route);
        if (route)
            navigate(route);
    };
    return (React.createElement("div", { className: 'flex-1 place-content-center ml-48' },
        React.createElement(Tabs, { value: value, onChange: handleChange, "aria-label": 'basic tabs example', textColor: 'secondary', indicatorColor: 'secondary' },
            React.createElement(Tab, { label: 'Inicio' }),
            React.createElement(Tab, { label: 'Men\u00FA' }),
            React.createElement(Tab, { label: 'Ubicaci\u00F3n' }),
            React.createElement(Tab, { label: 'Franquicia' }),
            React.createElement(Tab, { label: 'Cont\u00E1ctanos' }))));
};
export default MenuComponentEsChimeLayout;
