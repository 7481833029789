import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
var IconButtonComponent = function (props) {
    var handleClick = function (e) {
        props.onClick(e, props.params);
    };
    if (props.hide)
        return React.createElement("div", null);
    return (React.createElement(Tooltip, { title: props.label, classes: { tooltip: 'mt-2' } },
        React.createElement("span", null,
            React.createElement(IconButton, { color: props.color, onClick: handleClick, "aria-label": props.label, className: props.className, disabled: props.disabled, size: props.size || 'large' }, props.icon))));
};
export default IconButtonComponent;
