import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import NotFoundComponent from './NotFound';
import LandingComponent from '../containers/Landing';
import LandingLayoutComponent from './LandingLayout';
import ClassificationComponent from '../containers/Classification';
import ProductComponent from '../containers/Product';
import SignupComponent from '../containers/_Session/Signup';
import ForgotPasswordComponent from '../containers/_Session/ForgotPassword';
import PrivacyStaticInformationComponent from '../containers/StaticInformation/privacy';
import TermsStaticInformationComponent from '../containers/StaticInformation/terms';
import ContactUsStaticInformationComponent from '../containers/StaticInformation/ContactUs';
import OurShopsStaticInformationComponent from '../containers/StaticInformation/ourShops';
import AboutUsStaticInformationComponent from '../containers/StaticInformation/aboutUs';
import HowBuyStaticInformationComponent from '../containers/StaticInformation/howBuy';
import PaymentMethodsStaticInformationComponent from '../containers/StaticInformation/paymentMethods';
import ShippingStaticInformationComponent from '../containers/StaticInformation/shipping';
import ReturnsStaticInformationComponent from '../containers/StaticInformation/returns';
import InvoivingStaticInformationComponent from '../containers/StaticInformation/Invoicing';
import ChatComponent from '../containers/Chat';
import PaymentComponentEcommerce from '../containers/_Payment';
// import LaChimeTemplateComponent from '../containers/LaChimeTemplate';
// import MenuComponentChime from '../containers/LaChimeTemplateEn/Menu';
// import ContactComponentChime from '../containers/LaChimeTemplateEn/Contact';
// import LocationComponent from '../containers/LaChimeTemplateEn/Location';
// import FranchiseComponent from '../containers/LaChimeTemplateEn/Franchise';
// import HearderComponentChime from './LaChimeEn/LaChimeLayout';
// import LaChimeTemplateComponent from '../containers/LaChimeTemplateEn';
import BodyProductListComponent from '../containers/productList/Body';
import ContactEsComponentChime from '../containers/LaChimeTemplateEs/Contact';
import FranchiseEsComponent from '../containers/LaChimeTemplateEs/Franchise';
import LocationEsComponent from '../containers/LaChimeTemplateEs/Location';
// import HearderEsComponentChime from './LaChimeEs/LaChimeLayout';
import LaChimeEnTemplateComponent from '../containers/LaChimeTemplateEn';
import HearderEnComponentChime from './LaChimeEn/LaChimeLayout';
import MenuEnComponentChime from '../containers/LaChimeTemplateEn/Menu';
import ContactEnComponentChime from '../containers/LaChimeTemplateEn/Contact';
import LocationEnComponent from '../containers/LaChimeTemplateEn/Location';
import FranchiseEnComponent from '../containers/LaChimeTemplateEn/Franchise';
import MenuEsComponentChime from '../containers/LaChimeTemplateEs/Menu';
import HearderEsComponentChime from './LaChimeEs/LaChimeLayout';
import LaChimeEsTemplateComponent from '../containers/LaChimeTemplateEs';
import TopMenuEs from '../containers/LaChimeTemplateEs/Menu/TopMenu';
import ClassificationItemProductListComponent from '../containers/productList/Body/ClassificationItem';
// import CompanyLayoutComponent from './CompanyLayout';
// import ProductListComponent from '../containers/LaChimeTemplate/productList';
var children = [
    { index: true, element: React.createElement(LandingComponent, null) },
    { path: 'aboutUs', element: React.createElement(AboutUsStaticInformationComponent, null) },
    { path: 'chat', element: React.createElement(ChatComponent, null) },
    { path: 'classification/:classificationId', element: React.createElement(ClassificationComponent, null) },
    { path: 'contactUs', element: React.createElement(ContactUsStaticInformationComponent, null) },
    { path: 'forgotPassword', element: React.createElement(ForgotPasswordComponent, null) },
    { path: 'howBuy', element: React.createElement(HowBuyStaticInformationComponent, null) },
    { path: 'invoicing', element: React.createElement(InvoivingStaticInformationComponent, null) },
    { path: 'ourShops', element: React.createElement(OurShopsStaticInformationComponent, null) },
    { path: 'paymentMethods', element: React.createElement(PaymentMethodsStaticInformationComponent, null) },
    { path: 'product/:productId', element: React.createElement(ProductComponent, null) },
    { path: 'privacy', element: React.createElement(PrivacyStaticInformationComponent, null) },
    { path: 'returns', element: React.createElement(ReturnsStaticInformationComponent, null) },
    { path: 'shipping', element: React.createElement(ShippingStaticInformationComponent, null) },
    { path: 'signup', element: React.createElement(SignupComponent, null) },
    { path: 'terms', element: React.createElement(TermsStaticInformationComponent, null) },
];
export var domainRouter = createBrowserRouter([
    {
        path: '/',
        element: React.createElement(LandingLayoutComponent, null),
        children: children,
    },
    { path: 'payment', element: React.createElement(PaymentComponentEcommerce, null) },
    { path: '*', element: React.createElement(NotFoundComponent, null) },
]);
var router = createBrowserRouter([
    {
        path: 'lachime/es',
        element: React.createElement(HearderEsComponentChime, null),
        children: [
            { index: true, element: React.createElement(LaChimeEsTemplateComponent, null) },
            {
                path: 'menu',
                element: React.createElement(MenuEsComponentChime, null),
                children: [
                    { index: true, element: React.createElement(TopMenuEs, null) },
                    {
                        path: 'classification/:classificationId',
                        element: React.createElement(ClassificationItemProductListComponent, null),
                    },
                    // { path: 'drink', element: <DrinkComponent /> },
                    // { path: 'hotdrinks', element: <HotDrinksComponent /> },
                ],
            },
            {
                path: 'contact',
                element: React.createElement(ContactEsComponentChime, null),
            },
            {
                path: 'location',
                element: React.createElement(LocationEsComponent, null),
            },
            {
                path: 'franchise',
                element: React.createElement(FranchiseEsComponent, null),
            },
        ],
    },
    {
        path: 'lachime/en',
        element: React.createElement(HearderEnComponentChime, null),
        children: [
            { index: true, element: React.createElement(LaChimeEnTemplateComponent, null) },
            {
                path: 'menu',
                element: React.createElement(MenuEnComponentChime, null),
                children: [
                    { index: true, element: React.createElement(BodyProductListComponent, null) },
                    { path: 'classification/:classificationId', element: React.createElement(BodyProductListComponent, null) },
                    // { path: 'drink', element: <DrinkComponent /> },
                    // { path: 'hotdrinks', element: <HotDrinksComponent /> },
                ],
            },
            {
                path: 'contact',
                element: React.createElement(ContactEnComponentChime, null),
            },
            {
                path: 'location',
                element: React.createElement(LocationEnComponent, null),
            },
            {
                path: 'franchise',
                element: React.createElement(FranchiseEnComponent, null),
            },
        ],
    },
    // { path: 'payment', element: <PaymentComponentEcommerce /> },
    // { path: '*', element: <NotFoundComponent /> },
]);
export default router;
