import React from 'react';
import CloseIconButtonUtilsComponent from '../../../../../../../../utils/CloseIconButton';
import { isECCartOpen } from '../../../../../../../../apollo/cache';
var CloseIconButtonDialogSearchActionNavigationSecondaryContentComponent = function () {
    var handleClose = function () {
        isECCartOpen(false);
    };
    return React.createElement(CloseIconButtonUtilsComponent, { onClose: handleClose });
};
export default CloseIconButtonDialogSearchActionNavigationSecondaryContentComponent;
