import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ShowMoreButton from './ShowMoreButton';
import Image from './Image';
var ContainerProductImageComponent = function (props) {
    var _a = useState(false), showButton = _a[0], setShowButton = _a[1];
    var handleShowButton = function () {
        setShowButton(true);
    };
    var handleHideButton = function () {
        setShowButton(false);
    };
    return (React.createElement(Link, { to: "../product/".concat(props.productId).concat(props.productAttributeId ? "?color=".concat(props.productAttributeId) : ''), onMouseOver: handleShowButton, onMouseLeave: handleHideButton },
        React.createElement("div", { className: 'w-full p-0 overflow-hidden relative  h-[230px] sm:h-[400px] flex flex-col items-center' },
            React.createElement("div", { className: "absolute w-full h-full flex items-center justify-center overflow-hidden opacity-100 ".concat(props.Files[1] ? 'hover:opacity-0' : '') },
                React.createElement(Image, { name: props.productName, fileId: props.Files[0] })),
            props.Files[1] && (React.createElement("div", { className: 'absolute w-full h-full flex items-center justify-center overflow-hidden top-0 left-0 transition-opacity opacity-0 hover:opacity-100 duration-200 ease-in-out' },
                React.createElement(Image, { name: props.productName, fileId: props.Files[1] }))),
            React.createElement("div", { className: "w-full absolute bottom-5 overflow-hidden p-0 hidden sm:flex transition-opacity ".concat(showButton ? 'opacity-100' : 'opacity-0', " duration-200 ease-in-out") },
                React.createElement(ShowMoreButton, null)))));
};
export default ContainerProductImageComponent;
