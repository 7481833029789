import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import FormChatComponent from './Form';
import getChatSession from '../../utils/getChatSession';
import RoomChatComponent from './Room';
var ChatComponent = function () {
    var _a = useState(null), session = _a[0], setSession = _a[1];
    var handleCreatedSession = function (publicUserToken, publicUserRoom) {
        setSession({
            publicUserToken: publicUserToken,
            publicUserRoom: publicUserRoom,
        });
    };
    useEffect(function () {
        var session = getChatSession();
        if (!session)
            return;
        handleCreatedSession(session === null || session === void 0 ? void 0 : session.publicUserToken, session === null || session === void 0 ? void 0 : session.publicUserRoom);
    }, []);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(Grid, { container: true, spacing: 2, className: 'p-5 md:px-36 border-solid border-[#ccc] border-0 border-b-[1px] bg-white' },
            React.createElement(Grid, { item: true, xs: 12, md: 8 },
                React.createElement(Typography, { variant: 'h6', display: 'block', className: 'subTitle', gutterBottom: true }, "Inicio / Chat"),
                React.createElement(Typography, { variant: 'h4' },
                    React.createElement("strong", null, "Chat en vivo"),
                    React.createElement("br", null),
                    React.createElement("div", { className: 'text-lg text-gray-700 mb-2' }, "Comun\u00EDcate con nosotros al instante por medio de este chat. Los horarios de atenci\u00F3n son de lunes a s\u00E1bado de 10:00 a.m. a 18:00 p.m.")),
                session ? (React.createElement(RoomChatComponent, { session: session })) : (React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                        React.createElement(FormChatComponent, { onCreatedSession: handleCreatedSession }))))))));
};
export default ChatComponent;
