import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { snackbar } from '../../apollo/cache';
var SnackbarComponent = function () {
    var snackbarVar = useReactiveVar(snackbar);
    var handleClose = function () {
        snackbar({ isOpen: false, time: 3000, label: '', severity: 'success' });
    };
    return (React.createElement(MuiSnackbar, { open: snackbarVar === null || snackbarVar === void 0 ? void 0 : snackbarVar.isOpen, autoHideDuration: (snackbarVar === null || snackbarVar === void 0 ? void 0 : snackbarVar.time) || null, anchorOrigin: {
            vertical: (snackbarVar === null || snackbarVar === void 0 ? void 0 : snackbarVar.vertical) || 'bottom',
            horizontal: (snackbarVar === null || snackbarVar === void 0 ? void 0 : snackbarVar.horizontal) || 'center',
        }, onClose: handleClose },
        React.createElement(MuiAlert, { elevation: 6, color: snackbarVar === null || snackbarVar === void 0 ? void 0 : snackbarVar.severity, variant: 'filled', onClose: handleClose }, snackbarVar === null || snackbarVar === void 0 ? void 0 : snackbarVar.label)));
};
export default SnackbarComponent;
