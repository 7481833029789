import { format, differenceInMinutes } from 'date-fns';
import locale from 'date-fns/locale/es';

export const getTotalFromPercent = (percent, total) => {
  return parseFloat(total) * (percent / 100);
};

export const addressFormat = address => {
  const addressLine1 = address.addressLine1 ? `${address.addressLine1}, ` : '';
  const addressLine2 = address.addressLine2 ? `${address.addressLine2}, ` : '';
  const city = address.city ? `${address.city}, ` : '';
  const State = address.state ? `${address.state}, ` : '';
  const zipCode = address.zipCode ? `CP ${address.zipCode}` : '';

  return `${addressLine1}${addressLine2}${city}${State}${zipCode}`.trim();
};

export const currencyFormat = num =>
  parseFloat(num)
    .toFixed(2)
    .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const phoneFormat = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) return `(${match[1]}) ${match[2]}-${match[3]}`;

  return phoneNumberString;
};

export const initials = string => {
  if (typeof string !== 'string') return '';
  return string
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();
};

export const capitalizeAlt = string => {
  if (typeof string !== 'string') return '';
  return string.replace(/\w\S*/g, w => {
    return w.replace(/^\w/, c => c.toUpperCase());
  });
};

export const capitalize = string => {
  if (typeof string !== 'string') return '';
  return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
};

export const queryFormat = values => {
  let query = '';
  Object.keys(values).forEach(key => {
    query += `${key}=${values[key]}&`;
  });
  return query.slice(0, -1);
};

export const parseError = e => {
  if (e.message.includes('Network error'))
    return [{ name: 'NETWORK_ERROR', message: 'El servidor no responde, intente más tarde.' }];
  const parseErrors =
    e.graphQLErrors &&
    e.graphQLErrors
      .map(({ extensions, message }) => {
        if (extensions.code === 'BAD_USER_INPUT') {
          const errorArray = JSON.parse(message);
          const errors = errorArray.fields.reduce(
            (object, el) => ({ ...object, [el.name || el.code]: el.message }),
            {},
          );
          return { name: extensions.code, message: errors };
        }
        if (
          [
            'GENERAL_ERROR',
            'INVENTORY_ERROR',
            'PAYMENT_ERROR',
            'PRODUCT_INVOICE_ERROR',
            'INTERNAL_SERVER_ERROR',
          ].includes(extensions.code)
        )
          return { name: extensions.code, message };

        return null;
      })
      .filter(el => el);
  return parseErrors;
};

export const transform = (text, type) => {
  switch (type) {
    case 'uppercase':
      return text.toUpperCase();
    case 'capitalize':
      return capitalize(text);
    default:
      return text.toLowerCase();
  }
};

export const dateTimeFormat = (date, cFormat) =>
  `${format(new Date(date), cFormat || 'dd/MM/yyyy HH:mm:ss', { locale })}`;
export const dateFormat = date => `${format(new Date(date), "EEEE, d 'de' MMMM", { locale })}`;
export const hourFormat = (date, hour = 'h') =>
  `${format(new Date(date), `${hour}:mmaaa`, { locale })}`;

export const eventFormat = (start, end) => {
  if (format(new Date(start), 'yyyy/MM/dd') === format(new Date(end), 'yyyy/MM/dd')) {
    const hour =
      hourFormat(start) !== hourFormat(end)
        ? ` ${hourFormat(start)}-${hourFormat(end)}`
        : ` ${hourFormat(start)}`;
    return `${dateFormat(start)}${hour}`;
  }
  return `${dateFormat(start)} ${hourFormat(start)} - ${dateFormat(end)} ${hourFormat(end)}`;
};

export const customerCheckTime = (start, end) => {
  const tmp = differenceInMinutes(
    start ? new Date(start) : new Date(),
    end ? new Date(end) : new Date(),
  );
  return tmp > 0 ? [true, `Demorado ${tmp} minutos`] : [false, 'A Tiempo, muchas gracias.'];
};

export const calendarVisibilityOptions = [
  { label: 'Público', value: 1 },
  { label: 'Privado', value: 2 },
];

export const hourOptions = [
  { value: 0, label: '00:00am' },
  { value: 0.5, label: '00:30am' },
  { value: 1, label: '01:00am' },
  { value: 1.5, label: '01:30am' },
  { value: 2, label: '02:00am' },
  { value: 2.5, label: '02:30am' },
  { value: 3, label: '03:00am' },
  { value: 3.5, label: '03:30am' },
  { value: 4, label: '04:00am' },
  { value: 4.5, label: '04:30am' },
  { value: 5, label: '05:00am' },
  { value: 5.5, label: '05:30am' },
  { value: 6, label: '06:00am' },
  { value: 6.5, label: '06:30am' },
  { value: 7, label: '07:00am' },
  { value: 7.5, label: '07:30am' },
  { value: 8, label: '08:00am' },
  { value: 8.5, label: '08:30am' },
  { value: 9, label: '09:00am' },
  { value: 9.5, label: '09:30am' },
  { value: 10, label: '10:00am' },
  { value: 10.5, label: '10:30am' },
  { value: 11, label: '11:00am' },
  { value: 11.5, label: '11:30am' },
  { value: 12, label: '12:00pm' },
  { value: 12.5, label: '12:30pm' },
  { value: 13, label: '01:00pm' },
  { value: 13.5, label: '01:30pm' },
  { value: 14, label: '02:00pm' },
  { value: 14.5, label: '02:30pm' },
  { value: 15, label: '03:00pm' },
  { value: 15.5, label: '03:30pm' },
  { value: 16, label: '04:00pm' },
  { value: 16.5, label: '04:30pm' },
  { value: 17, label: '05:00pm' },
  { value: 17.5, label: '05:30pm' },
  { value: 18, label: '06:00pm' },
  { value: 18.5, label: '06:30pm' },
  { value: 19, label: '07:00pm' },
  { value: 19.5, label: '07:30pm' },
  { value: 20, label: '08:00pm' },
  { value: 20.5, label: '08:30pm' },
  { value: 21, label: '09:00pm' },
  { value: 21.5, label: '09:30pm' },
  { value: 22, label: '10:00pm' },
  { value: 22.5, label: '10:30pm' },
  { value: 23, label: '11:00pm' },
  { value: 23.5, label: '11:30pm' },
];
export const objectArraySort = (object, key, type) => {
  return object.sort((a, b) =>
    type === 'int'
      ? parseInt(a[key]) > parseInt(b[key])
        ? 1
        : parseInt(b[key]) > parseInt(a[key])
        ? -1
        : 0
      : a[key] > b[key]
      ? 1
      : b[key] > a[key]
      ? -1
      : 0,
  );
};

export const countryCode = {
  México: '+52',
  'Estados Unidos': '+1',
};
