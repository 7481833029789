import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useQuery } from '@apollo/client';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
import ItemEmojiFieldComponent from './Item';
import EmojiPositionComponent from './Position';
var EmojiFieldComponent = function () {
    var _a = useQuery(GQL_PRODUCT.GET_EMOJI_FILES), data = _a.data, loading = _a.loading;
    return (React.createElement(Accordion, { className: 'shadow-none border-0 p-0 m-0' },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, { className: 'p-0 m-0' }), "aria-controls": 'panel1a-content', id: 'panel1a-details', classes: {
                root: 'm-0 p-0',
                content: 'm-0',
            } }, "A\u00F1adir emoji"),
        React.createElement(AccordionDetails, { className: 'pt-0 mt-0 px-0 flex flex-col' },
            loading || !data ? ('Cargando emojis') : (React.createElement("div", { className: 'flex-1 grid grid-cols-3 gap-2 place-items-center' }, data.files.rows.map(function (el) { return (React.createElement(ItemEmojiFieldComponent, { key: el.id, fileId: el.fileId, label: 'Emoji' })); }))),
            React.createElement(EmojiPositionComponent, null))));
};
export default EmojiFieldComponent;
