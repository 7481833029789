import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import { isECMenuOpen } from '../../../../../../apollo/cache';
var MenuButtonComponent = function () {
    var handleClick = function () {
        isECMenuOpen(true);
    };
    return (React.createElement("div", { className: 'flex sm:hidden' },
        React.createElement(IconButton, { onClick: handleClick },
            React.createElement(MenuIcon, null))));
};
export default MenuButtonComponent;
