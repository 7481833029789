import React from 'react';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
var Root = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return ({
        '&.root': {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        '& .content': (_b = {
                padding: theme.spacing(2, 18, 8, 18),
                borderBottom: '1px solid #ccc',
                background: '#fff'
            },
            _b[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(2),
            },
            _b),
        '& .subTitle': {
            color: '#4f4f4f',
        },
        '& .img': {
            width: '100%',
        },
    });
});
var PaymentMethodsStaticInformationComponent = function () {
    var theme = useTheme();
    var isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (React.createElement(Root, { className: 'root' },
        React.createElement("div", { className: 'content' },
            React.createElement(Grid, { container: true, spacing: 4, alignItems: 'center' },
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    !isSmallScreen && React.createElement("br", null),
                    React.createElement(Typography, { variant: 'h6', display: 'block', className: 'subTitle' }, "Efectivo"),
                    React.createElement(Typography, { variant: 'h4', gutterBottom: true },
                        React.createElement("strong", null, "Paga con efectivo en OXXO")),
                    React.createElement(Typography, { variant: 'body1' }, "Podr\u00E1s hacer pagos en efectivo acudiendo a la tienda m\u00E1s cercana de OXXO. Los pagos se acreditan al instante."),
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement(Typography, { variant: 'h6', display: 'block', className: 'subTitle' }, "Tarjeta"),
                    React.createElement(Typography, { variant: 'h4', gutterBottom: true },
                        React.createElement("strong", null, "Paga de forma f\u00E1cil y segura con tu tarjeta")),
                    React.createElement(Typography, { variant: 'body1' }, "Podr\u00E1s pagar con tu tarjeta de cr\u00E9dito o d\u00E9bito de forma segura desde cualquier dispositivo con conexi\u00F3n a internet."))))));
};
export default PaymentMethodsStaticInformationComponent;
