import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
var useGetProportionsToFind = function () {
    var theme = useTheme();
    var _a = useState([null, null]), proportions = _a[0], setProportions = _a[1];
    var isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(function () {
        if (isSmallScreen)
            setProportions([14, 17]);
        else if (isMediumScreen)
            setProportions([13, 16]);
        else
            setProportions([12, 15]);
    }, [isSmallScreen, isMediumScreen]);
    return proportions;
};
export default useGetProportionsToFind;
