var getRouteFromProductMenuValue = function (value) {
    switch (value) {
        case 0:
            return '.';
        case 1:
            return 'menu';
        case 2:
            return 'location';
        case 3:
            return 'franchise';
        case 4:
            return 'contact';
        default:
            return false;
    }
};
export default getRouteFromProductMenuValue;
