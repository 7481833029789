import React from 'react';
import { styled } from '@mui/system';
import Link from './helpers/Link';
var RootComponent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 3, 2, 3),
        borderTop: '1px solid #dadada',
    });
});
var LegalInformationBodyPaymentComponent = function () {
    return (React.createElement(RootComponent, null,
        React.createElement(Link, { route: '../returns', label: 'Pol\u00EDticas de devoluciones' }),
        React.createElement(Link, { route: '../shipping', label: 'Pol\u00EDticas de env\u00EDo' }),
        React.createElement(Link, { route: '../privacy', label: 'Aviso de privacidad' }),
        React.createElement(Link, { route: '../terms', label: 'T\u00E9rminos y condiciones' })));
};
export default LegalInformationBodyPaymentComponent;
