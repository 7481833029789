import { gql } from '@apollo/client';

const CREATE_ECCUSTOMER = gql`
  mutation CreateECCustomer(
    $ecName: String
    $hostname: String
    $password: String
    $firstName: String!
    $phone: String!
    $email: String
  ) {
    createECCustomer(
      ecName: $ecName
      hostname: $hostname
      input: { password: $password, firstName: $firstName, phone: $phone, email: $email }
    ) {
      id
      firstName
      phone
      email
      user
    }
  }
`;

export default {
  CREATE_ECCUSTOMER,
};
