var getCountryCode = function (value) {
    switch (value) {
        case 'México':
            return '+52';
        case 'Estados Unidos':
            return '+1';
        default:
            return '';
    }
};
export default getCountryCode;
