var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import GQL_PRODUCT from '../../apollo/gql/product';
import getImages from '../../utils/getImages';
import LoadingProductsComponents from '../../presentationals/LoadingProducts';
import ProductImageComponent from '../../presentationals/ProductImage';
var ClassificationComponent = function () {
    var params = useParams();
    var _a = useState([]), products = _a[0], setProducts = _a[1];
    var _b = useQuery(GQL_PRODUCT.GET_PRODUCTS, {
        variables: {
            limit: 20,
            offset: 0,
            classificationId: params.classificationId,
            companyName: params.companyName,
        },
        // notifyOnNetworkStatusChange: true,
    }), data = _b.data, loading = _b.loading, fetchMore = _b.fetchMore;
    useEffect(function () {
        setProducts([]);
    }, [params.classificationId]);
    var handleScroll = function () {
        var scrollPosition = window.scrollY;
        var maxScroll = document.documentElement.scrollHeight - window.innerHeight;
        if (scrollPosition / maxScroll >= 0.8 && loading === false && data) {
            var currentProductCount = data.productsEC.length;
            if (products.length < currentProductCount)
                fetchMore({
                    variables: {
                        offset: currentProductCount,
                    },
                });
        }
    };
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        return function () {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, data]);
    useEffect(function () {
        if (data) {
            var newProducts_1 = data.productsEC.map(function (el) {
                var Images = getImages({
                    ProductImages: el.ProductFiles,
                    ProductPresentation: el.ProductPresentations,
                    ProductAttributes: el.ProductAttributes,
                });
                return __assign(__assign({}, el), { Images: Images, Colors: el.ProductAttributes.filter(function (el) { return el.type === 1; }) });
            });
            setProducts(function (products) { return __spreadArray(__spreadArray([], products, true), newProducts_1, true); });
        }
    }, [data]);
    if (loading)
        return React.createElement(LoadingProductsComponents, { className: 'sm:p-20', cols: 4, rows: 1 });
    return (React.createElement("div", { className: 'bg-white p-4 sm:p-20 grid grid-cols-2 sm:grid-cols-4 gap-10' }, products.map(function (el) { return (React.createElement(ProductImageComponent, { key: el.id, Product: el })); })));
};
export default ClassificationComponent;
