import React from 'react';
import { Link } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isECMenuOpen } from '../../../../../../apollo/cache';
var ItemMenuOptionDialogMenuHeaderMainContentComponent = function (props) {
    var theme = useTheme();
    var isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var handleClick = function () {
        isSmallScreen && isECMenuOpen(false);
    };
    return (React.createElement(Link, { to: props.dir, className: 'text-2xl block text-[#333] hover:font-medium no-underline mt-1', onClick: handleClick }, props.label));
};
export default ItemMenuOptionDialogMenuHeaderMainContentComponent;
