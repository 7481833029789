import React from 'react';
var ItemProductPresentationType1ProductComponent = function (props) {
    var handleClick = function () {
        props.onClick(props.params);
    };
    return (React.createElement("div", { className: 'rounded border-2 border-solid px-4 py-2 mr-2 mb-2', style: {
            borderColor: props.isSelected ? '#787878' : '#dadada',
        }, role: 'button', tabIndex: -1, onClick: handleClick }, props.label));
};
export default ItemProductPresentationType1ProductComponent;
