var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ECCustomerSessionData, ECPaymentForm, ECShipmentRates, ECShipping, inputError, } from '../../../../../apollo/cache';
import capitalize from '../../../../../utils/capitalize';
import currencyFormat from '../../../../../utils/currencyFormat';
import getCountryCode from '../../../../../utils/getCountryCode';
import getAddressFormat from '../../../../../utils/getAddressFormat';
var GridContainer = styled(Grid)(function (_a) {
    var theme = _a.theme;
    return ({
        '& .spaceBetween': {
            marginTop: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& .addButton': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(1.5, 6, 1.5, 6),
            backgroundColor: '#2196f3',
            boxShadow: 'none',
            color: '#fff',
            fontSize: 14,
            '&:hover': {
                backgroundColor: '#0e8df2',
            },
        },
        '& .footer': {
            display: 'flex',
            padding: theme.spacing(2, 4, 3, 4),
            textAlign: 'right',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& .linkBack': {
            display: 'flex',
            alignItems: 'center',
            color: '#808080',
        },
        '& .subtitle': {
            color: '#808080',
        },
        '& .gridContainer': {
            padding: theme.spacing(1),
        },
        '& .gridShippingContainer': {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        '& .formLabel': {
            fontSize: 14,
        },
        '& .cardError': {
            borderColor: '#ff0000',
        },
        '& .labelError': {
            marginTop: theme.spacing(1),
            color: '#ff0000',
        },
        '& .marginBottom': {
            marginBottom: theme.spacing(2),
        },
    });
});
var defaultValues = {
    shipping: '',
};
var defaultShippingOptions = [
    {
        id: 'local',
        provider: 'Encanta',
        serviceLevel: 'Entrega a Domicilio (Soló ciudad Culiacán)',
        durationTerms: '1 hábiles',
        currency: 'MXN',
        amount: '50',
    },
];
var Form2BodyPaymentComponent = function () {
    var theme = useTheme();
    var isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var errors = useReactiveVar(inputError);
    var ECPaymentFormVar = useReactiveVar(ECPaymentForm);
    var ECShipmentRatesVar = useReactiveVar(ECShipmentRates);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useState(defaultShippingOptions), shipmentRates = _b[0], setShipmentRates = _b[1];
    useEffect(function () {
        if (ECPaymentFormVar && ECPaymentFormVar.shipping)
            setValues(function (values) { var _a; return (__assign(__assign({}, values), { shipping: ((_a = ECPaymentFormVar.shipping) === null || _a === void 0 ? void 0 : _a.toString()) || '' })); });
    }, [ECPaymentFormVar]);
    var handleChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        var dataSession = ECCustomerSessionData();
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
        ECPaymentForm(__assign(__assign({}, ECPaymentForm()), (_a = {}, _a[name] = value, _a)));
        var findRate = shipmentRates.find(function (el) { return el.id === value; });
        if (!findRate)
            return;
        ECShipping(parseFloat(findRate.amount));
        if (findRate.id !== 'local')
            ECCustomerSessionData(__assign(__assign({}, dataSession), { miEnvioShipmentRateId: findRate.id }));
    };
    useEffect(function () {
        setShipmentRates(function (rates) { return __spreadArray(__spreadArray([], rates, true), ECShipmentRatesVar, true); });
    }, [ECShipmentRatesVar]);
    return (React.createElement(GridContainer, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Typography, { variant: 'h6', gutterBottom: true }, "Informaci\u00F3n del contacto"),
            React.createElement(Card, { variant: 'outlined', className: 'marginBottom' },
                React.createElement(Grid, { container: true, className: 'gridContainer' },
                    React.createElement(Grid, { item: true, md: 2, xs: 3 },
                        React.createElement(Typography, { variant: 'body2', className: 'subtitle' }, "Contacto")),
                    React.createElement(Grid, { item: true, md: 10, xs: 9 },
                        React.createElement(Typography, { variant: 'body2' }, ECPaymentFormVar.name))),
                React.createElement(Divider, null),
                React.createElement(Grid, { container: true, className: 'gridContainer' },
                    React.createElement(Grid, { item: true, md: 2, xs: 3 },
                        React.createElement(Typography, { variant: 'body2', className: 'subtitle' }, "Tel\u00E9fono")),
                    React.createElement(Grid, { item: true, md: 10, xs: 9 },
                        React.createElement(Typography, { variant: 'body2' }, "".concat(getCountryCode(ECPaymentFormVar.country)).concat(ECPaymentFormVar.phone)))),
                React.createElement(Divider, null),
                React.createElement(Grid, { container: true, className: 'gridContainer' },
                    React.createElement(Grid, { item: true, md: 2, xs: 3 },
                        React.createElement(Typography, { variant: 'body2', className: 'subtitle' }, "Enviar a")),
                    React.createElement(Grid, { item: true, md: 10, xs: 9 },
                        React.createElement(Typography, { variant: 'body2' }, getAddressFormat(ECPaymentFormVar))))),
            React.createElement(Typography, { variant: 'h6', gutterBottom: true }, "Env\u00EDo"),
            React.createElement(Card, { variant: 'outlined' },
                React.createElement(RadioGroup, { "aria-label": 'shipping', name: 'shipping', value: values.shipping, onChange: handleChange }, shipmentRates &&
                    shipmentRates.map(function (el, i) { return (React.createElement("div", { key: el.id },
                        React.createElement(Grid, { container: true, justifyContent: 'space-between', alignItems: 'center', className: 'gridShippingContainer' },
                            React.createElement(Grid, { item: true, xs: isSmallScreen && 8 },
                                React.createElement(FormControlLabel, { value: el.id, classes: { label: 'formLabel' }, control: React.createElement(Radio, { color: 'primary' }), label: "".concat(el.provider, " ").concat(capitalize(el.serviceLevel), " (").concat(el.durationTerms, ")") })),
                            React.createElement(Grid, { item: true, xs: isSmallScreen && 4 },
                                React.createElement("strong", null, "".concat(el.currency, " ").concat(currencyFormat(parseFloat(el.amount)))))),
                        i + 1 < shipmentRates.length && React.createElement(Divider, null))); }))),
            React.createElement("div", { className: 'labelError' }, errors.shipping))));
};
export default Form2BodyPaymentComponent;
