import React from 'react';
import { styled } from '@mui/system';
import ProductDialogCartNavigationSecondaryContentCompoennt from '../../../Landing/Navigation/Sticky/Action/CartButton/Dialog/Product';
import TotalFooterDialogCartActionNavigationSecondaryContentComponent from '../../../Landing/Navigation/Sticky/Action/CartButton/Dialog/Footer/Total';
var RootComponent = styled('div')({
    '&.root': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
});
var CartBodyPaymentComponent = function () {
    return (React.createElement(RootComponent, { className: 'root' },
        React.createElement(ProductDialogCartNavigationSecondaryContentCompoennt, { inCart: true }),
        React.createElement(TotalFooterDialogCartActionNavigationSecondaryContentComponent, { inCart: true })));
};
export default CartBodyPaymentComponent;
