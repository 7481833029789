import React, { useEffect, useState } from 'react';
import getMessageCompany102 from './helpers/getMessage.company102';
import ItemIngredientsProductVariationType7ProductComponent from './Item';
var IngredientsProductVariationType7ProductComponent = function (props) {
    var _a = useState(''), message = _a[0], setMessage = _a[1];
    useEffect(function () {
        if (props.ProductVariation) {
            var message_1 = getMessageCompany102(props.ProductVariation.id);
            setMessage(message_1);
        }
    }, [props.ProductVariation]);
    if (!props.ProductVariation)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'text-2xl' }, props.ProductVariation.name),
        message && React.createElement("div", { className: 'text-lg' }, message),
        React.createElement("div", { className: 'grid flex-1 grid-cols-4 gap-6 mt-2' }, props.ProductVariation.ProductIngredients.map(function (el) {
            var _a;
            return (React.createElement(ItemIngredientsProductVariationType7ProductComponent, { key: el.id, currentStep: props.currentStep, productId: el.Ingredient.id, productIngredientId: el.id, name: el.Ingredient.name, fileId: el.Ingredient.fileId, price: el.price, limit: ((_a = props.ProductVariation) === null || _a === void 0 ? void 0 : _a.limit) || 1 }));
        }))));
};
export default IngredientsProductVariationType7ProductComponent;
