import { styled } from '@mui/system';
import React from 'react';
import { Link as LinkMui } from 'react-router-dom';
var Link = styled(LinkMui)(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: 12,
        color: '#0e8df2',
        marginRight: theme.spacing(2),
    });
});
var LinkHelpersLegalInformationBodyPayment = function (props) {
    return (React.createElement(Link, { to: props.route, color: 'inherit' }, props.label));
};
export default LinkHelpersLegalInformationBodyPayment;
