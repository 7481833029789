var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import ItemProductPresentationType1ProductComponent from '../../ProductPresentation/Item';
import { extraProductPrice, inputError } from '../../../../../apollo/cache';
var ItemProductVariationComponent = function (props) {
    var _a = useState(null), selectIngredient = _a[0], setSelectedIngredient = _a[1];
    var handleClick = function (el) {
        inputError({});
        var extraProductPriceVar = extraProductPrice();
        setSelectedIngredient(el.ProductIngredient.id);
        var newArray = extraProductPriceVar.filter(function (elCh) { return elCh.productVariationId !== el.productVariationId; });
        extraProductPrice(__spreadArray(__spreadArray([], newArray, true), [el], false));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'text-xl tracking-tighter mb-2' }, props.ProductVariation.name),
        React.createElement("div", { className: 'flex flex-wrap' }, props.ProductVariation.ProductIngredients.map(function (elCh) { return (React.createElement(ItemProductPresentationType1ProductComponent, { isSelected: elCh.id === selectIngredient, key: elCh.id, label: elCh.Ingredient.name, params: { ProductIngredient: elCh, productVariationId: props.ProductVariation.id }, onClick: handleClick })); }))));
};
export default ItemProductVariationComponent;
