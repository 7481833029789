var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Button } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { setCurrentProductIdDialog, type7ProductCart } from '../../../../../../../apollo/cache';
import useApolloLazyQueryByCompany from '../../../../../../../hooks/useLazyQueryByCompany';
import GQL_PRODUCT from '../../../../../../../apollo/gql/product';
import getInputErrorFormat from '../../../../../../../utils/getInputErrorFormat';
var AddIngredientButtonType7ProductComponent = function (props) {
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var cart = useReactiveVar(type7ProductCart);
    var _b = useApolloLazyQueryByCompany(GQL_PRODUCT.GET_PRODUCT_BY_ID), getData = _b[0], loading = _b[1].loading;
    useEffect(function () {
        var isInArray = cart.find(function (el) { return el.productIngredientId === props.productIngredientId; });
        setDisabled(!!isInArray);
    }, [cart]);
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var cart_1, productVariationInCart, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    cart_1 = type7ProductCart();
                    productVariationInCart = cart_1.filter(function (el) { return el.step === props.currentStep; });
                    if (productVariationInCart.length >= props.limit)
                        throw new Error('No es posible añadir más productos en esta sección.');
                    return [4 /*yield*/, getData({
                            variables: {
                                id: props.productId,
                            },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    if (!data)
                        return [2 /*return*/];
                    if (data.ProductPresentations.length === 1 && data.ProductAttributes.length === 0) {
                        type7ProductCart(__spreadArray(__spreadArray([], cart_1, true), [
                            {
                                productIngredientId: props.productIngredientId,
                                productId: props.productId,
                                productPresentationId: data.ProductPresentations[0].id,
                                productName: data.name,
                                productPresentationName: data.ProductPresentations[0].name,
                                step: props.currentStep,
                                price: data.ProductPresentations[0].price,
                                fileId: data.ProductPresentations[0].ProductPresentationFiles.length > 0
                                    ? data.ProductPresentations[0].ProductPresentationFiles[0].fileId
                                    : null,
                            },
                        ], false));
                        return [2 /*return*/];
                    }
                    setCurrentProductIdDialog([
                        props.productId,
                        { productIngredientId: props.productIngredientId },
                    ]);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Button, { disabled: disabled || loading, onClick: handleClick, fullWidth: true, className: 'm-0 text-[#fff] bg-[#3d3d3d] sm:bg-[#292929] hover:bg-[#000] disabled:text-[#333] disabled:bg-[#f5f5f5]' }, disabled ? 'Añadido' : 'Añadir'));
};
export default AddIngredientButtonType7ProductComponent;
