import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import GQL_CLASSIFICATION from '../../../../../apollo/gql/classification';
import useQueryByCompany from '../../../../../hooks/useQueryByCompany';
import ItemMenuOptionDialogMenuHeaderMainContentComponent from './Item';
var MenuOptionDialogMenuHeaderMainContentComponent = function () {
    var theme = useTheme();
    var isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var _a = useQueryByCompany(GQL_CLASSIFICATION.GET_CLASSIFICATIONS), data = _a.data, loading = _a.loading;
    if (loading)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'mt-2' },
        isSmallScreen && (React.createElement(ItemMenuOptionDialogMenuHeaderMainContentComponent, { label: 'Inicio', dir: '' })),
        data &&
            data.map(function (el) { return (React.createElement(ItemMenuOptionDialogMenuHeaderMainContentComponent, { key: el.id, label: el.name, dir: "classification/".concat(el.id) })); })));
};
export default MenuOptionDialogMenuHeaderMainContentComponent;
