import { Button, DialogActions, Divider } from '@mui/material';
import React, { useState } from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
var AddComponent = function () {
    var _a = useState(1), count = _a[0], setCount = _a[1];
    var increment = function () {
        setCount(count + 1);
    };
    var decrement = function () {
        if (count > 1)
            setCount(count - 1);
    };
    var count2 = count === 1;
    return (React.createElement("div", null,
        React.createElement(Divider, { className: 'bg-slate-200' }),
        React.createElement(DialogActions, { className: 'bg-slate-100' },
            React.createElement("div", { className: 'flex flex-row items-center border-solid border-2 border-slate-300 rounded-full mr-5 p-1' },
                count2 ? (React.createElement(Button, { onClick: decrement },
                    React.createElement(DeleteOutlinedIcon, { className: 'text-slate-600 hover:text-slate-700' }))) : (React.createElement(Button, { onClick: decrement },
                    React.createElement(HorizontalRuleOutlinedIcon, { className: 'text-slate-600 hover:text-slate-700' }))),
                React.createElement("div", null, count),
                React.createElement(Button, { onClick: increment },
                    React.createElement(AddOutlinedIcon, { className: 'text-slate-600 hover:text-slate-700' }))),
            React.createElement(Button, { className: 'flex flex-row bg-green-600 text-white hover:bg-green-700 rounded-full p-3' },
                React.createElement(ShoppingCartOutlinedIcon, null),
                "Agregar ",
                React.createElement("span", { className: 'font-bold' }, "($85.00)")))));
};
export default AddComponent;
