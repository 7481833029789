import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
import './App.css';
import App from './App';
import client from './apollo';
import Helmet from './config/Helmet';
var container = document.getElementById('app');
var root = createRoot(container);
root.render(React.createElement(ApolloProvider, { client: client },
    React.createElement(HelmetProvider, null,
        React.createElement(Helmet, null),
        React.createElement(App, null))));
