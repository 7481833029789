var objectArraySort = function (object, key, type) {
    return object.sort(function (a, b) {
        return type === 'int'
            ? parseInt(a[key]) > parseInt(b[key])
                ? 1
                : parseInt(b[key]) > parseInt(a[key])
                    ? -1
                    : 0
            : a[key] > b[key]
                ? 1
                : b[key] > a[key]
                    ? -1
                    : 0;
    });
};
export default objectArraySort;
