import { Skeleton } from '@mui/material';
import React from 'react';
var LoadingClassificationNavigationComponent = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(Skeleton, { className: 'mx-2 w-20' }),
        React.createElement(Skeleton, { className: 'mx-2 w-28' }),
        React.createElement(Skeleton, { className: 'mx-2 w-40' }),
        React.createElement(Skeleton, { className: 'mx-2 w-20' }),
        React.createElement(Skeleton, { className: 'mx-2 w-28' }),
        React.createElement(Skeleton, { className: 'mx-2 w-32' })));
};
export default LoadingClassificationNavigationComponent;
