import React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import setSearchQueryParam from '../../../../../utils/setSearchQueryParam';
var FontSizeFieldComponent = function () {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var fontSize = searchParams.get('fontSize');
    var handleLess = function () {
        var newFontSize = fontSize ? parseFloat(fontSize) - 2 : 30;
        if (newFontSize < 10)
            return;
        setSearchParams(setSearchQueryParam('fontSize', newFontSize.toString(), searchParams));
    };
    var handleMore = function () {
        var newFontSize = fontSize ? parseFloat(fontSize) + 2 : 30;
        if (newFontSize > 60)
            return;
        setSearchParams(setSearchQueryParam('fontSize', newFontSize.toString(), searchParams));
    };
    return (React.createElement("div", { className: 'mt-2' },
        React.createElement("div", null, "Tama\u00F1o de letra"),
        React.createElement("div", { className: 'flex flex-row items-center' },
            React.createElement(IconButton, { onClick: handleLess },
                React.createElement(RemoveIcon, null)),
            React.createElement("div", { className: 'text-2xl text-gray-600' }, fontSize || 30),
            React.createElement(IconButton, { onClick: handleMore },
                React.createElement(AddIcon, null)))));
};
export default FontSizeFieldComponent;
