var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import getProductAttributeByType from './getProductAttributeByType';
var setAddedAttributesArray = function (SelectedProductAttributes, ProductAttributes) {
    var ColorAttribute = getProductAttributeByType(1, ProductAttributes);
    var Color = SelectedProductAttributes.color
        ? [
            {
                id: ColorAttribute === null || ColorAttribute === void 0 ? void 0 : ColorAttribute.id,
                type: 1,
                attribute: SelectedProductAttributes.color,
                name: ColorAttribute ? "Color ".concat(ColorAttribute.attribute) : '',
            },
        ]
        : [];
    var TextAttribute = getProductAttributeByType(3, ProductAttributes);
    var Text = SelectedProductAttributes.text
        ? [
            {
                id: TextAttribute === null || TextAttribute === void 0 ? void 0 : TextAttribute.id,
                type: 3,
                attribute: SelectedProductAttributes.text,
                name: TextAttribute ? "Texto ".concat(SelectedProductAttributes.text) : '',
            },
        ]
        : [];
    var TypographyAttribute = getProductAttributeByType(4, ProductAttributes);
    var Typography = SelectedProductAttributes.typography
        ? [
            {
                id: TypographyAttribute === null || TypographyAttribute === void 0 ? void 0 : TypographyAttribute.id,
                type: 4,
                attribute: SelectedProductAttributes.typography,
                name: TypographyAttribute ? "Tipograf\u00EDa ".concat(SelectedProductAttributes.typography) : '',
            },
        ]
        : [];
    var OrientationAttribute = getProductAttributeByType(5, ProductAttributes);
    var Orientation = SelectedProductAttributes.orientation
        ? [
            {
                id: OrientationAttribute === null || OrientationAttribute === void 0 ? void 0 : OrientationAttribute.id,
                type: 5,
                attribute: SelectedProductAttributes.orientation,
                name: OrientationAttribute
                    ? "Orientaci\u00F3n ".concat(SelectedProductAttributes.orientation === 1 ? 'Vertical' : 'Horizontal')
                    : '',
            },
        ]
        : [];
    var SizeAttribute = getProductAttributeByType(6, ProductAttributes);
    var Size = SelectedProductAttributes.size
        ? [
            {
                id: SizeAttribute === null || SizeAttribute === void 0 ? void 0 : SizeAttribute.id,
                type: 6,
                attribute: SelectedProductAttributes.size,
                name: SizeAttribute ? "Tama\u00F1o de letra ".concat(SelectedProductAttributes.size) : '',
            },
        ]
        : [];
    var SecondaryColorAttribute = getProductAttributeByType(8, ProductAttributes);
    var SecondaryColor = SelectedProductAttributes.secondaryColor
        ? [
            {
                id: SecondaryColorAttribute === null || SecondaryColorAttribute === void 0 ? void 0 : SecondaryColorAttribute.id,
                type: 8,
                attribute: SelectedProductAttributes.secondaryColor,
                name: SecondaryColorAttribute
                    ? "Color secundario ".concat(SecondaryColorAttribute.attribute)
                    : '',
            },
        ]
        : [];
    var EmojiAttribute = getProductAttributeByType(9, ProductAttributes);
    var Emoji = SelectedProductAttributes.emoji
        ? [
            {
                id: EmojiAttribute === null || EmojiAttribute === void 0 ? void 0 : EmojiAttribute.id,
                type: 9,
                attribute: SelectedProductAttributes.emoji,
                name: EmojiAttribute ? "Emoji ".concat(SelectedProductAttributes.emoji) : '',
            },
        ]
        : [];
    var EmojiPositionAttribute = getProductAttributeByType(10, ProductAttributes);
    var EmojiPosition = SelectedProductAttributes.emojiPosition
        ? [
            {
                id: EmojiPositionAttribute === null || EmojiPositionAttribute === void 0 ? void 0 : EmojiPositionAttribute.id,
                type: 10,
                attribute: SelectedProductAttributes.emojiPosition,
                name: EmojiPositionAttribute
                    ? "Posici\u00F3n del emoji ".concat(SelectedProductAttributes.emojiPosition === 'start' ? 'Al inicio' : 'Al final')
                    : '',
            },
        ]
        : [];
    return __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], Color, true), Typography, true), Text, true), Orientation, true), Size, true), SecondaryColor, true), Emoji, true), EmojiPosition, true);
};
export default setAddedAttributesArray;
