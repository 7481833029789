import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from '@mui/material';
import CONFIG from '../../../../config/config.json';
var WhatsAppContactUsStaticInformationComponent = function () {
    return (React.createElement(Link, { underline: 'none', href: 'https://wa.me/526672941267', target: '_blank' },
        React.createElement("div", { className: 'text-[#525252] text-lg flex justify-start items-center mb-2 p-2 rounded border-gray-300 border-solid' },
            React.createElement("div", { className: 'flex justify-center items-center font-bold mr-4' },
                React.createElement(WhatsAppIcon, { fontSize: 'large' }),
                " WhatsApp"),
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("img", { src: "".concat(CONFIG['googleDrive'].fileUri, "1JnTGHnaxEjoTDrmC_G7utbQ2MrQ2Jn6E"), alt: 'encantacln-wa-qrcode', className: 'w-28 h-28' }),
                React.createElement("div", null, "667 294 1267")))));
};
export default WhatsAppContactUsStaticInformationComponent;
