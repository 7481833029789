import { Button } from '@mui/material';
import React from 'react';
import getInputErrorFormat from '../../../../../utils/getInputErrorFormat';
var ContinueButtonChoosePresentationType7ProductComponent = function (props) {
    var handleAction = function () {
        try {
            if (!props.productPresentationId)
                throw new Error('Selecciona una presentación del producto.');
            props.onClick(props.productPresentationId);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(Button, { className: 'px-8 py-4  bg-[#00bb2d] text-white text-base hover:bg-[#009424]', onClick: handleAction }, "Continuar"));
};
export default ContinueButtonChoosePresentationType7ProductComponent;
