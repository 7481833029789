var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { ECPaymentStep } from '../../../../../apollo/cache';
import ButtonMui from '../../../../../presentationals/Button';
import step1Validation from './helpers/step1.validation';
import step2Validation from './helpers/step2.validation';
import step3Validation from './helpers/step3.validation';
import getInputErrorFormat from '../../../../../utils/getInputErrorFormat';
var Button = styled(ButtonMui)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(1.5, 6, 1.5, 6),
        backgroundColor: '#00bb2d',
        boxShadow: 'none',
        color: '#fff',
        fontSize: 14,
        '&:hover': {
            backgroundColor: '#009424',
            boxShadow: 'none',
        },
    });
});
var ContinueButtonActionBodyPaymentComponent = function () {
    var params = useParams();
    var ECPaymentStepVar = useReactiveVar(ECPaymentStep);
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var handleNext = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 9, 10, 11]);
                    setIsLoading(true);
                    _a = ECPaymentStepVar;
                    switch (_a) {
                        case 0: return [3 /*break*/, 1];
                        case 1: return [3 /*break*/, 3];
                        case 2: return [3 /*break*/, 5];
                    }
                    return [3 /*break*/, 7];
                case 1: return [4 /*yield*/, step1Validation({ companyName: params.companyName || 'encantacln' })];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 8];
                case 3: return [4 /*yield*/, step2Validation({ companyName: params.companyName || 'encantacln' })];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 8];
                case 5: return [4 /*yield*/, step3Validation({ companyName: params.companyName || 'encantacln' })];
                case 6:
                    _b.sent();
                    return [3 /*break*/, 8];
                case 7: return [2 /*return*/, 0];
                case 8: return [3 /*break*/, 11];
                case 9:
                    e_1 = _b.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 11];
                case 10:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 11: return [2 /*return*/];
            }
        });
    }); };
    return (ECPaymentStepVar < 3 && (React.createElement(Button, { variant: 'contained', onClick: handleNext, isLoading: isLoading, label: 'Continuar' })));
};
export default ContinueButtonActionBodyPaymentComponent;
