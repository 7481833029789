import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
var StepperType7ProductComponent = function (props) {
    return (React.createElement("div", { className: 'w-full px-0 py-8 sm:px-20' },
        React.createElement(Stepper, { activeStep: props.currentStep, alternativeLabel: true }, props.steps.map(function (label) { return (React.createElement(Step, { key: label },
            React.createElement(StepLabel, null, "ELIGE TU ".concat(label)))); }))));
};
export default StepperType7ProductComponent;
