import React from 'react';
import { Button, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CopyrightComponent from '../Copyright';
var ContactEnComponentChime = function () {
    return (React.createElement("div", { className: 'flex flex-col  w-full h-screen text-white overflow-auto' },
        React.createElement("div", { className: 'mb-24' }),
        React.createElement("img", { className: 'h-32 w-full bg-cover bg-center ', src: 'https://storage.capitalike.com/files/28677', alt: 'IMAGEN' }),
        React.createElement("div", { className: 'px-44 ' },
            React.createElement("div", { className: 'text-chime-pink text-center text-2xl font-bold' }, "Contact us"),
            React.createElement("div", { className: 'flex flex-col px-10' },
                React.createElement("div", { className: 'flex flex-row mt-5' },
                    React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Name' }),
                    React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Last Name' })),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Email' }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Reason' }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'State' }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'City' }),
                React.createElement(TextField, { id: 'outlined-basic', variant: 'outlined', className: 'flex flex-1 p-2 mb-6', label: 'Message' })),
            React.createElement("div", { className: 'text-center' },
                React.createElement(Button, { variant: 'contained', endIcon: React.createElement(SendIcon, null), className: 'bg-chime-pink mb-10 mt-4 w-32' }, "Send"))),
        React.createElement(CopyrightComponent, null)));
};
export default ContactEnComponentChime;
