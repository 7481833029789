import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/system';
import { Drawer as DrawerMui, Typography } from '@mui/material';
import { isECCartOpen } from '../../../../../../../apollo/cache';
import CloseIconButton from './CloseIconButton';
import ProductComponent from './Product';
import FooterComponent from './Footer';
var Drawer = styled(DrawerMui)(function (_a) {
    var _b;
    var theme = _a.theme;
    return ({
        '& .paper': (_b = {
                width: '30%',
                boxShadow: 'none',
                display: 'flex'
            },
            _b[theme.breakpoints.down('sm')] = {
                width: '100%',
            },
            _b),
        '& .header': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottom: '1px solid #dadada',
            alignItems: 'center',
            padding: theme.spacing(2, 2, 1, 2),
        },
        '& .body': {
            flex: 1,
            padding: theme.spacing(1, 0, 1, 0),
        },
        '& .footer': {
            borderTop: '1px solid #dadada',
        },
    });
});
var CartDialogComponent = function () {
    var isECCartOpenVar = useReactiveVar(isECCartOpen);
    var handleClose = function () {
        isECCartOpen(false);
    };
    return (React.createElement(Drawer, { anchor: 'right', open: isECCartOpenVar, onClose: handleClose, classes: {
            paperAnchorRight: 'paper',
        } },
        React.createElement("div", { className: 'header' },
            React.createElement(Typography, { variant: 'h6' }, "Carrito de compras"),
            React.createElement(CloseIconButton, null)),
        React.createElement("div", { className: 'body' },
            React.createElement(ProductComponent, null)),
        React.createElement("div", { className: 'footer' },
            React.createElement(FooterComponent, null))));
};
export default CartDialogComponent;
