import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from '@mui/material';
var InstagramContactUsStaticInformationComponent = function () {
    return (React.createElement(Link, { underline: 'none', href: 'https://www.instagram.com/encantacln/', target: '_blank' },
        React.createElement("div", { className: 'text-[#525252] text-lg flex justify-start items-center mb-2 p-2 rounded border-gray-300 border-solid' },
            React.createElement("div", { className: 'flex justify-center items-center font-bold mr-4' },
                React.createElement(InstagramIcon, { fontSize: 'large' }),
                " Instagram"),
            React.createElement("div", null, "@encantacln"))));
};
export default InstagramContactUsStaticInformationComponent;
