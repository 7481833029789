import React from 'react';
import { Tooltip } from '@mui/material';
var ItemColorType1ProductComponent = function (props) {
    var handleClick = function () {
        props.onClick(props.id);
    };
    return (React.createElement(Tooltip, { title: props.label },
        React.createElement("div", { onClick: handleClick, role: 'button', tabIndex: -1, className: 'w-10 h-10 mr-2 mb-2 flex items-center justify-center border-solid rounded-full', style: {
                borderWidth: props.isSelected ? '2px' : '1px',
                borderColor: props.isSelected ? '#787878' : '#dadada',
            } },
            React.createElement("div", { className: 'w-8 h-8 border border-solid rounded-full', style: {
                    backgroundColor: props.hexa,
                    borderColor: props.hexa === '#ffffff' ? '#dadada' : props.hexa,
                } }))));
};
export default ItemColorType1ProductComponent;
