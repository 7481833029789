import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import CONFIG from '../../../config/config.json';
var HeaderComponent = function () {
    var theme = useTheme();
    var isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var mainOthers = "".concat(CONFIG['googleDrive'].fileUri).concat('1ddLzMkv8u7obI3KiopgQJJS8R8ul1_0j');
    var mainPhone = "".concat(CONFIG['googleDrive'].fileUri).concat('1jKNkh4inPEnBr5xg7HIDpOSO3lqmUEsS');
    return (React.createElement("div", { className: 'flex flex-1 flex-col bg-transparent' }, isSmallScreen ? (React.createElement("img", { src: mainPhone, className: 'w-full', alt: 'header', loading: 'lazy' })) : (React.createElement("img", { src: mainOthers, className: 'w-full', alt: 'header', loading: 'lazy' }))));
};
export default HeaderComponent;
