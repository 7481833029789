import React from 'react';
import { Link } from 'react-router-dom';
import CONFIG from '../../../../../config/config.json';
var ProductImageRelevantProductComponent = function (props) {
    return (React.createElement(Link, { className: 'no-underline p-0 overflow-hidden relative w-full', to: "classification/".concat(props.id) },
        React.createElement("div", { className: 'w-full overflow-hidden relative h-[230px] sm:h-[400px] items-center justify-center' },
            React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(props.Files[0].Files), alt: props.name, className: 'object-contain w-full h-full', loading: 'lazy' }),
            React.createElement("div", { className: 'absolute flex flex-1 items-center justify-center text-center w-full h-full bottom-0 left-0 text-white bg-black bg-opacity-50' },
                React.createElement("div", { className: 'font-bold text-xl sm:text-3xl' }, props.classificationName.toUpperCase())))));
};
export default ProductImageRelevantProductComponent;
