import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/system';
import { Link as LinkMui } from '@mui/material';
import { ECPaymentStep } from '../../../../../apollo/cache';
var Link = styled(LinkMui)(function (_a) {
    var theme = _a.theme;
    return ({
        marginRight: theme.spacing(2),
        color: '#808080',
        cursor: 'pointer',
    });
});
var ReturnButtonActionBodyPaymentComponent = function () {
    var ECPaymentStepVar = useReactiveVar(ECPaymentStep);
    var handleReturn = function (e) {
        e.preventDefault();
        ECPaymentStep(ECPaymentStepVar - 1);
    };
    return ECPaymentStepVar > 0 && React.createElement(Link, { onClick: handleReturn }, "Regresar");
};
export default ReturnButtonActionBodyPaymentComponent;
