import React from 'react';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { isECSessionOpen } from '../../../../../../../../../apollo/cache';
var RootComponent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        marginTop: theme.spacing(1),
        textAlign: 'center',
    });
});
var ForgotPasswordLinkDialogSessionActionNavigationSecondaryContentComponent = function () {
    var handleForgotPassword = function () {
        isECSessionOpen(false);
    };
    return (React.createElement(RootComponent, null,
        "\u00BFOlvidaste tu contrase\u00F1a?",
        ' ',
        React.createElement(Link, { to: 'forgotPassword', onClick: handleForgotPassword }, "Recuperar ahora.")));
};
export default ForgotPasswordLinkDialogSessionActionNavigationSecondaryContentComponent;
