import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/system';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import Form4 from './Form4';
import { ECPaymentStep } from '../../../../apollo/cache';
var RootComponent = styled('div')({
    display: 'flex',
    flex: 1,
});
var FormBodyPaymentComponent = function () {
    var ECPaymentStepVar = useReactiveVar(ECPaymentStep);
    return (React.createElement(RootComponent, null,
        ECPaymentStepVar === 0 && React.createElement(Form1, null),
        ECPaymentStepVar === 1 && React.createElement(Form2, null),
        ECPaymentStepVar === 2 && React.createElement(Form3, null),
        ECPaymentStepVar === 3 && React.createElement(Form4, null)));
};
export default FormBodyPaymentComponent;
