import { InMemoryCache, makeVar } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import getDefaultEcPaymentValues from '../containers/_Payment/helpers/defaultValues';
var defaultECPaymentValues = getDefaultEcPaymentValues();
export var extraProductPrice = makeVar([]);
export var inputError = makeVar({});
export var ECPaymentFormSelected = makeVar(null);
export var ECShipping = makeVar(0);
export var ECCustomerSessionData = makeVar(null);
export var ECShipmentRates = makeVar([]);
export var ECPaymentForm = makeVar(defaultECPaymentValues);
export var ECPaymentStep = makeVar(0);
export var customizedText = makeVar({
    text: '',
    typography: 'Arial',
    orientation: 2,
    size: 'l',
    emojiPosition: 1,
    isAvailable: true,
});
export var setProductECType2Selected = makeVar([]);
export var productECType2Step = makeVar([0, 0]);
export var type7ProductCart = makeVar([]);
export var type2ProductCart = makeVar([]);
export var checkCart = makeVar(true);
export var ProductAttributePrices = makeVar(null);
export var setCurrentProductIdDialog = makeVar([null, null]);
export var setCurrentMiniImage = makeVar(null);
export var setCurrentImage = makeVar(null);
export var setCurrentPrice = makeVar(0);
export var setCurrentPrensetation = makeVar(null);
export var setCurrentPrensetationType7 = makeVar(null);
export var setCurrentPrensetationType2 = makeVar(null);
export var setCurrentColor = makeVar(null);
export var setCurrentMultiColor = makeVar([]);
export var setCurrentMultiColorB = makeVar([]);
export var setCurrentSize = makeVar(null);
export var setCurrentType = makeVar(null);
export var isDuplicateSaleOpen = makeVar(false);
export var isECMenuOpen = makeVar(false);
export var isECSearchOpen = makeVar(false);
export var isECFavoriteOpen = makeVar(false);
export var isECSessionOpen = makeVar(false);
export var isECCartOpen = makeVar(false);
export var isECProductDetailDialogOpen = makeVar(false);
export var isECUpdateColorFormOpen = makeVar([false, null]);
export var isECUpdateSecColorFormOpen = makeVar([false, null]);
export var snackbar = makeVar({
    isOpen: false,
    time: null,
    label: '',
    severity: 'success',
});
export var cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                productsEC: offsetLimitPagination(['relevant', 'classificationId']),
            },
        },
    },
});
