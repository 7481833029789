var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import ItemImageType1ProductComponent from './Item';
import MainImageType1ProductComponent from './Main';
var ImageType1ProductComponent = function (props) {
    var _a = useState([null, false]), selectedImage = _a[0], setSelectedImage = _a[1];
    useEffect(function () {
        if (props.Files.length > 0)
            setSelectedImage([props.Files[props.Files.length - 1], true]);
    }, [props.Files]);
    var handleClick = function (fileId) {
        var isMain = props.Files[props.Files.length - 1] === fileId;
        setSelectedImage([fileId, isMain]);
    };
    return (React.createElement("div", { className: 'flex flex-col-reverse sm:flex-row' },
        React.createElement("div", { className: 'flex flex-row sm:flex-col' }, __spreadArray([], props.Files, true).reverse().map(function (id) { return (React.createElement(ItemImageType1ProductComponent, { key: id, productName: props.productName, fileId: id, isSelected: selectedImage[0] === id, onClick: handleClick })); })),
        React.createElement("div", { className: 'flex overflow-hidden sm:pl-2 w-full sm:w-[450px] items-start' },
            React.createElement(MainImageType1ProductComponent, { Proportions: props.Proportions, TextAttributes: props.TextAttributes, fileId: selectedImage[0], isMainImage: selectedImage[1] }))));
};
export default ImageType1ProductComponent;
