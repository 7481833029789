import React from 'react';
import { useSearchParams } from 'react-router-dom';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import setSearchQueryParam from '../../../../../utils/setSearchQueryParam';
import getFonts from '../../../../../utils/getFonts';
import ErrorAttributeMessageComponent from '../../helpers/ErrorAttributeMessage';
import { inputError } from '../../../../../apollo/cache';
var TypographyFieldComponent = function (props) {
    var fonts = getFonts();
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var typographyId = searchParams.get('typographyId');
    var handleChange = function (name, value) {
        inputError({});
        setSearchParams(setSearchQueryParam('typographyId', value, searchParams));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SelectFieldComponent, { size: 'small', name: 'typography', label: 'Tipograf\u00EDa', value: typographyId || '', onChange: handleChange, options: fonts.map(function (font) { return ({ value: font, label: font }); }) }),
        React.createElement(ErrorAttributeMessageComponent, { message: props.error })));
};
export default TypographyFieldComponent;
