import React from 'react';
import { styled } from '@mui/system';
import CopyrightUtilsComponent from '../../../utils/Copyright';
var RootComponent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3, 0, 3, 0),
    });
});
var FooterPaymentComponent = function () {
    return (React.createElement(RootComponent, null,
        React.createElement(CopyrightUtilsComponent, null)));
};
export default FooterPaymentComponent;
