import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationComponent from '../../containers/Landing/Navigation';
import LoadingLandingPageComponent from '../../containers/Landing/LoadingLadingPage';
import FooterComponent from '../../containers/Landing/Footer';
import MenuDialogComponent from '../../containers/Landing/Navigation/MenuDialog';
import CartDialogComponent from '../../containers/Landing/Navigation/Sticky/Action/CartButton/Dialog';
import SearchProductDialogComponent from '../../containers/Landing/Navigation/Sticky/Action/SearchButton/Dialog';
import SessionDialogComponent from '../../containers/Landing/Navigation/Sticky/Action/SessionButton/Dialog';
var LandingLayoutComponent = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'w-screen h-screen p-0 m-0 flex flex-1 flex-col' },
            React.createElement(LoadingLandingPageComponent, null),
            React.createElement(NavigationComponent, null),
            React.createElement(Outlet, null),
            React.createElement(FooterComponent, null)),
        React.createElement(MenuDialogComponent, null),
        React.createElement(CartDialogComponent, null),
        React.createElement(SearchProductDialogComponent, null),
        React.createElement(SessionDialogComponent, null)));
};
export default LandingLayoutComponent;
