import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ItemProductPresentationType1ProductComponent from '../ProductPresentation/Item';
import setSearchQueryParam from '../../../../utils/setSearchQueryParam';
import ErrorAttributeMessageComponent from '../helpers/ErrorAttributeMessage';
import { inputError } from '../../../../apollo/cache';
var SizeType1ProductComponent = function (props) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var sizeId = searchParams.get('sizeId');
    var handleClick = function (Size) {
        inputError({});
        var selectedSize = props.Sizes.find(function (el) { return el.id === Size.id; });
        if (!selectedSize)
            return;
        setSearchParams(setSearchQueryParam('sizeId', selectedSize.id.toString(), searchParams));
    };
    if (props.Sizes.length === 0)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'text-xl tracking-tighter mb-2' }, "Elige una talla"),
        props.Sizes.map(function (el) { return (React.createElement(ItemProductPresentationType1ProductComponent, { key: el.id, isSelected: el.id === sizeId, label: el.attribute, params: el, onClick: handleClick })); }),
        React.createElement(ErrorAttributeMessageComponent, { message: props.error })));
};
export default SizeType1ProductComponent;
