import React, { useRef, useEffect, useState } from 'react';
import LogoStickyNavigationComponent from './Logo';
import ClassificationNavigationComponent from './Classification';
import ActionNavigationComponent from './Action';
import MenuButtonComponent from './Action/MenuButton';
var StickyNavigationComponent = function () {
    var element = useRef(null);
    var _a = useState(false), isOnTop = _a[0], setIsOnTop = _a[1];
    var handleScroll = function () {
        if (element &&
            element.current &&
            Math.round(window.scrollY) >= Math.round(element.current.offsetTop))
            setIsOnTop(true);
        else
            setIsOnTop(false);
    };
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        return function () {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (React.createElement("div", { ref: element, className: 'flex items-center sticky top-0 z-[1]', style: {
            backgroundColor: '#faecf2',
        } },
        React.createElement("div", { className: 'flex flex-1 sm:flex-none' },
            React.createElement(MenuButtonComponent, null),
            isOnTop && React.createElement(LogoStickyNavigationComponent, null)),
        React.createElement(ClassificationNavigationComponent, null),
        React.createElement(ActionNavigationComponent, null)));
};
export default StickyNavigationComponent;
