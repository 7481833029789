import React from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Typography, Button as ButtonMui } from '@mui/material';
import { isECSessionOpen } from '../../../../../../../../apollo/cache';
var RootComponent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(3, 2, 3, 2),
    });
});
var Button = styled(ButtonMui)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
        padding: theme.spacing(2, 8, 2, 8),
        backgroundColor: '#fff',
        color: '#333',
        fontSize: 16,
        border: '1px solid #333',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#333',
        },
    });
});
var SignupDialogSessionActionNavigationSecondaryContentComponent = function () {
    var navigate = useNavigate();
    var handleSignUp = function () {
        isECSessionOpen(false);
        navigate('signup');
    };
    return (React.createElement(RootComponent, null,
        React.createElement(Typography, { variant: 'button', gutterBottom: true }, "Soy cliente nuevo"),
        React.createElement(Typography, { variant: 'body2' }, "Al crear tu cuenta, podr\u00E1s tramitar tus pedidos de una forma mucho m\u00E1s r\u00E1pida, adem\u00E1s, recibir\u00E1s ofertas exclusivas."),
        React.createElement(Button, { onClick: handleSignUp }, "Reg\u00EDstrate")));
};
export default SignupDialogSessionActionNavigationSecondaryContentComponent;
