import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ItemColorType1ProductComponent from '../Color/Item';
import setSearchQueryParam from '../../../../utils/setSearchQueryParam';
import { inputError } from '../../../../apollo/cache';
import ErrorAttributeMessageComponent from '../helpers/ErrorAttributeMessage';
var SecondaryColorType1ProductComponent = function (props) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var colorId = searchParams.get('secondaryColorId');
    var productPresentationId = searchParams.get('productPresentationId');
    var _b = useState([]), colors = _b[0], setColors = _b[1];
    useEffect(function () {
        if (props.Colors && props.Colors.length > 0) {
            var newColors = props.Colors.filter(function (el) {
                return props.ProductPresentations.length <= 1 ||
                    !el.productPresentationId ||
                    el.productPresentationId === productPresentationId;
            });
            setColors(newColors);
        }
    }, [props.Colors, props.ProductPresentations, productPresentationId]);
    var handleClick = function (id) {
        inputError({});
        var selectedColor = props.Colors.find(function (el) { return el.id === id; });
        if (!selectedColor)
            return;
        setSearchParams(setSearchQueryParam('secondaryColorId', selectedColor.id.toString(), searchParams));
    };
    if (props.Colors.length === 0)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'text-xl tracking-tighter mb-2' }, "Selecciona el color secundario"),
        React.createElement("div", { className: 'flex flex-wrap' }, colors.map(function (el) { return (React.createElement(ItemColorType1ProductComponent, { onClick: handleClick, isSelected: colorId === el.id.toString(), key: el.id, id: el.id, hexa: el.attribute, label: el.attribute })); })),
        React.createElement(ErrorAttributeMessageComponent, { message: props.error })));
};
export default SecondaryColorType1ProductComponent;
