import React from 'react';
import { styled } from '@mui/system';
import CONFIG from '../../../../../../../../../../config/config.json';
var Image = styled('img')({
    width: '100%',
});
var ImageOptionProductDialogCartNavigationSecondaryContentCompoennt = function (props) {
    return React.createElement(Image, { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(props.fileId), alt: props.name });
};
export default ImageOptionProductDialogCartNavigationSecondaryContentCompoennt;
