import React from 'react';
import Link from '@mui/material/Link';
import { isECSessionOpen } from '../../../../../../../apollo/cache';
var LoginSessionActionForm1BodyPaymentComponent = function () {
    var handleLogin = function () {
        isECSessionOpen(true);
    };
    return (React.createElement("div", null,
        "\u00BFYa tienes una cuenta?",
        ' ',
        React.createElement(Link, { className: 'cursor-pointer', onClick: handleLogin }, "Iniciar sesi\u00F3n")));
};
export default LoginSessionActionForm1BodyPaymentComponent;
