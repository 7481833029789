var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Button } from '@mui/material';
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { extraProductPrice, inputError, setCurrentProductIdDialog, type7ProductCart, } from '../../../../../../apollo/cache';
import validateProductInputs from '../../../../Type1/AddToCartButton/helpers/validateProductInputs';
import getInputErrorFormat from '../../../../../../utils/getInputErrorFormat';
import getProductPrice from '../../../../Type1/helpers/getProductPrice';
var ContinueIngredientDialogButtonType7ProductComponent = function (props) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var extraProductPriceVar = useReactiveVar(extraProductPrice);
    var productIngredientId = useReactiveVar(setCurrentProductIdDialog);
    var productPresentationId = searchParams.get('productPresentationId');
    var typographyId = searchParams.get('typographyId');
    var textOrientationId = searchParams.get('textOrientationId');
    var colorId = searchParams.get('colorId');
    var secondaryColorId = searchParams.get('secondaryColorId');
    var label = searchParams.get('label');
    var sizeId = searchParams.get('sizeId');
    var typeId = searchParams.get('typeId');
    var handleAction = function () {
        try {
            inputError({});
            validateProductInputs({
                productPresentationId: productPresentationId,
                typographyId: typographyId,
                textOrientationId: textOrientationId,
                colorId: colorId,
                secondaryColorId: secondaryColorId,
                sizeId: sizeId,
                typeId: typeId,
                label: label,
                SelectedProductIngredients: extraProductPriceVar,
            }, props.Product);
            var currentCart = type7ProductCart();
            var ProductPresentation = props.Product.ProductPresentations.find(function (el) { return el.id === productPresentationId; });
            if (!ProductPresentation)
                return;
            if (!productIngredientId[1])
                return;
            var price = getProductPrice(ProductPresentation.price, extraProductPriceVar);
            type7ProductCart(__spreadArray(__spreadArray([], currentCart, true), [
                {
                    productIngredientId: productIngredientId[1].productIngredientId,
                    productId: props.Product.id,
                    productPresentationId: productPresentationId,
                    productName: props.Product.name,
                    productPresentationName: ProductPresentation.name,
                    step: props.step,
                    price: price,
                    fileId: null,
                },
            ], false));
            setSearchParams({});
            setCurrentProductIdDialog([null, null]);
            extraProductPrice([]);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(Button, { className: 'px-8 py-4  bg-[#00bb2d] text-white text-base hover:bg-[#009424]', onClick: handleAction }, "Continuar"));
};
export default ContinueIngredientDialogButtonType7ProductComponent;
