import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import CONFIG from '../config.json';

const HelmetComponent = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [faviconUrl, setFaviconUrl] = useState('');
  useEffect(() => {
    if (window.location.hostname)
      switch (window.location.hostname) {
        case 'www.encantacln.com':
        case 'encantacln.com':
          setFaviconUrl(`${CONFIG.googleDrive.fileUri}1vNTVQq1jvG3GTwfQ1WE9stQxHn-7-KYk`);
          setTitle('Encanta Cln | Más que un regalo, una experiencia.');
          break;
        default:
          setTitle('Capitalike | Una herramienta inteligente, veloz y fácil de usar.');
          setDescription(
            'Nunca fue tan fácil adminintrar tu empresa! Bienvenido al mundo digital de Capitalike, tu primera sucursal va por nuestra cuenta.',
          );
          break;
      }
  }, [window.location.hostname]);
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />;
      {faviconUrl.length > 0 && <link rel='shortcut icon' href={faviconUrl} />}
    </Helmet>
  );
};

export default HelmetComponent;
