import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
var StorePickupSwitchForm1BodyPaymentComponent = function (props) {
    var _a = useState(false), isActive = _a[0], setIsActive = _a[1];
    var handleChange = function (event) {
        setIsActive(event.target.checked);
        props.handleSwitch(event.target.checked);
    };
    return (React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: isActive, onChange: handleChange, color: 'primary' }), label: 'Recoger en tienda' }));
};
export default StorePickupSwitchForm1BodyPaymentComponent;
