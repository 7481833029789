var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var parseError = function (e) {
    if (e.message.includes('Network error'))
        return [{ name: 'NETWORK_ERROR', message: 'El servidor no responde, intente más tarde.' }];
    var parseErrors = e.graphQLErrors &&
        e.graphQLErrors
            .map(function (_a) {
            var extensions = _a.extensions, message = _a.message;
            if (extensions.code === 'BAD_USER_INPUT') {
                var errorArray = JSON.parse(message);
                var errors = errorArray.fields.reduce(function (object, el) {
                    var _a;
                    return (__assign(__assign({}, object), (_a = {}, _a[el.name || el.code] = el.message, _a)));
                }, {});
                return { name: extensions.code, message: errors };
            }
            if ([
                'GENERAL_ERROR',
                'INVENTORY_ERROR',
                'PAYMENT_ERROR',
                'PRODUCT_INVOICE_ERROR',
                'INTERNAL_SERVER_ERROR',
            ].includes(extensions.code))
                return { name: extensions.code, message: message };
            return null;
        })
            .filter(function (el) { return el; });
    return parseErrors;
};
export default parseError;
