var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var getImages = function (props) {
    var _a;
    var reverse = (_a = props.settings) === null || _a === void 0 ? void 0 : _a.reverse;
    var Colors = props.ProductAttributes
        ? props.ProductAttributes.filter(function (el) { return el.type === 1 && el.ProductAttributeFiles.length > 0; })
        : [];
    var ProductPresentations = props.ProductPresentation.filter(function (el) { return el.ProductPresentationFiles.length > 0; });
    if (Colors.length > 0)
        return Colors.map(function (el) { return ({
            id: el.id,
            type: 'color',
            Files: reverse
                ? __spreadArray([], el.ProductAttributeFiles.map(function (el) { return el.fileId; }), true).reverse()
                : el.ProductAttributeFiles.map(function (el) { return el.fileId; }),
        }); });
    var MainFiles = props.ProductImages.map(function (el) { return el.fileId; });
    if (ProductPresentations.length > 0)
        return ProductPresentations.map(function (el) { return ({
            id: el.id,
            type: 'presentation',
            Files: reverse
                ? __spreadArray(__spreadArray([], MainFiles, true), el.ProductPresentationFiles.map(function (el) { return el.fileId; }), true).reverse()
                : __spreadArray(__spreadArray([], MainFiles, true), el.ProductPresentationFiles.map(function (el) { return el.fileId; }), true),
        }); });
    return [{ id: null, type: 'product', Files: reverse ? __spreadArray([], MainFiles, true).reverse() : MainFiles }];
};
export default getImages;
