import React from 'react';
import { useSearchParams } from 'react-router-dom';
import CONFIG from '../../../../../../config/config.json';
import setSearchQueryParam from '../../../../../../utils/setSearchQueryParam';
var ItemEmojiFieldComponent = function (props) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var emoji = searchParams.get('emoji');
    var handleClick = function () {
        setSearchParams(setSearchQueryParam('emoji', props.fileId.toString(), searchParams));
    };
    return (React.createElement("div", { onClick: handleClick, role: 'button', tabIndex: 0, className: 'border-solid rounded-full flex flex-1 items-start justify-center p-1', style: {
            borderColor: emoji === props.fileId ? '#808080' : '#dadada',
            borderWidth: emoji === props.fileId ? '2px' : '1px',
        } },
        React.createElement("img", { alt: props.label, src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(props.fileId), className: 'w-12 h-12' })));
};
export default ItemEmojiFieldComponent;
