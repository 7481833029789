import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Snackbar from './presentationals/Snackbar';
// import SocketHandlerComponent from './socket/handler';
import router from './routes';
import getMainDomain from './utils/getMainDomain';
// import LaChimeTemplateComponent from './containers/LaChimeTemplate';
var theme = createTheme({
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#0083e8',
        },
        secondary: {
            main: '#e91e63',
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});
var Main = function () {
    var _a = useState(null), isDomain = _a[0], setIsDomain = _a[1];
    // const [domain, setDomain] = useState<string | null>(null);
    useEffect(function () {
        var currentDomain = getMainDomain(window.location.hostname);
        // setDomain(currentDomain);
        setIsDomain(currentDomain !== 'capitalike');
    }, []);
    if (isDomain === null)
        return React.createElement("div", null);
    return (React.createElement(StyledEngineProvider, { injectFirst: true },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(CssBaseline, null),
            React.createElement(RouterProvider, { router: router }),
            React.createElement(Snackbar, null))));
};
export default Main;
