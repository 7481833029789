import FRAGMENT_PRODUCT from './fragmentProduct';
import GET_EMOJI_FILES from './getEmojiFiles';
import GET_PRODUCT_BY_ID from './getProductById';
import GET_PRODUCTS from './getProducts';
import GET_RANDOM_PRODUCTS from './getRandomProducts';
var GQL_PRODUCT = {
    FRAGMENT_PRODUCT: FRAGMENT_PRODUCT,
    GET_EMOJI_FILES: GET_EMOJI_FILES,
    GET_PRODUCT_BY_ID: GET_PRODUCT_BY_ID,
    GET_PRODUCTS: GET_PRODUCTS,
    GET_RANDOM_PRODUCTS: GET_RANDOM_PRODUCTS,
};
export default GQL_PRODUCT;
