import React from 'react';
import { useSearchParams } from 'react-router-dom';
import setSearchQueryParam from '../../../../../../../utils/setSearchQueryParam';
var ItemEmojiPosition = function (props) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var emoji = searchParams.get('emojiPosition');
    var handleClick = function () {
        setSearchParams(setSearchQueryParam('emojiPosition', props.position, searchParams));
    };
    return (React.createElement("div", { onClick: handleClick, role: 'button', tabIndex: 0, className: 'border-1 border-solid rounded-full flex flex-1 items-start justify-center p-1 mt-2', style: {
            borderColor: emoji === props.position ? '#808080' : '#dadada',
        } }, props.position === 'start' ? 'Al inicio del texto' : 'Al final del texto'));
};
export default ItemEmojiPosition;
