import React from 'react';
import { styled } from '@mui/system';
import ReturnButton from './ReturnButton';
import CancelButton from './CancelButton';
import ContinueButton from './ContinueButton';
var RootComponent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2, 0, 0, 0),
    });
});
var ActionBodyPaymentComponent = function () {
    return (React.createElement(RootComponent, null,
        React.createElement("div", null,
            React.createElement(ReturnButton, null)),
        React.createElement("div", null,
            React.createElement(CancelButton, null),
            React.createElement(ContinueButton, null))));
};
export default ActionBodyPaymentComponent;
