import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import getImages from '../../../utils/getImages';
import getColorsFromProductAttributes from '../../../utils/getColorsFromProductAttributes';
import getSecondaryColorsFromProductAttributes from '../../../utils/getSecondaryColorsFromProductAttributes';
import getTextAttributesFromProductAttributes from '../../../utils/getTextAttributesFromProductAttributes';
import getSizesFromProductAttributes from '../../../utils/getSizesFromProductAttributes';
import getTypesFromProductAttributes from '../../../utils/getTypesFromProductAttributes';
import useGetProportionsToFind from '../../../hooks/useGetProportionsToFind';
import ImageType1ProductComponent from './Image';
import getProductImagesByColor from './helpers/getProductImagesByColor';
import PriceType1ProductComponent from './Price';
import ProductPresentationType1ProductComponent from './ProductPresentation';
import ColorType1ProductComponent from './Color';
import SecondaryColorType1ProductComponent from './SecondaryColor';
import SizeType1ProductComponent from './Size';
import TypeType1ProductComponent from './Type';
import TextType1ProductComponent from './Text';
import AddToCartButtonComponent from './AddToCartButton';
import AddToFavoriteButtonComponent from './AddToFavoriteButton';
import DescriptionType1ProductComponent from './Description';
import ProductVariationsType1ProductComponent from './ProductVariations';
import { extraProductPrice, inputError } from '../../../apollo/cache';
import getProductPrice from './helpers/getProductPrice';
var Type1ProductComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var extraProductPriceVar = useReactiveVar(extraProductPrice);
    var params = useParams();
    var searchParams = useSearchParams()[0];
    var colorId = searchParams.get('colorId');
    var proportionsToFind = useGetProportionsToFind();
    var _a = useState([]), Files = _a[0], setFiles = _a[1];
    var _b = useState([]), Colors = _b[0], setColors = _b[1];
    var _c = useState([]), SecondaryColors = _c[0], setSecondaryColors = _c[1];
    var _d = useState([]), TextAttributes = _d[0], setTextAttributes = _d[1];
    var _e = useState([]), Sizes = _e[0], setSizes = _e[1];
    var _f = useState([]), Types = _f[0], setTypes = _f[1];
    var _g = useState([null, null]), Proportions = _g[0], setProportions = _g[1];
    var _h = useState(null), price = _h[0], setPrice = _h[1];
    var _j = useState(null), productPresentationPrice = _j[0], setProductPresentationPrice = _j[1];
    useEffect(function () {
        if (props.Product) {
            var price_1 = props.Product.ProductPresentations[0].price;
            setProductPresentationPrice(price_1);
            setPrice(price_1);
        }
    }, [props.Product]);
    useEffect(function () {
        if (productPresentationPrice)
            setPrice(getProductPrice(productPresentationPrice, extraProductPriceVar));
    }, [extraProductPriceVar]);
    useEffect(function () {
        var Images = getImages({
            ProductImages: props.Product.ProductFiles,
            ProductPresentation: props.Product.ProductPresentations,
            ProductAttributes: props.Product.ProductAttributes,
        });
        var ImagesByColor = getProductImagesByColor(colorId, Images);
        setFiles(ImagesByColor);
        var Colors = getColorsFromProductAttributes(props.Product.ProductAttributes);
        setColors(Colors);
        var SecondaryColors = getSecondaryColorsFromProductAttributes(props.Product.ProductAttributes);
        setSecondaryColors(SecondaryColors);
        var TextAttributes = getTextAttributesFromProductAttributes(props.Product.ProductAttributes);
        setTextAttributes(TextAttributes);
        var Sizes = getSizesFromProductAttributes(props.Product.ProductAttributes);
        setSizes(Sizes);
        var Types = getTypesFromProductAttributes(props.Product.ProductAttributes);
        setTypes(Types);
    }, [props.Product, colorId]);
    useEffect(function () {
        if (props.Product && proportionsToFind[0]) {
            var tmpTextWidth = props.Product.ProductAttributes.find(function (el) { return proportionsToFind[0] === el.type; });
            var tmpTextHeight = props.Product.ProductAttributes.find(function (el) { return proportionsToFind[1] === el.type; });
            setProportions([
                tmpTextWidth ? parseFloat(tmpTextWidth.attribute) : null,
                tmpTextHeight ? parseFloat(tmpTextHeight.attribute) : null,
            ]);
        }
    }, [props.Product, proportionsToFind]);
    var handleProductPresentationChange = function (ProductPresentation) {
        setProductPresentationPrice(ProductPresentation.price);
        setPrice(getProductPrice(ProductPresentation.price, extraProductPriceVar));
    };
    return (React.createElement("div", { className: 'px-2 sm:px-10 sm:py-6 flex flex-col sm:flex-row' },
        React.createElement("div", { className: 'flex' },
            React.createElement(ImageType1ProductComponent, { productName: props.Product.name, Files: Files, Proportions: Proportions, TextAttributes: TextAttributes })),
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden sm:pl-6' },
            React.createElement("div", { className: 'text-4xl leading-tight' }, props.Product.name),
            React.createElement(PriceType1ProductComponent, { productType: props.Product.type, price: price }),
            React.createElement(Divider, { className: 'my-2' }),
            React.createElement(ProductPresentationType1ProductComponent, { error: errors.productPresentation, ProductPresentations: props.Product.ProductPresentations, onChange: handleProductPresentationChange }),
            React.createElement(ProductVariationsType1ProductComponent, { ProductPresentations: props.Product.ProductPresentations, error: errors.variation }),
            [21080].includes(parseInt(params.productId)) ? (React.createElement("div", null, "Multicolor")) : (React.createElement(ColorType1ProductComponent, { Colors: Colors, ProductPresentations: props.Product.ProductPresentations, error: errors.color })),
            React.createElement(SecondaryColorType1ProductComponent, { Colors: SecondaryColors, error: errors.secondaryColor, ProductPresentations: props.Product.ProductPresentations }),
            React.createElement(SizeType1ProductComponent, { Sizes: Sizes, error: errors.size }),
            React.createElement(TypeType1ProductComponent, { Types: Types }),
            React.createElement(TextType1ProductComponent, { TextAttributes: TextAttributes }),
            React.createElement("div", { className: 'mt-4 flex items-center' },
                props.action ? (props.action) : !props.justForRead ? (React.createElement(AddToCartButtonComponent, { Product: props.Product })) : (React.createElement("div", null)),
                React.createElement(AddToFavoriteButtonComponent, { productId: params.productId })),
            React.createElement(DescriptionType1ProductComponent, { description: props.Product.description }))));
};
export default Type1ProductComponent;
