import { gql } from '@apollo/client';

const ECLOGIN = gql`
  mutation EcLogin($ecName: String, $hostname: String, $username: String, $password: String) {
    ecLogin(ecName: $ecName, hostname: $hostname, username: $username, password: $password) {
      user
      name
      email
      phone
      refreshToken
      accessToken
    }
  }
`;

export default {
  ECLOGIN,
};
