var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import getFavoriteList from './getFavoriteList';
import getIfInFavoriteList from './getIfInFavoriteList';
var addToFavoriteList = function (productId) {
    var isFavorite = getIfInFavoriteList(productId);
    var favoriteList = getFavoriteList();
    var newFavoriteList = isFavorite
        ? favoriteList.filter(function (el) { return el !== productId; })
        : __spreadArray(__spreadArray([], favoriteList, true), [productId], false);
    localStorage.setItem('favoriteList', JSON.stringify(newFavoriteList));
};
export default addToFavoriteList;
