import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
var TermsLinkFormChatComponent = function () {
    return (React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'mt-2' },
        "Al iniciar el chat, est\u00E1s aceptando los ",
        React.createElement(Link, { to: '../terms' }, "t\u00E9rminos y condiciones"),
        "."));
};
export default TermsLinkFormChatComponent;
