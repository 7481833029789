var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/system';
import { Drawer as DrawerMui, Typography } from '@mui/material';
import { isECSearchOpen } from '../../../../../../../apollo/cache';
import CloseIconButton from './CloseIconButton';
import LinearProgressComponent from './LinearProgress';
import useApolloLazyQueryByCompany from '../../../../../../../hooks/useLazyQueryByCompany';
import GQL_PRODUCT from '../../../../../../../apollo/gql/product';
import OptionSearchProductDialogComponent from './Option';
import SearchProductFieldComponent from './SearchField';
import getInputErrorFormat from '../../../../../../../utils/getInputErrorFormat';
var Drawer = styled(DrawerMui)(function (_a) {
    var _b;
    var theme = _a.theme;
    return ({
        '& .root': (_b = {
                padding: theme.spacing(4, 7, 7, 7),
                display: 'flex',
                flex: 1,
                flexDirection: 'column'
            },
            _b[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(2, 2, 1, 2),
            },
            _b),
        '& .header': {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
    });
});
var SearchProductDialogComponent = function () {
    var isECSearchOpenVar = useReactiveVar(isECSearchOpen);
    var _a = useApolloLazyQueryByCompany(GQL_PRODUCT.GET_PRODUCTS), getProducts = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    var handleSearch = function (like) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getProducts({
                            variables: {
                                like: like,
                            },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function () {
        isECSearchOpen(false);
    };
    return (React.createElement(Drawer, { anchor: 'top', open: isECSearchOpenVar, onClose: handleClose },
        loading && React.createElement(LinearProgressComponent, null),
        React.createElement("div", { className: 'root' },
            React.createElement("div", { className: 'header' },
                React.createElement(Typography, { variant: 'h6' }, "Buscador del sitio web"),
                React.createElement(CloseIconButton, null)),
            React.createElement(SearchProductFieldComponent, { onSearch: handleSearch }),
            React.createElement("div", { className: 'flex-1 grid grid-cols-2 sm:grid-cols-6 gap-4 mt-4' }, data &&
                data.map(function (el) { return (React.createElement(OptionSearchProductDialogComponent, { key: el.id, id: el.id, name: el.name, Files: [el.fileId], price: el.price })); })))));
};
export default SearchProductDialogComponent;
