import React, { useEffect, useState } from 'react';
import useQueryByCompany from '../../../../hooks/useQueryByCompany';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import GQL_CLASSIFICATION from '../../../../apollo/gql/classification';
import getRelevantProducts from './helpers/getRelevantProducts';
import ProductImageRelevantProductComponent from './ProductImage';
var ProductRelevantProductComponent = function () {
    var _a = useQueryByCompany(GQL_PRODUCT.GET_PRODUCTS, {
        relevant: true,
    }), data = _a.data, loading = _a.loading;
    var _b = useQueryByCompany(GQL_CLASSIFICATION.GET_CLASSIFICATIONS), Classifications = _b.data, loadingClassifications = _b.loading;
    var _c = useState([]), products = _c[0], setProducts = _c[1];
    useEffect(function () {
        if (data && Classifications) {
            var newProducts = getRelevantProducts(data, Classifications);
            setProducts(newProducts);
        }
    }, [data, Classifications]);
    if (loading || loadingClassifications)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'mt-4 sm:mt-10 grid grid-cols-2 sm:grid-cols-4 gap-4' }, products.map(function (el) { return (React.createElement(ProductImageRelevantProductComponent, { key: el.id, id: el.Classification.id, name: el.name, classificationName: el.Classification.name, price: el.price, altPrice: el.altPrice || 0, Files: el.Images, noTitle: true })); })));
};
export default ProductRelevantProductComponent;
