import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import Header from './Header';
var DialogComponent = function (props) {
    var theme = useTheme();
    var isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var handleClose = function () {
        props.onClose && props.onClose();
    };
    return (React.createElement(Dialog, { onClose: handleClose, disableEscapeKeyDown: props.disableEscapeKeyDown, fullScreen: props.fullScreen || isSmallScreen, fullWidth: true, maxWidth: props.maxWidth, open: props.open },
        (props.title || !props.hideHeaderCloseButton) && (React.createElement(Header, { title: props.title, onClose: handleClose })),
        props.children));
};
export default DialogComponent;
