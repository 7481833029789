import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
var Root = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return ({
        '&.root': {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        '& .content': (_b = {
                padding: theme.spacing(2, 18, 2, 18),
                borderBottom: '1px solid #ccc',
                background: '#fff'
            },
            _b[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(2),
            },
            _b),
        '& .subTitle': {
            color: '#4f4f4f',
        },
        '& .title': {
            marginTop: 0,
        },
        '& .marginBottom': {
            marginBottom: theme.spacing(1),
        },
        '& .addButton': {
            marginBottom: theme.spacing(0.5),
            padding: theme.spacing(2, 8, 2, 8),
            backgroundColor: '#333',
            color: '#fff',
            fontSize: 16,
            '&:hover': {
                backgroundColor: '#474747',
            },
        },
        '& .iconButton': {
            fontSize: 18,
            color: '#525252',
            margin: theme.spacing(2),
        },
        '& .title1': {
            fontSize: 40,
            fontFamily: 'Marthin',
            color: '#d44e88',
        },
        '& .title2': {
            fontSize: 70,
            fontFamily: 'Marthin',
            color: '#81a74a',
        },
    });
});
var AboutUsStaticInformationComponent = function () {
    return (React.createElement(Root, { className: 'root' },
        React.createElement("div", { className: 'content' },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { paragraph: true, align: 'center', className: 'title title1' }, "La vida es un regalo... nuestra mision,"),
                    React.createElement(Typography, { paragraph: true, align: 'center', className: 'title title2' }, "Personalizarla")),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: 'h5' },
                        React.createElement("strong", null, "\u00BFQuien\u00E9s somos?"))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Nosotros creemos que la vida es un regalo del universo, y como seres humanos tenemos la misi\u00F3n de crearla y planificarla para ser felices."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Encanta te da la idea del regalo perfecto, tu compromiso y misi\u00F3n es personalizarlo, plasmando emociones, momentos, gustos, personalidades de los seres que forman parte de tu vida. Son regalos indestructibles que se vuelven peque\u00F1os recuerdos f\u00EDsicos de su historia.")),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: 'h5' },
                        React.createElement("strong", null, "Historia"))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Encanta Cln comienza con el sue\u00F1o de una madre de crear una tienda de regalos fuera de lo ordinario, en donde el cliente pudiera expresar sus ideas y sentimientos para as\u00ED crear entre ambos recuerdos indestructibles."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "El sue\u00F1o llev\u00F3 a\u00F1os volverse realidad hasta que un d\u00EDa junto con su hija decide ver las posibilidades de cumplirlo."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Encanta abre sus puertas en febrero del 2019 en Culiac\u00E1n Sinaloa, comenzando una traves\u00EDa en donde no solo se han conocido personas/clientes espectaculares, sino que tambi\u00E9n ha abierto las puertas para fortalecer el v\u00EDnculo entre una madre e hija."),
                    React.createElement(Typography, { variant: 'body1', paragraph: true, align: 'justify', className: 'title' }, "Encanta espera seguir siendo parte de los momentos especiales de cada una de las personas por un largo tiempo."))))));
};
export default AboutUsStaticInformationComponent;
