import { setCurrentProductIdDialog } from '../../../../../../../apollo/cache';
var getMarginBottomByProductId = function (productId, _a) {
    var isSmallScreen = _a.isSmallScreen;
    var customizedProductId = setCurrentProductIdDialog();
    switch ((customizedProductId[0] || productId || '').toString()) {
        case '20785':
            return 210;
        case '20780':
            return 30;
        case '20864':
            return 300;
        case '20784':
            return isSmallScreen ? 30 : 0;
        case '21067':
            return 80;
        default:
            return 0;
    }
};
export default getMarginBottomByProductId;
