import React from 'react';
import ReturnButtonProductVariationType7ProductComponent from './ReturnButton';
import ContinueButtonProductVariationType7ProductComponent from './ContinueButton';
import IngredientsProductVariationType7ProductComponent from './Ingredients';
import Type7CartComponent from './Cart';
var ProductVariationsType7ProductComponent = function (props) {
    return (React.createElement("div", { className: 'px-2 sm:px-20 py-6 flex-1 grid grid-cols-3 gap-6' },
        React.createElement("div", { className: 'flex col-span-2 flex-col' },
            React.createElement("div", { className: 'flex flex-1' },
                React.createElement(IngredientsProductVariationType7ProductComponent, { ProductVariation: props.ProductVariation, currentStep: props.currentStep })),
            React.createElement("div", { className: 'flex justify-between mt-4' },
                React.createElement(ReturnButtonProductVariationType7ProductComponent, { onClick: props.onReturn }),
                React.createElement(ContinueButtonProductVariationType7ProductComponent, { steps: props.steps, currentStep: props.currentStep, onClick: props.onContinue }))),
        React.createElement("div", { className: 'flex bg-[#f5f5f5]' },
            React.createElement(Type7CartComponent, { steps: props.steps }))));
};
export default ProductVariationsType7ProductComponent;
