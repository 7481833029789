import React from 'react';
// import { Outlet } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import { Link as RouterLink, Outlet } from 'react-router-dom';
import MenuComponentChimeLayout from '../Menu';
var HearderEsComponentChime = function () {
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-row fixed bg-white w-full z-[999] items-center' },
            React.createElement("div", { className: 'flex-1' },
                React.createElement("img", { className: 'w-20 ml-16 mt-2', src: 'https://storage.capitalike.com/files/28311', alt: 'LOGO' })),
            React.createElement(MenuComponentChimeLayout, null),
            React.createElement("div", { className: 'mr-4' },
                React.createElement(IconButton, { onClick: handleClick },
                    React.createElement(LanguageIcon, { className: 'text-chime-pink hover:text-chime-brown' })),
                React.createElement(Menu, { anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                        'aria-labelledby': 'basic-button',
                    } },
                    React.createElement(MenuItem, { onClick: handleClose, className: 'items-center justify-between  ', component: RouterLink, to: '/lachime/es' },
                        React.createElement("div", { className: 'font-bold text-sm' }, "ES - MEX"),
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/29119', className: 'w-6 ml-2', alt: '' })),
                    React.createElement(MenuItem, { onClick: handleClose, className: 'items-center justify-between', component: RouterLink, to: '/lachime/en' },
                        React.createElement("div", { className: 'font-bold text-sm' }, "EN - EUA"),
                        React.createElement("img", { src: 'https://storage.capitalike.com/files/29120', className: 'w-6', alt: '' }))))),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(Outlet, null))));
};
export default HearderEsComponentChime;
