var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import CONFIG from '../../../../../config/config.json';
import objectArraySort from '../../../../../utils/objectArraySort';
import currencyFormat from '../../../../../utils/currencyFormat';
var ItemChoosePresentationType7ProductComponent = function (props) {
    var handleClick = function () {
        props.onClick(props.productPresentationId);
    };
    return (React.createElement("div", { role: 'button', tabIndex: 0, onClick: handleClick, className: 'flex flex-col w-1/2 md:w-1/4 flex-wrap mb-2 rounded border-2 border-solid mr-2', style: {
            color: props.isSelected ? '#000' : '#515151',
            borderColor: props.isSelected ? '#000' : '#dadada',
        } },
        props.Files.length > 0 && (React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(objectArraySort(__spreadArray([], props.Files, true), 'fileId', 'int')[0].fileId), alt: props.productPresentationName, className: 'w-full' })),
        React.createElement("div", { className: 'px-5 pb-1' },
            React.createElement("div", { className: 'text-lg text-gray-700 leading-3' },
                props.productName,
                " ",
                props.productPresentationName),
            React.createElement("div", { className: 'mt-0 text-2xl' },
                "$",
                currencyFormat(props.price)))));
};
export default ItemChoosePresentationType7ProductComponent;
