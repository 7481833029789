import React from 'react';
import { isECMenuOpen } from '../../../../../apollo/cache';
import CloseIconButtonUtilsComponent from '../../../../../utils/CloseIconButton';
var CloseIconButtonDialogMenuHeaderMainContentComponent = function () {
    var handleClose = function () {
        isECMenuOpen(false);
    };
    return React.createElement(CloseIconButtonUtilsComponent, { onClose: handleClose });
};
export default CloseIconButtonDialogMenuHeaderMainContentComponent;
