import React, { useState } from 'react';
import { Button, Dialog, DialogContent, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CONFIG from '../../../../../config/config.json';
import AddComponent from '../../../../LaChimeTemplateEs/Menu/Actions/Add';
import { capitalize } from '../../../../../helpers/general';
var ProductItemProductListComponent = function (props) {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement("div", { className: 'flex flex-col overflow-auto' },
        React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
            React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl', role: 'button', tabIndex: -1, onClick: handleClickOpen },
                React.createElement("div", { className: 'flex justify-center h-60 p-3' },
                    React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(props.fileId || '28311'), alt: "product-".concat(props.fileId || '28311'), className: 'w-32 object-contain' })),
                React.createElement("div", { className: 'border-solid border-chime-brown ml-9 mr-9' }),
                React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center' }, props.name)),
            React.createElement(Dialog, { maxWidth: 'xl', open: open, onClose: handleClose, "aria-labelledby": 'alert-dialog-title', "aria-describedby": 'alert-dialog-description', className: 'm-10 rounded-3xl' },
                React.createElement(DialogContent, { className: ' bg-slate-100 flex flex-1 overflow-hidden' },
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: 'flex flex-[1/3] h-6/6 justify-center rounded mt-8 mr-10' },
                            React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(props.fileId || '28311'), alt: "product-".concat(props.fileId || '28311'), className: 'w-80 h-6/6 mt-7 object-contain' })),
                        React.createElement("div", { className: 'flex flex-1 flex-col overflow-auto' },
                            React.createElement("div", { className: 'flex flex-row justify-between' },
                                React.createElement("div", null),
                                React.createElement("div", null,
                                    React.createElement(Button, { onClick: handleClose },
                                        React.createElement(CloseIcon, { className: 'text-red-600 w-16', fontSize: 'large' })))),
                            React.createElement("div", { className: ' text-center rounded-2xl text-2xl text-chime-pink font-bold' }, props.name),
                            React.createElement("div", { className: 'mt-5 text-justify' }, capitalize(props.description)),
                            React.createElement("div", { className: 'flex flex-col overflow-auto mt-3' },
                                React.createElement("div", null,
                                    React.createElement("div", { className: 'font-bold mt-5 text-lg' }, "Seleccione el tama\u00F1o"),
                                    React.createElement(Divider, { className: 'mb-2' }),
                                    React.createElement("div", { className: 'flex flex-row p-2' },
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "12 OZ"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white' }, "16 OZ"))),
                                React.createElement("div", null,
                                    React.createElement("div", { className: 'font-bold mt-5 text-lg' }, "Seleccione el tipo de leche"),
                                    React.createElement(Divider, { className: 'mb-2' }),
                                    React.createElement("div", { className: 'flex flex-row p-2' },
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "ENTERA"),
                                        React.createElement(Button, { className: 'w-36 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "DESLACTOSADA"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white ' }, "SOYA"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "COCO"),
                                        React.createElement(Button, { className: 'w-32 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "ALMENDRAS"))),
                                React.createElement("div", null,
                                    React.createElement("div", { className: 'font-bold mt-5 text-lg' }, "Seleccione extra"),
                                    React.createElement(Divider, { className: 'mb-2' }),
                                    React.createElement("div", null, "Jelly $15.00"),
                                    React.createElement("div", { className: 'flex flex-row p-2' },
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "COCO"),
                                        React.createElement(Button, { className: 'w-36 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "MANGO"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white ' }, "FRESA")),
                                    React.createElement(Divider, { className: 'mb-2' }),
                                    React.createElement("div", null, "Jelly $15.00"),
                                    React.createElement("div", { className: 'flex flex-row p-2' },
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "COCO"),
                                        React.createElement(Button, { className: 'w-36 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "MANGO"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white ' }, "FRESA"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "COCO"),
                                        React.createElement(Button, { className: 'w-36 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "MANGO"))))))),
                React.createElement(AddComponent, null))))
    // {/*
    //     // <div className='flex flex-col text-white items-center py-2 m-2 rounded-3xl'>
    //     //   <Card className='flex flex-1 justify-center h-56 max-h-56 w-56 min-w-56 rounded overflow-hidden raised m-2'>
    //     //     <img
    //     //       src={`${CONFIG['api-file-service'].url}/files/${props.fileId || '28311'}`}
    //     //       alt={`product-${props.fileId || '28311'}`}
    //     //       className='object-contain w-3/5 transition-opacity duration-500 rounded-3xl'
    //     //     />
    //     //   </Card>
    //     //   <div className='flex flex-col text-center mt-2'>
    //     //     <span className='flex border-solid m-auto border-chime-brown bg-chime-brown w-52 mb-2 ' />
    //     //     <div className='text-black tracking-tighter leading-none font-medium'>{props.name}</div>
    //     //     {props.description && (
    //     //       <div className='text-sm text-chime-brown leading-none mt-1 h-16 max-h-16 w-56 min-w-56'>
    //     //         {capitalize(props.description)}
    //     //       </div>
    //     //     )}
    //     //     <div className='text-black text-lg font-semibold'>${currencyFormat(props.price)}</div>
    //     //   </div>
    //     // </div> */}
    );
};
export default ProductItemProductListComponent;
