var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/system';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ECPaymentFormSelected, ECPaymentStep } from '../../../../apollo/cache';
var RootComponent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
        padding: theme.spacing(0, 0, 4, 0),
    });
});
var StepperBodyPaymentComponent = function () {
    var ECPaymentStepVar = useReactiveVar(ECPaymentStep);
    var ECPaymentFormSelectedVar = useReactiveVar(ECPaymentFormSelected);
    var _a = useState([]), fourStep = _a[0], setFourStep = _a[1];
    useEffect(function () {
        if (ECPaymentFormSelectedVar && parseInt(ECPaymentFormSelectedVar) === 1)
            setFourStep(['Completar Pago']);
        else
            setFourStep([]);
    }, [ECPaymentFormSelectedVar]);
    return (React.createElement(RootComponent, null,
        React.createElement(Stepper, { activeStep: ECPaymentStepVar }, __spreadArray(['Información', 'Envío', 'Pago'], fourStep, true).map(function (label) { return (React.createElement(Step, { key: label },
            React.createElement(StepLabel, null, label))); }))));
};
export default StepperBodyPaymentComponent;
