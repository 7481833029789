import React from 'react';
import SessionButtonNavigationComponent from './SessionButton';
import SearchButtonNavigationComponent from './SearchButton';
import CartButtonNavigationComponent from './CartButton';
var ActionNavigationComponent = function () {
    return (React.createElement("div", { className: 'flex flex-end px-3' },
        React.createElement(SearchButtonNavigationComponent, null),
        React.createElement(SessionButtonNavigationComponent, null),
        React.createElement(CartButtonNavigationComponent, null)));
};
export default ActionNavigationComponent;
