import React from 'react';
import { useSearchParams } from 'react-router-dom';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import setSearchQueryParam from '../../../../../utils/setSearchQueryParam';
import ErrorAttributeMessageComponent from '../../helpers/ErrorAttributeMessage';
import { inputError } from '../../../../../apollo/cache';
var TextOrientationFieldComponent = function (props) {
    var options = [
        { value: 1, label: 'Vertical' },
        { value: 2, label: 'Horizontal' },
    ];
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var textOrientationId = searchParams.get('textOrientationId');
    var handleChange = function (name, value) {
        inputError({});
        setSearchParams(setSearchQueryParam('textOrientationId', value, searchParams));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SelectFieldComponent, { size: 'small', name: 'orientation', label: 'Orientaci\u00F3n', value: textOrientationId || '', onChange: handleChange, options: options }),
        React.createElement(ErrorAttributeMessageComponent, { message: props.error })));
};
export default TextOrientationFieldComponent;
