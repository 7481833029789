var getMessageCompany102 = function (productVariationId) {
    switch (productVariationId.toString()) {
        case '1026':
            return 'Puedes elegir solo una opción.';
        case '1036':
            return 'Puedes elegir una opción, o dos opciones en caso de no seleccionar en el paso anterior.';
        case '1044':
            return 'Puedes elegir hasta 3 opciones.';
        case '1028':
            return 'Puedes elegir solo una opción.';
        case '1041':
            return 'Puedes elegir hasta 4 opciones.';
        default:
            return '';
    }
};
export default getMessageCompany102;
