import React from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Image from './Image';
import DeleteButton from './DeleteButton';
import currencyFormat from '../../../../../../../../../utils/currencyFormat';
var RootComponent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        '&.root': {
            display: 'flex',
            textAlign: 'left',
            alignItems: 'center',
            marginTop: theme.spacing(1),
            borderBottom: '1px solid #dadada',
            padding: theme.spacing(0, 2, 0, 2),
        },
        '& .containerA': {
            width: 70,
        },
        '& .containerB': {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        '& .attribute': {
            marginLeft: theme.spacing(1),
        },
    });
});
var OptionProductDialogCartNavigationSecondaryContentCompoennt = function (props) {
    return (React.createElement(RootComponent, { className: 'root' },
        React.createElement("div", { className: 'containerA' }, props.CartProduct.fileId && (React.createElement(Image, { name: props.CartProduct.productName, fileId: props.CartProduct.fileId }))),
        React.createElement("div", { className: 'containerB' },
            React.createElement(Typography, { variant: 'body2' }, "".concat(props.CartProduct.productName, " ").concat(props.CartProduct.productPresentationName).toUpperCase()),
            props.CartProduct.attributesAdded.map(function (el, i) {
                return el.name && (React.createElement(Typography, { variant: 'caption', className: 'attribute', key: i },
                    "- ",
                    el.name));
            }),
            !props.inCart && (React.createElement(Typography, { variant: 'button', display: 'block' },
                React.createElement("strong", null,
                    "MXN ",
                    currencyFormat(props.CartProduct.price))))),
        React.createElement("div", null,
            !props.inCart && React.createElement(DeleteButton, { index: props.index }),
            props.inCart && (React.createElement(Typography, { variant: 'subtitle1', display: 'block' },
                React.createElement("strong", null,
                    "MXN ",
                    currencyFormat(props.CartProduct.price)))))));
};
export default OptionProductDialogCartNavigationSecondaryContentCompoennt;
