import setInputErrorFormat from '../../../../../utils/setInputErrorFormat';
var validateProductVariations = function (SelectedProductIngredients, ProductVariations) {
    var mandatoryVariation = ProductVariations.filter(function (el) { return el.isRequired; }).map(function (el) { return el.id; });
    mandatoryVariation.map(function (id) {
        if (!SelectedProductIngredients.map(function (el) { return el.productVariationId; }).includes(id))
            throw new Error(setInputErrorFormat('variation', 'Selecciona una opción'));
        return true;
    });
    return true;
};
export default validateProductVariations;
