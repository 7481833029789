var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
var useQueryByCompany = function (gql, variables) {
    var params = useParams();
    console.log(params);
    var combinedVariables = __assign({ companyName: params.companyName }, variables);
    var _a = useState(null), data = _a[0], setData = _a[1];
    var _b = useQuery(gql, {
        variables: combinedVariables,
    }), serverData = _b.data, fetchMore = _b.fetchMore, loading = _b.loading;
    useEffect(function () {
        if (serverData) {
            var queryName = Object.keys(serverData)[0];
            var formattedServerData = serverData[queryName];
            var dataToSet = 'rows' in formattedServerData ? formattedServerData.rows : formattedServerData;
            setData(dataToSet);
        }
    }, [serverData]);
    return { data: data, fetchMore: fetchMore, loading: loading };
};
export default useQueryByCompany;
