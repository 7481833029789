import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
var ChatButtonComponent = function () {
    var navigate = useNavigate();
    var handleClick = function () {
        navigate('chat');
    };
    return (React.createElement(Button, { variant: 'outlined', color: 'secondary', onClick: handleClick, className: 'px-6 py-4 border-2 hover:border-2', fullWidth: false }, "Iniciar Chat"));
};
export default ChatButtonComponent;
