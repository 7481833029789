import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setCurrentProductIdDialog } from '../../../../../apollo/cache';
import DialogComponent from '../../../../../presentationals/Dialog';
import useApolloLazyQueryByCompany from '../../../../../hooks/useLazyQueryByCompany';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import Type1ProductComponent from '../../../Type1';
import ContinueIngredientDialogButtonType7ProductComponent from './ContinueButton';
var IngredientType7ProductDialogComponent = function (props) {
    var URLSearchParams = useSearchParams();
    var productId = useReactiveVar(setCurrentProductIdDialog);
    var _a = useApolloLazyQueryByCompany(GQL_PRODUCT.GET_PRODUCT_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (productId[0])
            getData({
                variables: {
                    id: productId[0],
                },
            });
    }, [productId[0]]);
    var handleClose = function () {
        setCurrentProductIdDialog([null, null]);
        URLSearchParams[1]({});
    };
    return (React.createElement(DialogComponent, { title: 'Configura tu producto', fullScreen: true, open: !!productId[0], onClose: handleClose }, loading || !data ? (React.createElement(LoadingProgress, null)) : (React.createElement("div", { className: 'flex flex-1 flex-col overflow-y-auto' },
        React.createElement(Type1ProductComponent, { Product: data, action: React.createElement(ContinueIngredientDialogButtonType7ProductComponent, { step: props.step, Product: data }) })))));
};
export default IngredientType7ProductDialogComponent;
