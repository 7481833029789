import React from 'react';
import ItemImageProductImageUtilsComponent from '../../../../../../presentationals/ProductImage/Container/Image';
import AddIngredientButtonType7ProductComponent from './AddButton';
import currencyFormat from '../../../../../../utils/currencyFormat';
var ItemIngredientsProductVariationType7ProductComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-col items-center' },
        React.createElement("div", { className: 'w-full h-64 mb-2' },
            React.createElement(ItemImageProductImageUtilsComponent, { name: props.name, fileId: props.fileId })),
        React.createElement(AddIngredientButtonType7ProductComponent, { currentStep: props.currentStep, productIngredientId: props.productIngredientId, productId: props.productId, limit: props.limit }),
        React.createElement("div", { className: 'text-base text-[#666] mt-2 flex text-center' }, props.name),
        React.createElement("div", { className: 'font-medium' },
            "MXN ",
            currencyFormat(props.price))));
};
export default ItemIngredientsProductVariationType7ProductComponent;
