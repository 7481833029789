import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import DialogContent from '@mui/material/DialogContent';
import { Button, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CopyrightEsComponent from '../../Copyright';
import AddComponent from '../Actions/Add';
var TopMenuEs = function () {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement("div", { className: 'flex flex-col overflow-auto' },
        React.createElement("div", { className: 'text-center text-5xl font-bold m-10' },
            "\u00A1Lo m\u00E1s ",
            React.createElement("span", { className: 'text-chime-pink' }, "Top!")),
        React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
            React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl', role: 'button', tabIndex: -1, onClick: handleClickOpen },
                React.createElement("div", { className: 'flex justify-center h-60 p-3' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/10268', className: 'w-32 object-contain', alt: '' })),
                React.createElement("div", { className: 'border-solid border-chime-brown ml-9 mr-9' }),
                React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center' }, "BOBA ZARZAMORA")),
            React.createElement(Dialog, { maxWidth: 'xl', open: open, onClose: handleClose, "aria-labelledby": 'alert-dialog-title', "aria-describedby": 'alert-dialog-description', className: 'm-10 rounded-3xl' },
                React.createElement(DialogContent, { className: ' bg-slate-100 flex flex-1 overflow-hidden' },
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: 'flex flex-[1/3] h-6/6 justify-center rounded mt-8 mr-10' },
                            React.createElement("img", { src: 'https://storage.capitalike.com/files/10268', className: 'w-80 h-6/6 mt-7 object-contain', alt: '' })),
                        React.createElement("div", { className: 'flex flex-1 flex-col overflow-auto' },
                            React.createElement("div", { className: 'flex flex-row justify-between' },
                                React.createElement("div", null),
                                React.createElement("div", null,
                                    React.createElement(Button, { onClick: handleClose },
                                        React.createElement(CloseIcon, { className: 'text-red-600 w-16', fontSize: 'large' })))),
                            React.createElement("div", { className: ' text-center rounded-2xl text-2xl text-chime-pink font-bold' }, "BOBA ZARZAMORA"),
                            React.createElement("div", { className: 'mt-5 text-justify' }, "Bebida de tapioca que combina el dulce sabor de las zarzamoras frescas con perlas suaves de tapioca. Refrescante y deliciosa, ofrece una experiencia \u00FAnica que satisfar\u00E1 tu gusto por lo frutal y lo indulgente."),
                            React.createElement("div", { className: 'flex flex-col overflow-auto mt-3' },
                                React.createElement("div", null,
                                    React.createElement("div", { className: 'font-bold mt-5 text-lg' }, "Seleccione el tama\u00F1o"),
                                    React.createElement(Divider, { className: 'mb-2' }),
                                    React.createElement("div", { className: 'flex flex-row p-2' },
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "12 OZ"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white' }, "16 OZ"))),
                                React.createElement("div", null,
                                    React.createElement("div", { className: 'font-bold mt-5 text-lg' }, "Seleccione el tipo de leche"),
                                    React.createElement(Divider, { className: 'mb-2' }),
                                    React.createElement("div", { className: 'flex flex-row p-2' },
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "ENTERA"),
                                        React.createElement(Button, { className: 'w-36 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "DESLACTOSADA"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white ' }, "SOYA"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "COCO"),
                                        React.createElement(Button, { className: 'w-32 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "ALMENDRAS"))),
                                React.createElement("div", null,
                                    React.createElement("div", { className: 'font-bold mt-5 text-lg' }, "Seleccione extra"),
                                    React.createElement(Divider, { className: 'mb-2' }),
                                    React.createElement("div", null, "Jelly $15.00"),
                                    React.createElement("div", { className: 'flex flex-row p-2' },
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "COCO"),
                                        React.createElement(Button, { className: 'w-36 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "MANGO"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white ' }, "FRESA")),
                                    React.createElement(Divider, { className: 'mb-2' }),
                                    React.createElement("div", null, "Jelly $15.00"),
                                    React.createElement("div", { className: 'flex flex-row p-2' },
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "COCO"),
                                        React.createElement(Button, { className: 'w-36 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "MANGO"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white ' }, "FRESA"),
                                        React.createElement(Button, { className: 'w-24 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "COCO"),
                                        React.createElement(Button, { className: 'w-36 mr-5 border-solid border-2 font-bold border-chime-brown rounded-full text-black hover:bg-chime-brown hover:text-white focus:bg-chime-brown focus:text-white' }, "MANGO"))))))),
                React.createElement(AddComponent, null)),
            React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2  rounded-xl' },
                React.createElement("div", { className: 'flex justify-center h-60 p-3' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/10268', className: 'w-32 object-contain', alt: '' })),
                React.createElement("div", { className: 'border-solid border-chime-brown ml-9 mr-9' }),
                React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center' }, "BOBA ZARZAMORA")),
            React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2  rounded-xl' },
                React.createElement("div", { className: 'flex justify-center h-60 p-3' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/10459', className: 'w-32 object-contain', alt: '' })),
                React.createElement("div", { className: 'border-solid border-chime-brown ml-9 mr-9' }),
                React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center' }, "CHIMENEA CARAMELO")),
            React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2  rounded-xl' },
                React.createElement("div", { className: 'flex justify-center h-60 p-3' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/10348', className: 'w-32 object-contain', alt: '' })),
                React.createElement("div", { className: 'border-solid border-chime-brown ml-9 mr-9' }),
                React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center' }, "EN LAS ROCAS MOKA CARBON"))),
        React.createElement("div", { className: 'm-10' }),
        React.createElement("div", { className: 'flex flex-row h-80 p-5 flex-1 items-center justify-center' },
            React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2 rounded-xl' },
                React.createElement("div", { className: 'flex justify-center h-60 p-3' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/10283', className: 'w-32 object-contain', alt: '' })),
                React.createElement("div", { className: 'border-solid border-chime-brown ml-9 mr-9' }),
                React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center' }, "EN LAS ROCAS CARAMELO")),
            React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2  rounded-xl' },
                React.createElement("div", { className: 'flex justify-center h-60 p-3' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/10258', className: 'w-32 object-contain', alt: '' })),
                React.createElement("div", { className: 'border-solid border-chime-brown ml-9 mr-9' }),
                React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center' }, "CHIMENEA OREO")),
            React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2  rounded-xl' },
                React.createElement("div", { className: 'flex justify-center h-60 p-3' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/10320', className: 'w-32 object-contain', alt: '' })),
                React.createElement("div", { className: 'border-solid border-chime-brown ml-9 mr-9' }),
                React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center' }, "SODA BLUE MAGIC")),
            React.createElement("div", { className: 'flex flex-col flex-1 h-72 m-2  rounded-xl' },
                React.createElement("div", { className: 'flex justify-center h-60 p-3' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/10331', className: 'w-32 object-contain', alt: '' })),
                React.createElement("div", { className: 'border-solid border-chime-brown ml-9 mr-9' }),
                React.createElement("div", { className: 'flex flex-row m-2 font-semibold justify-center' }, "CHIMMY FRESAS"))),
        React.createElement("div", { className: 'm-10' }),
        React.createElement(CopyrightEsComponent, null)));
};
export default TopMenuEs;
