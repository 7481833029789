import React, { useEffect, useState } from 'react';
import CONFIG from '../../../config/config.json';
var LoadingLandingPageComponent = function () {
    var logo = "".concat(CONFIG['googleDrive'].fileUri, "1J7AAKk4iG4-n2pyBWAwbtBRAY0a9QD8H");
    var background = "".concat(CONFIG['googleDrive'].fileUri, "1XXvjZQsf5jpQEsHTC01ttO5EP709U0CC");
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    useEffect(function () {
        setTimeout(function () {
            setIsLoading(false);
        }, 3000);
    }, []);
    if (!isLoading)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'fixed z-10 top-0 left-0 w-screen h-screen flex flex-1 items-center justify-center bg-cover bg-fixed sm:bg-center', style: {
            backgroundColor: '#faecf2',
            backgroundImage: "url(".concat(background, ")"),
        } },
        React.createElement("img", { src: logo, alt: 'loading-ecommerce', className: 'w-32 animate-zoom' })));
};
export default LoadingLandingPageComponent;
