var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import StepperType7ProductComponent from './Stepper';
import ChoosePresentationType7ProductComponent from './ChoosePresentation';
import ProductVariationsType7ProductComponent from './ProductVariations';
import IngredientType7ProductDialogComponent from './ProductVariations/IngredientDialog';
import { extraProductPrice, type7ProductCart } from '../../../apollo/cache';
import getProductPrice from '../Type1/helpers/getProductPrice';
import getInputErrorFormat from '../../../utils/getInputErrorFormat';
import addType7ProductToCart from './helpers/addToCart';
var Type7ProductComponent = function (props) {
    var _a = useState([]), steps = _a[0], setSteps = _a[1];
    var _b = useState(0), currentStep = _b[0], setCurrentStep = _b[1];
    var _c = useState(null), ProductPresentation = _c[0], setProductPresentation = _c[1];
    var clear = function () {
        setSteps(['PRESENTACIÓN']);
        setCurrentStep(0);
        type7ProductCart([]);
        setProductPresentation(null);
    };
    useEffect(function () {
        clear();
    }, [props.Product]);
    var handleNextStep = function (productPresentationId) {
        var ProductPresentation = props.Product.ProductPresentations.find(function (el) { return el.id === productPresentationId; });
        if (!ProductPresentation)
            return;
        setProductPresentation(ProductPresentation);
        var extraProductPriceVar = extraProductPrice();
        var price = getProductPrice(ProductPresentation.price, extraProductPriceVar);
        type7ProductCart([
            {
                productIngredientId: null,
                productId: props.Product.id,
                productPresentationId: ProductPresentation.id,
                productName: props.Product.name,
                productPresentationName: ProductPresentation.name,
                step: 0,
                fileId: ProductPresentation.ProductPresentationFiles.length > 0
                    ? ProductPresentation.ProductPresentationFiles[0].fileId
                    : null,
                price: price,
            },
        ]);
        setSteps(__spreadArray(['PRESENTACIÓN'], ProductPresentation.ProductVariations.map(function (el) { return el.name; }), true));
        setCurrentStep(function (currentStep) { return currentStep + 1; });
    };
    var handleReturn = function () {
        var newStep = currentStep - 1;
        if (newStep === 0)
            clear();
        setCurrentStep(newStep);
    };
    var handleContinue = function () { return __awaiter(void 0, void 0, void 0, function () {
        var cart, currentProductVariation, productVariationInCart, newStep, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!ProductPresentation)
                        return [2 /*return*/];
                    cart = type7ProductCart();
                    currentProductVariation = ProductPresentation.ProductVariations[currentStep - 1];
                    productVariationInCart = cart.filter(function (el) { return el.step === currentStep; });
                    if (currentProductVariation.isRequired && productVariationInCart.length === 0)
                        throw new Error('Debes seleccionar al menos un producto de esta sección.');
                    newStep = currentStep + 1;
                    setCurrentStep(newStep);
                    if (!(newStep === steps.length)) return [3 /*break*/, 2];
                    return [4 /*yield*/, addType7ProductToCart()];
                case 1:
                    _a.sent();
                    clear();
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' }, currentStep === 0 ? (React.createElement(ChoosePresentationType7ProductComponent, { onContinue: handleNextStep, Product: props.Product })) : ProductPresentation ? (React.createElement(React.Fragment, null,
        React.createElement(StepperType7ProductComponent, { currentStep: currentStep, steps: steps }),
        React.createElement(ProductVariationsType7ProductComponent, { currentStep: currentStep, ProductVariation: ProductPresentation.ProductVariations[currentStep - 1], onReturn: handleReturn, onContinue: handleContinue, steps: steps }),
        React.createElement(IngredientType7ProductDialogComponent, { step: currentStep }))) : (React.createElement("div", null))));
};
export default Type7ProductComponent;
