import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useQueryByCompany from '../../hooks/useQueryByCompany';
import GQL_PRODUCT from '../../apollo/gql/product';
import LoadingProductComponent from './Loading';
import Type1ProductComponent from './Type1';
import Type2ProductComponent from './Type2';
import Type7ProductComponent from './Type7';
import { extraProductPrice } from '../../apollo/cache';
var ProductComponent = function () {
    var params = useParams();
    var _a = useQueryByCompany(GQL_PRODUCT.GET_PRODUCT_BY_ID, {
        id: params.productId,
    }), data = _a.data, loading = _a.loading;
    useEffect(function () {
        return function () {
            extraProductPrice([]);
        };
    }, []);
    if (loading || !data)
        return (React.createElement("div", { className: 'flex flex-1' },
            React.createElement(LoadingProductComponent, null)));
    if (data.type === 1)
        return React.createElement(Type1ProductComponent, { Product: data });
    if (data.type === 2)
        return React.createElement(Type2ProductComponent, { Product: data });
    if (data.type === 7)
        return React.createElement(Type7ProductComponent, { Product: data });
    return React.createElement("div", null);
};
export default ProductComponent;
