import React from 'react';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingCartOutlined';
import IconButton from '@mui/material/IconButton';
import { isECCartOpen } from '../../../../../../apollo/cache';
var CartButtonNavigationComponent = function () {
    var handleClick = function () {
        isECCartOpen(true);
    };
    return (React.createElement(IconButton, { onClick: handleClick },
        React.createElement(ShoppingBasketIcon, null)));
};
export default CartButtonNavigationComponent;
