import React from 'react';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton as IconButtonMui } from '@mui/material';
import { type7ProductCart } from '../../../../../../apollo/cache';
import getInputErrorFormat from '../../../../../../utils/getInputErrorFormat';
var IconButton = styled(IconButtonMui)(function (_a) {
    var theme = _a.theme;
    return ({
        margin: 0,
        marginLeft: theme.spacing(1),
        padding: 0,
        fontSize: 16,
        '&:hover': {
            background: 'transparent',
        },
    });
});
var DeleteType7ProductButtonComponent = function (props) {
    var handleClose = function () {
        try {
            var currentCart = type7ProductCart();
            if (!props.productIngredientId)
                return;
            var newProductECType7Selected = currentCart.filter(function (el) { return el.productIngredientId !== props.productIngredientId; });
            console.log(currentCart, props.productIngredientId);
            type7ProductCart(newProductECType7Selected);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(IconButton, { disabled: props.disabled, onClick: handleClose, size: 'small' },
        React.createElement(DeleteIcon, null)));
};
export default DeleteType7ProductButtonComponent;
