import React from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CopyrightEsComponent from '../Copyright';
var FranchiseEsComponent = function () {
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("img", { className: 'w-full mb-20', src: 'https://storage.capitalike.com/files/28542', 
            // src='https://storage.capitalike.com/files/28540'
            alt: 'IMAGEN 2' }),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' }),
            React.createElement("h4", { className: 'flex whitespace-nowrap text-3xl text-chime-pink' }, "EXPERIENCIA VISUAL \u00DANICA"),
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' })),
        React.createElement("div", { className: 'flex flex-col flex-1' },
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement("img", { className: 'p-2 w-36 flex flex-1 h-80 object-cover object-bottom', src: 'https://storage.capitalike.com/files/28800', alt: 'IMAGEN 2' }),
                React.createElement("div", { className: 'flex flex-1 text-justify p-4 text-xl font-bold' },
                    "En La Chime, creemos que la experiencia visual es tan importante como el sabor. Nuestras bebidas est\u00E1n exquisitamente decoradas con un adorable personaje, creando un toque \u00FAnico y encantador que mejora cada sorbo. ",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Nuestras instalaciones son un reflejo de nuestra marca: llenas de vida y color. Predominan los tonos rosas y los detalles en ne\u00F3n, creando el escenario perfecto para tus fotos. Ya sea que nos visites en M\u00E9xico o en los Estados Unidos, cada tienda de La Chime est\u00E1 dise\u00F1ada para ser un lugar donde puedas disfrutar y capturar momentos inolvidables."))),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' }),
            React.createElement("h4", { className: 'flex whitespace-nowrap text-3xl text-chime-pink' }, "NUESTRA MISI\u00D3N"),
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' })),
        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-screen' }),
        React.createElement("div", { className: 'flex flex-1 flex-row text-black font-bold items-center bg-chime-brown' },
            React.createElement("div", { className: 'flex place-content-end mr-5 p-6' },
                React.createElement("img", { className: 'w-100 h-80 p-8', 
                    //   src='https://scontent.fcul1-1.fna.fbcdn.net/v/t39.30808-6/445364138_18012145343377728_5342928707079830525_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=DxAige2_fEoQ7kNvgExlmsp&_nc_ht=scontent.fcul1-1.fna&oh=00_AYBXMjagYihclLUr1C2ZHbIZ-knIGI6PIv1sZmSB9IjvFA&oe=665FCD06'
                    //   src='https://storage.capitalike.com/files/28319'
                    // src='https://storage.capitalike.com/files/28539'
                    src: 'https://storage.capitalike.com/files/28541', 
                    // src='https://storage.capitalike.com/files/28543'
                    alt: 'IMAGEN 2' })),
            React.createElement("p", { className: 'flex px-20 justify-center text-3xl text-white' }, "Ofrecer a todos nuestros clientes un servicio que cumpla con los m\u00E1s altos est\u00E1ndares de calidad, garantizando as\u00ED productos y sabores inigualables."),
            React.createElement("div", { className: 'flex place-content-end mr-5 p-6' },
                React.createElement("img", { className: 'w-100 h-80 p-8', 
                    //   src='https://scontent.fcul1-1.fna.fbcdn.net/v/t39.30808-6/445364138_18012145343377728_5342928707079830525_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=DxAige2_fEoQ7kNvgExlmsp&_nc_ht=scontent.fcul1-1.fna&oh=00_AYBXMjagYihclLUr1C2ZHbIZ-knIGI6PIv1sZmSB9IjvFA&oe=665FCD06'
                    //   src='https://storage.capitalike.com/files/28319'
                    // src='https://storage.capitalike.com/files/28539'
                    src: 'https://storage.capitalike.com/files/28541', 
                    // src='https://storage.capitalike.com/files/28543'
                    alt: 'IMAGEN 2' }))),
        React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-screen' }),
        React.createElement("div", { className: 'mb-20' }),
        React.createElement("div", { className: 'flex place-content-center' },
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' }),
            React.createElement("h4", { className: 'flex whitespace-nowrap text-3xl text-chime-pink' }, "\u00A1COMIENZA CON NOSOTROS!"),
            React.createElement("span", { className: 'flex border-solid m-auto border-chime-pink bg-chime-pink w-80 ' })),
        React.createElement("div", { className: 'mt-5' },
            React.createElement("img", { src: 'https://storage.capitalike.com/files/28581', alt: '', className: 'object-contain w-screen' })),
        React.createElement("div", { className: 'flex flex-1 flex-col mb-5 text-center m-4 p-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'text-center text-2xl font-bold mt-10 mb-10' },
                    "PRE-SOLICITUD DE FRANQUICIA ",
                    React.createElement("span", { className: 'text-chime-pink' }, "LA CHIME\u00AE")),
                React.createElement("div", { className: 'mt-3 text-justify mb-5' }, "Toda la informaci\u00F3n presentada dentro de esta Pre-solicitud es estrictamente confidencial y ser\u00E1 utilizada \u00FAnicamente como evaluaci\u00F3n para el otorgamiento de una franquicia de LA CHIME\u00AE al inversionista interesado en formar parte de nuestra familia de franquiciatarios. Una vez evaluada la informaci\u00F3n, es posible que reciba una llamada de nuestra parte para solicitar informaci\u00F3n adicional a la propuesta. Para cualquier duda adicional favor de contactarnos a hola@franquiciasdeexito.com.mx")),
            React.createElement("div", { className: 'text-left text-xl font-bold text-chime-pink mt-16' },
                "DATOS PERSONALES ",
                React.createElement("br", null),
                React.createElement("span", { className: 'text-black font-normal text-base' }, "Favor de proporcionar la informaci\u00F3n de acuerdo a su credencial INE y/o Pasaporte"))),
        React.createElement("div", { className: 'flex flex-1 flex-col m-4 p-4' },
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Nombre (s) y Apellidos (s) *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Fecha de Nacimiento *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Direcci\u00F3n *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Ciudad *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Estado *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Correo Electr\u00F3nico *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "Tel\u00E9fono M\u00F3vil *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "\u00BFC\u00F3mo conoci\u00F3 o se ingres\u00F3 por La Chime? *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "\u00BFA qu\u00E9 se dedica acutalmente? *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("p", { className: 'font-bold' }, "\u00BFCu\u00E1l es su monto de inversi\u00F3n disponible actualmente? *"),
                React.createElement(TextField, null)),
            React.createElement("div", { className: 'flex flex-col ' },
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFUsted est\u00E1 interesado en este proyecto individualmente o con otros socios? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFPara la ejecuci\u00F3n del proyecto utilizaras alg\u00FAn tipo de financiamiento? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFYa tiene un local visto y/o disponible? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFCu\u00E1nto tiempo tiene de conocer la plaza de su inter\u00E9s? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFTiene experiencia en la administraci\u00F3n de alguna licencia o franquicia? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-col ' },
                    React.createElement("p", { className: 'font-bold' }, "\u00BFPara cuando le gustar\u00EDa empezar este proyecto? *"),
                    React.createElement(TextField, null)),
                React.createElement("div", { className: 'flex flex-row items-center' },
                    React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { defaultChecked: true, className: 'text-chime-pink' }), label: '\u00BFAcepta el Aviso de Privacidad?' }),
                    React.createElement("a", { href: 'https://franquiciasdeexito.com.mx/aviso-de-privacidad/', className: 'text-chime-brown', target: '_blank', rel: 'noreferrer' }, "https://franquiciasdeexito.com.mx/aviso-de-privacidad/"))),
            React.createElement("div", { className: 'text-center' },
                React.createElement(Button, { variant: 'contained', endIcon: React.createElement(SendIcon, null), className: 'bg-chime-pink mb-10 mt-4 w-32' }, "Enviar"))),
        React.createElement(CopyrightEsComponent, null)));
};
export default FranchiseEsComponent;
