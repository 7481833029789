import React from 'react';
import { useReactiveVar } from '@apollo/client';
import TextOrientationFieldComponent from './TextOrientationField';
import TypographyFieldComponent from './TypographyField';
import LabelFieldTextType1ProductComponent from './LabelField';
import EmojiFieldComponent from './EmojiField';
import FontSizeFieldComponent from './FontSizeField';
import { inputError } from '../../../../apollo/cache';
var TextType1ProductComponent = function (props) {
    var errors = useReactiveVar(inputError);
    if (props.TextAttributes.length === 0)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'text-xl tracking-tighter mt-2' }, "Texto Personalizado"),
        props.TextAttributes.find(function (el) { return el.type === 5; }) && (React.createElement("div", { className: 'w-full sm:w-1/3' },
            React.createElement(TextOrientationFieldComponent, { error: errors.textOrientationId }))),
        props.TextAttributes.find(function (el) { return el.type === 4; }) && (React.createElement("div", { className: 'w-full sm:w-1/3' },
            React.createElement(TypographyFieldComponent, { error: errors.typographyId }))),
        props.TextAttributes.find(function (el) { return el.type === 3; }) && (React.createElement("div", { className: 'w-full sm:w-1/3' },
            React.createElement(LabelFieldTextType1ProductComponent, null))),
        props.TextAttributes.find(function (el) { return el.type === 6; }) && (React.createElement("div", { className: 'w-full sm:w-1/3' },
            React.createElement(FontSizeFieldComponent, null))),
        props.TextAttributes.find(function (el) { return el.type === 9; }) && (React.createElement("div", { className: 'w-full sm:w-1/3' },
            React.createElement(EmojiFieldComponent, null)))));
};
export default TextType1ProductComponent;
