import getCart from './getCart';

const dropCart = async index => {
  const currentCart = await getCart();
  if (!Number.isNaN(parseInt(index)) && parseInt(index) >= 0) {
    const newCart = currentCart.filter((el, i) => i !== parseInt(index));
    await localStorage.setItem('ecommerceCart', JSON.stringify(newCart));
    return newCart;
  }
  await localStorage.removeItem('ecommerceCart');
  return [];
};

export default dropCart;
