import React from 'react';
import { Outlet } from 'react-router-dom';
import ProductListComponent from '../../productList';
import CopyrightComponent from '../Copyright';
var MenuEnComponentChime = function () {
    return (React.createElement("div", null,
        React.createElement(ProductListComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden mt-10' },
            React.createElement(Outlet, null)),
        React.createElement(CopyrightComponent, null)));
};
export default MenuEnComponentChime;
