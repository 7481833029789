import React from 'react';
import ItemImageProductImageUtilsComponent from '../../../../../presentationals/ProductImage/Container/Image';
var ItemImageType1ProductComponent = function (props) {
    var handleClick = function () {
        props.onClick(props.fileId);
    };
    return (React.createElement("div", { className: 'border rounded-sm border-solid p-2 mb-2 w-24', style: { borderColor: props.isSelected ? '#787878' : '#dadada' }, role: 'button', onClick: handleClick, tabIndex: -1 },
        React.createElement(ItemImageProductImageUtilsComponent, { fileId: props.fileId, name: props.productName })));
};
export default ItemImageType1ProductComponent;
