import React, { useEffect, useState } from 'react';
import getDateFormat from '../../../../../utils/getDateFormat';
var ItemBodyRoomComponent = function (props) {
    var _a = useState(''), date = _a[0], setDate = _a[1];
    useEffect(function () {
        if (props.sent) {
            var now = getDateFormat(new Date());
            var messageDate = getDateFormat(new Date(props.sent));
            var newDateFormat = now === messageDate ? 'k:mmaaa' : 'dd/MM/yyyy k:mmaaa';
            var newDate = getDateFormat(new Date(props.sent), newDateFormat);
            setDate(newDate);
        }
    }, [props.sent]);
    return (React.createElement("div", { className: "flex px-2 py-1 ".concat(props.position === 'left' ? 'flex-row' : 'flex-row-reverse') },
        React.createElement("div", { className: "p-2 rounded-lg relative ".concat(props.position === 'left' ? 'bg-gray-100' : 'bg-[#b0ecb0]') },
            React.createElement("div", null, props.body),
            React.createElement("div", { className: 'items-end text-xs' }, date)),
        React.createElement("div", { className: 'flex flex-1' })));
};
export default ItemBodyRoomComponent;
