var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import useQueryByCompany from '../../../hooks/useQueryByCompany';
import GQL_PRODUCT from '../../../apollo/gql/product';
import getImages from '../../../utils/getImages';
import LoadingProductsComponents from '../../../presentationals/LoadingProducts';
import ProductImageComponent from '../../../presentationals/ProductImage';
var RandomProductComponent = function () {
    var _a = useQueryByCompany(GQL_PRODUCT.GET_RANDOM_PRODUCTS, {
        limit: 24,
        offset: 0,
    }), data = _a.data, loading = _a.loading;
    var _b = useState([]), products = _b[0], setProducts = _b[1];
    useEffect(function () {
        if (data) {
            var newProducts = data.map(function (el) {
                var Images = getImages({
                    ProductImages: el.ProductFiles,
                    ProductPresentation: el.ProductPresentations,
                    ProductAttributes: el.ProductAttributes,
                });
                return __assign(__assign({}, el), { Images: Images, Colors: el.ProductAttributes.filter(function (el) { return el.type === 1; }) });
            });
            setProducts(newProducts);
        }
    }, [data]);
    if (loading)
        return React.createElement(LoadingProductsComponents, { className: 'sm:p-20', cols: 4, rows: 5 });
    return (React.createElement("div", { className: 'bg-white p-4 sm:p-20 grid grid-cols-2 sm:grid-cols-4 gap-10' }, products.map(function (el) { return (React.createElement(ProductImageComponent, { key: el.id, Product: el })); })));
};
export default RandomProductComponent;
