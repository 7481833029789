import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Stepper from './Stepper';
import Form from './Form';
import Action from './Action';
import Cart from './Cart';
import LegalInformation from './LegalInformation';
import { ECCustomerSessionData, ECPaymentForm, ECPaymentStep, ECShipping, inputError, } from '../../../apollo/cache';
import getDefaultEcPaymentValues from '../helpers/defaultValues';
var GridComponent = styled(Grid)(function (_a) {
    var theme = _a.theme;
    return ({
        '&.root': {
            borderBottom: '1px solid #e2e2e2',
            flex: 1,
            display: 'flex',
        },
        '& .cartContainer': {
            backgroundColor: '#f5f5f5',
            borderLeft: '1px solid #d5d5d5',
        },
        '& .formContainer': {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        '& .spacing': {
            padding: theme.spacing(3),
        },
    });
});
var BodyPaymentComponent = function () {
    useEffect(function () {
        return function () {
            ECShipping(0);
            ECCustomerSessionData(null);
            inputError({});
            var defaultValues = getDefaultEcPaymentValues();
            ECPaymentForm(defaultValues);
            ECPaymentStep(0);
        };
    }, []);
    return (React.createElement(GridComponent, { container: true, className: 'root' },
        React.createElement(Grid, { item: true, xs: 12, md: 7, className: 'formContainer' },
            React.createElement("div", { className: 'spacing formContainer' },
                React.createElement(Stepper, null),
                React.createElement(Form, null),
                React.createElement("div", null,
                    React.createElement(Action, null))),
            React.createElement("div", null,
                React.createElement(LegalInformation, null))),
        React.createElement(Grid, { item: true, xs: 12, md: 5, className: 'cartContainer spacing' },
            React.createElement(Cart, null))));
};
export default BodyPaymentComponent;
