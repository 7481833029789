var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import useApolloLazyQueryByCompany from '../../../../hooks/useLazyQueryByCompany';
import CHAT_GQL from '../../../../apollo/gql/chat';
import socket from '../../../../socket';
import getChatSession from '../../../../utils/getChatSession';
import getInputErrorFormat from '../../../../utils/getInputErrorFormat';
import ItemBodyRoomComponent from './Item';
var BodyRoomChatComponent = function () {
    var apolloClient = useApolloClient();
    var messagesEndRef = useRef(null);
    var _a = useApolloLazyQueryByCompany(CHAT_GQL.GET_BUCKET), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    var scrollToBottom = function () {
        var _a;
        (_a = messagesEndRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'start',
        });
    };
    useEffect(function () {
        if (data)
            scrollToBottom();
    }, [data]);
    useEffect(function () {
        socket.on('message', function (data) {
            try {
                var session_1 = getChatSession();
                apolloClient.cache.modify({
                    fields: {
                        roomBucket: function (cachedRoomBucket, _a) {
                            var storeFieldName = _a.storeFieldName, fieldName = _a.fieldName;
                            var args = JSON.parse(storeFieldName.replace("".concat(fieldName, "("), '').slice(0, -1));
                            if (args.room !== (session_1 === null || session_1 === void 0 ? void 0 : session_1.publicUserRoom))
                                return cachedRoomBucket;
                            var newRef = apolloClient.cache.writeFragment({
                                data: {
                                    id: data.id,
                                    sent: data.sent,
                                    from: data.from,
                                    name: data.name,
                                    body: data.body,
                                    position: data.position,
                                    __typename: 'Message',
                                },
                                fragment: CHAT_GQL.FRAGMENT_MESSAGE,
                            });
                            return __assign(__assign({}, cachedRoomBucket), { messages: __spreadArray(__spreadArray([], cachedRoomBucket.messages, true), [newRef], false) });
                        },
                    },
                });
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        });
    }, []);
    useEffect(function () {
        var session = getChatSession();
        if (!session)
            return;
        getData({
            variables: {
                wsToken: session.publicUserToken,
                room: session.publicUserRoom,
            },
        });
    }, []);
    return (React.createElement("div", { className: 'flex flex-1 overflow-y-auto bg-gray-50 rounded flex-col border border-gray-300 border-solid p-1' },
        loading && React.createElement("div", null, "Cargando mensajes..."), data === null || data === void 0 ? void 0 :
        data.messages.map(function (el) { return (React.createElement(ItemBodyRoomComponent, { key: el.id, body: el.body, position: el.position, sent: el.sent })); }),
        React.createElement("div", { ref: messagesEndRef })));
};
export default BodyRoomChatComponent;
