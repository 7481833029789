var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import getNewToken from './helpers/getNewToken';
import getNewWsToken from './helpers/getNewWsToken';
var errorLink = onError(function (_a) {
    var _b, _c, _d;
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation, forward = _a.forward;
    if (graphQLErrors) {
        var error = graphQLErrors[0];
        if (error.extensions.code === 'UNAUTHENTICATED')
            return new Observable(function (observer) {
                (function () { return __awaiter(void 0, void 0, void 0, function () {
                    var oldHeaders_1, newToken_1, subscriber, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                oldHeaders_1 = operation.getContext().headers;
                                return [4 /*yield*/, getNewToken()];
                            case 1:
                                newToken_1 = _a.sent();
                                operation.setContext(function () { return ({
                                    headers: __assign(__assign({}, oldHeaders_1), { authorization: newToken_1 }),
                                }); });
                                subscriber = {
                                    next: observer.next.bind(observer),
                                    error: observer.error.bind(observer),
                                    complete: observer.complete.bind(observer),
                                };
                                forward(operation).subscribe(subscriber);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                console.log('ErrorLink: ', e_1);
                                observer.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
            });
    }
    if (graphQLErrors &&
        graphQLErrors[0] &&
        graphQLErrors[0].message &&
        graphQLErrors[0].message.includes('wsToken'))
        try {
            getNewWsToken();
        }
        catch (e) {
            console.log("WS_ERROR: ".concat(e.message));
        }
    if (networkError) {
        var serverError = networkError;
        if (((_b = serverError.result) === null || _b === void 0 ? void 0 : _b.errors) &&
            ((_c = serverError.result) === null || _c === void 0 ? void 0 : _c.errors.length) > 0 &&
            ((_d = serverError.result) === null || _d === void 0 ? void 0 : _d.errors[0].message.includes('wsToken')))
            try {
                getNewWsToken();
            }
            catch (e) {
                console.log("WS_ERROR: ".concat(e.message));
            }
    }
    console.log("[Network error]: ".concat(JSON.stringify(networkError)));
});
export default errorLink;
