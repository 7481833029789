var getDefaultEcPaymentValues = function () { return ({
    name: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    isPickupActive: false,
    state: '',
    zipCode: '',
    municipality: '',
    reference: '',
    deliveryDate: null,
}); };
export default getDefaultEcPaymentValues;
