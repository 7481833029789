import React from 'react';
import { isECSessionOpen } from '../../../../../../../../apollo/cache';
import CloseIconButtonUtilsComponent from '../../../../../../../../utils/CloseIconButton';
var CloseIconButtonDialogSessionActionNavigationSecondaryContentComponent = function () {
    var handleClose = function () {
        isECSessionOpen(false);
    };
    return React.createElement(CloseIconButtonUtilsComponent, { onClose: handleClose });
};
export default CloseIconButtonDialogSessionActionNavigationSecondaryContentComponent;
