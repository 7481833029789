import React from 'react';

import { Typography, Link } from '@mui/material';

const CopyrightUtilsComponent = () => {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='#'>
        Encanta Cln
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
};

export default CopyrightUtilsComponent;
