var getFonts = function () { return [
    'Arial',
    'BrittanySignature',
    'Bufally',
    'Aladin',
    'Belmist',
    'CandyBeans',
    'CaviarDreams',
    'CaviarDreamsBold',
    'ChelseaOlivia',
    'DKFledermaus',
    'Dulcelin',
    'Etienne',
    'Freshman',
    'GoboldBoldItalic',
    'GoPanda',
    'GunplayRg',
    'JerseyM54',
    'LaBeauties',
    'Lobster1.4',
    'Marlina',
    'Marthin',
    'MidnightBangkok',
    'MomcakeBold',
    'MomcakeThin',
    'MyfridaBold',
    'NuCenturyGothic',
    'Paladins',
    'Retroica',
    'Santana',
    'Southmore',
    'Warmesty',
]; };
export default getFonts;
