import React from 'react';
import { Button as ButtonMui } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { isECCartOpen } from '../../../../../../../../../apollo/cache';
var Button = styled(ButtonMui)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2, 8, 2, 8),
        backgroundColor: '#00bb2d',
        color: '#fff',
        fontSize: 16,
        '&:hover': {
            backgroundColor: '#009424', //'#474747',
        },
    });
});
var PayButtonFooterDialogCartActionNavigationSecondaryContentComponent = function () {
    var navigate = useNavigate();
    var handlePay = function () {
        navigate('payment');
        isECCartOpen(false);
    };
    return (React.createElement(Button, { variant: 'contained', onClick: handlePay }, "Ir a Pagar"));
};
export default PayButtonFooterDialogCartActionNavigationSecondaryContentComponent;
