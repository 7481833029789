import GET_EC_CLASSIFICATION_BY_ID from './getEcClassificationById';
import GET_EC_CLASSIFICATIONS from './getEcClassifications';
import GET_EC_COMPANY from './getEcCompany';
import GET_EC_PRODUCTS from './getEcProducts';
var GQL_ECOMMERCE = {
    GET_EC_CLASSIFICATION_BY_ID: GET_EC_CLASSIFICATION_BY_ID,
    GET_EC_CLASSIFICATIONS: GET_EC_CLASSIFICATIONS,
    GET_EC_COMPANY: GET_EC_COMPANY,
    GET_EC_PRODUCTS: GET_EC_PRODUCTS,
};
export default GQL_ECOMMERCE;
