var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Button } from '@mui/material';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import { inputError } from '../../../../apollo/cache';
var useCfdiOptions = [
    { value: 'G01', label: 'Adquisición de mercancias' },
    { value: 'G03', label: 'Gastos en general' },
    { value: 'P01', label: 'Por definir' },
];
var paymentFormOptions = [
    { value: '01', label: 'Efectivo' },
    { value: '04', label: 'Tarjeta de crédito' },
    { value: '28', label: 'Tarjeta de débito' },
];
var defaultValues = {
    useCfdi: 'G03',
    paymentForm: '04',
    businessName: '',
    identifier: '',
    code: '',
};
var FormInvoicingStaticInformationComponent = function () {
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleCreate = function () {
        console.log('Hey');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TextFieldComponent, { name: 'code', label: 'Folio de Compra', autoFocus: true, onChange: handleChange, value: values.code, error: errors.code, size: 'medium' }),
        React.createElement(TextFieldComponent, { name: 'businessName', label: 'Raz\u00F3n Social', onChange: handleChange, value: values.businessName, error: errors.businessName, size: 'medium' }),
        React.createElement(TextFieldComponent, { name: 'identifier', label: 'RFC', variant: 'outlined', onChange: handleChange, value: values.identifier, error: errors.identifier, size: 'medium' }),
        React.createElement(SelectFieldComponent, { label: 'Uso de CFDi', name: 'useCfdi', value: values.useCfdi, onChange: handleChange, error: errors.useCfdi, options: useCfdiOptions, size: 'medium' }),
        React.createElement(SelectFieldComponent, { label: 'Forma de Pago', name: 'paymentForm', value: values.paymentForm, error: errors.paymentForm, size: 'medium', onChange: handleChange, options: paymentFormOptions }),
        React.createElement(Button, { variant: 'contained', onClick: handleCreate, className: 'mt-1 py-3 px-8 bg-[#333] hover:bg-[#474747] text-base', size: 'large' }, "Emitir CFDi")));
};
export default FormInvoicingStaticInformationComponent;
