import { Button } from '@mui/material';
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { extraProductPrice, inputError, type2ProductCart } from '../../../../../apollo/cache';
import getInputErrorFormat from '../../../../../utils/getInputErrorFormat';
import validateProductInputs from '../../../Type1/AddToCartButton/helpers/validateProductInputs';
import getProductPrice from '../../../Type1/helpers/getProductPrice';
import setAddedAttributesArray from '../../../../../utils/setAddedAttributesArray';
var ContinueStep1Type2ProductComponent = function (props) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var extraProductPriceVar = useReactiveVar(extraProductPrice);
    var paramProductPresentationId = searchParams.get('productPresentationId');
    var typographyId = searchParams.get('typographyId');
    var textOrientationId = searchParams.get('textOrientationId');
    var colorId = searchParams.get('colorId');
    var secondaryColorId = searchParams.get('secondaryColorId');
    var label = searchParams.get('label');
    var sizeId = searchParams.get('sizeId');
    var fontSize = searchParams.get('fontSize');
    var typeId = searchParams.get('typeId');
    var emoji = searchParams.get('emoji');
    var emojiPosition = searchParams.get('emojiPosition');
    var handleAction = function () {
        try {
            inputError({});
            var productPresentationId_1 = paramProductPresentationId || props.Product.ProductPresentations[0].id;
            var ProductPresentation = props.Product.ProductPresentations.find(function (el) { return el.id === productPresentationId_1; });
            validateProductInputs({
                productPresentationId: productPresentationId_1,
                typographyId: typographyId,
                textOrientationId: textOrientationId,
                colorId: colorId,
                secondaryColorId: secondaryColorId,
                sizeId: sizeId,
                typeId: typeId,
                label: label,
                SelectedProductIngredients: extraProductPriceVar,
            }, props.Product);
            if (!ProductPresentation)
                return;
            var AttributesAdded = setAddedAttributesArray({
                color: colorId,
                typography: typographyId,
                secondaryColor: secondaryColorId,
                emoji: emoji,
                emojiPosition: emojiPosition,
                orientation: parseInt(textOrientationId || '2'),
                text: label || '',
                size: parseInt(fontSize || '30'),
            }, props.Product.ProductAttributes);
            var price = getProductPrice(ProductPresentation.price, extraProductPriceVar);
            type2ProductCart([
                {
                    productIngredientId: null,
                    productId: props.Product.id,
                    productPresentationId: ProductPresentation.id,
                    productName: props.Product.name,
                    productPresentationName: ProductPresentation.name,
                    ProductAttributes: AttributesAdded,
                    fileId: ProductPresentation.ProductPresentationFiles.length > 0
                        ? ProductPresentation.ProductPresentationFiles[0].fileId
                        : null,
                    step: 0,
                    price: price,
                },
            ]);
            setSearchParams({});
            props.onClick();
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(Button, { className: 'px-8 py-4  bg-[#00bb2d] text-white text-base hover:bg-[#009424]', onClick: handleAction }, "Continuar"));
};
export default ContinueStep1Type2ProductComponent;
