import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
var DescriptionType1ProductComponent = function (props) {
    var _a = useState([]), description = _a[0], setDescription = _a[1];
    useEffect(function () {
        if (props.description) {
            var description_1 = props.description.trim().split('-');
            setDescription(description_1);
        }
    }, [props.description]);
    return (React.createElement(Accordion, { className: 'shadow-none border-0 mt-6 sm:w-2/3' },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, { className: 'p-0 m-0' }), "aria-controls": 'panel1a-content', id: 'panel1a-details', classes: {
                content: 'm-0',
            } },
            React.createElement(Typography, { variant: 'body1', className: 'font-medium' }, "Detalles del producto")),
        React.createElement(AccordionDetails, { className: 'pt-0 mt-0' },
            React.createElement("ul", { className: 'm-0 pl-8' }, description.map(function (label, i) {
                return label.trim().length > 0 && (React.createElement("li", { key: i, className: 'm-0 p-0' },
                    React.createElement(Typography, { variant: 'body2' }, label)));
            })))));
};
export default DescriptionType1ProductComponent;
