import React, { useState } from 'react';
import socket from '../../../../socket';
import getChatSession from '../../../../utils/getChatSession';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import Button from '../../../../presentationals/Button';
var FormRoomChatComponent = function () {
    var _a = useState(''), message = _a[0], setMessage = _a[1];
    var handleClick = function () {
        if (message === '')
            return;
        var session = getChatSession();
        if (!session)
            return;
        socket.emit('sendMessage', { room: session.publicUserRoom, body: message });
        setMessage('');
    };
    var handleChange = function (name, value) {
        setMessage(value);
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter')
            handleClick();
    };
    return (React.createElement("div", { className: 'flex mt-2' },
        React.createElement(TextFieldComponent, { defaultLabel: 'Escribir mensaje', value: message, onChange: handleChange, onKeyDown: handleKeyDown, size: 'medium', autoFocus: true, noPadding: true }),
        React.createElement(Button, { className: 'ml-2', color: 'success', isLoading: false, onClick: handleClick, label: 'Enviar' })));
};
export default FormRoomChatComponent;
