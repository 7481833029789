import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ItemProductPresentationType1ProductComponent from './Item';
import SelectProductPresentationField from './SelectField';
import setSearchQueryParam from '../../../../utils/setSearchQueryParam';
import ErrorAttributeMessageComponent from '../helpers/ErrorAttributeMessage';
import { extraProductPrice, inputError } from '../../../../apollo/cache';
var ProductPresentationType1ProductComponent = function (props) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var productPresentationId = searchParams.get('productPresentationId');
    var _b = useState([]), ProductPresentations = _b[0], setProductPresentations = _b[1];
    useEffect(function () {
        var ProductPresentations = props.ProductPresentations.length > 1
            ? props.ProductPresentations.filter(function (el) { return el.name !== 'PIEZA'; })
            : props.ProductPresentations;
        setProductPresentations(ProductPresentations);
        if (ProductPresentations.length > 0) {
            var ProductPresentation_1 = ProductPresentations[0];
            setTimeout(function () {
                setSearchParams(setSearchQueryParam('productPresentationId', ProductPresentation_1.id.toString(), searchParams));
            }, 300);
            props.onChange(ProductPresentation_1);
        }
    }, [props.ProductPresentations]);
    var handleClick = function (ProductPresentation) {
        inputError({});
        extraProductPrice([]);
        props.onChange(ProductPresentation);
        setSearchParams(setSearchQueryParam('productPresentationId', ProductPresentation.id.toString(), searchParams));
    };
    if (ProductPresentations.length <= 1)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'text-xl tracking-tighter mb-2' }, "Selecciona una presentaci\u00F3n"),
        ProductPresentations.length < 13 ? (React.createElement("div", { className: 'flex flex-wrap' }, ProductPresentations.map(function (el) { return (React.createElement(ItemProductPresentationType1ProductComponent, { isSelected: el.id === productPresentationId, key: el.id, label: el.name, params: el, onClick: handleClick })); }))) : (React.createElement("div", { className: 'w-1/3' },
            React.createElement(SelectProductPresentationField, { value: productPresentationId, options: ProductPresentations, onChange: handleClick }))),
        React.createElement(ErrorAttributeMessageComponent, { message: props.error })));
};
export default ProductPresentationType1ProductComponent;
