import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import IconButton from '@mui/material/IconButton';
import { isECSessionOpen } from '../../../../../../apollo/cache';
var SessionButtonNavigationComponent = function () {
    var handleClick = function () {
        isECSessionOpen(true);
    };
    return (React.createElement(IconButton, { onClick: handleClick },
        React.createElement(AccountCircleIcon, null)));
};
export default SessionButtonNavigationComponent;
