import React, { useEffect } from 'react';
import useLazyQueryByCompany from '../../../../hooks/useLazyQueryByCompany';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import LoadingProductComponent from '../../Loading';
import Type1ProductComponent from '../../Type1';
import ContinueStep2Type2ProductComponent from './ContinueButton';
var Step2Type2ProductComponent = function (props) {
    var _a = useLazyQueryByCompany(GQL_PRODUCT.GET_PRODUCT_BY_ID), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (props.ProductIngredient)
            getData({ variables: { id: props.ProductIngredient.Ingredient.id } });
    }, [props.ProductIngredient]);
    if (loading || !data)
        return (React.createElement("div", { className: 'flex flex-1' },
            React.createElement(LoadingProductComponent, null)));
    return (React.createElement(Type1ProductComponent, { Product: data, action: React.createElement(ContinueStep2Type2ProductComponent, { currentStep: props.currentStep, totalStep: props.totalStep, onClick: props.onContinue, Product: data }) }));
};
export default Step2Type2ProductComponent;
