var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useParams } from 'react-router-dom';
import GQL_ECOMMERCE from '../../../../apollo/gql/ecommerce';
// import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import ProductItemProductListComponent from './ProductItem';
import useApolloQuery from '../../../../hooks/useApolloQuery';
var ClassificationItemProductListComponent = function () {
    var params = useParams();
    var data = useApolloQuery(GQL_ECOMMERCE.GET_EC_PRODUCTS, {
        variables: {
            ecName: 'lachime',
            classificationId: params.classificationId,
            order: ['name', 'asc'],
            limit: null,
        },
    }).data;
    console.log(data);
    // if (!loading) return <LoadingProgress darkTheme />;
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'grid grid-cols-2 sm:grid-cols-4 sm:col-span-6 my-3' }, data && data.map(function (el) { return React.createElement(ProductItemProductListComponent, __assign({ key: el.id }, el)); }))));
};
export default ClassificationItemProductListComponent;
