import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/system';
import { Drawer as DrawerMui, Typography } from '@mui/material';
import { isECSessionOpen } from '../../../../../../../apollo/cache';
import CloseIconButton from './CloseIconButton';
import LoginComponent from './Login';
import SignupComponent from './Signup';
var Drawer = styled(DrawerMui)(function (_a) {
    var _b;
    var theme = _a.theme;
    return ({
        '& .paper': (_b = {
                width: '30%',
                boxShadow: 'none',
                display: 'flex'
            },
            _b[theme.breakpoints.down('sm')] = {
                width: '100%',
            },
            _b),
        '& .header': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottom: '1px solid #dadada',
            alignItems: 'center',
            padding: theme.spacing(2, 2, 1, 2),
        },
    });
});
var SessionDialogComponent = function () {
    var isECSessionOpenVar = useReactiveVar(isECSessionOpen);
    var handleClose = function () {
        isECSessionOpen(false);
    };
    return (React.createElement(Drawer, { anchor: 'right', open: isECSessionOpenVar, onClose: handleClose, classes: {
            paperAnchorRight: 'paper',
        } },
        React.createElement("div", { className: 'header' },
            React.createElement(Typography, { variant: 'h6' }, "Iniciar sesi\u00F3n"),
            React.createElement(CloseIconButton, null)),
        React.createElement(LoginComponent, { isDialog: true }),
        React.createElement(SignupComponent, null)));
};
export default SessionDialogComponent;
