import React from 'react';
import { useParams, Link } from 'react-router-dom';
var NavigationLogoComponent = function (props) {
    var params = useParams();
    return (React.createElement(Link, { to: "../".concat(params.companyName ? params.companyName : '') },
        React.createElement("img", { src: props.src, alt: "navbar-company-".concat(params.companyName), loading: 'lazy', style: {
                height: props.height,
            } })));
};
export default NavigationLogoComponent;
