import { setCurrentProductIdDialog } from '../../../../../../../apollo/cache';
var getMarginLeftByProductId = function (productId, _a) {
    var isSmallScreen = _a.isSmallScreen;
    var customizedProductId = setCurrentProductIdDialog();
    switch ((customizedProductId[0] || productId || '').toString()) {
        case '20784':
            return isSmallScreen ? 80 : 100;
        case '20864':
            return 20;
        case '20782':
            return 30;
        case '20785':
            return isSmallScreen ? 10 : 0;
        case '20905':
            return isSmallScreen ? 10 : 0;
        case '21078':
            return isSmallScreen ? 160 : 230;
        case '20902':
            return isSmallScreen ? 160 : 230;
        case '20906':
            return isSmallScreen ? 50 : 60;
        case '22472':
            return 10;
        default:
            return 0;
    }
};
export default getMarginLeftByProductId;
