import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ItemProductVariationComponent from './Item';
import ErrorAttributeMessageComponent from '../helpers/ErrorAttributeMessage';
var ProductVariationsType1ProductComponent = function (props) {
    var searchParams = useSearchParams()[0];
    var productPresentationId = searchParams.get('productPresentationId');
    var _a = useState([]), productVariations = _a[0], setProductVariations = _a[1];
    useEffect(function () {
        if (props.ProductPresentations && productPresentationId) {
            var ProductPresentation = props.ProductPresentations.find(function (el) { return el.id === productPresentationId; });
            if (!ProductPresentation)
                return;
            setProductVariations(ProductPresentation.ProductVariations);
        }
    }, [props.ProductPresentations, productPresentationId]);
    return (React.createElement("div", { className: 'flex flex-col' },
        productVariations.map(function (el) { return (React.createElement(ItemProductVariationComponent, { key: el.id, ProductVariation: el })); }),
        React.createElement(ErrorAttributeMessageComponent, { message: props.error })));
};
export default ProductVariationsType1ProductComponent;
