import React from 'react';
import useQueryByCompany from '../../../../../hooks/useQueryByCompany';
import GQL_CLASSIFICATION from '../../../../../apollo/gql/classification';
import ItemClassificationNavigationComponent from './Item';
import LoadingClassificationNavigationComponent from './LoadingClassificationNavigation';
var ClassificationNavigationComponent = function () {
    var _a = useQueryByCompany(GQL_CLASSIFICATION.GET_CLASSIFICATIONS), data = _a.data, loading = _a.loading;
    return (React.createElement("div", { className: 'hidden sm:flex flex-1 items-center justify-center flex-wrap' }, loading || !data ? (React.createElement(LoadingClassificationNavigationComponent, null)) : (data.map(function (el) { return React.createElement(ItemClassificationNavigationComponent, { key: el.id, Classification: el }); }))));
};
export default ClassificationNavigationComponent;
