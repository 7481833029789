import React from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Link as LinkMui, Typography as TypographyMui } from '@mui/material';
import { isECSearchOpen } from '../../../../../../../../apollo/cache';
import currencyFormat from '../../../../../../../../utils/currencyFormat';
import ItemImageProductImageUtilsComponent from '../../../../../../../../presentationals/ProductImage/Container/Image';
var Typography = styled(TypographyMui)({
    lineHeight: 1,
});
var Link = styled(LinkMui)(function (_a) {
    var theme = _a.theme;
    return ({
        '&.root': {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            padding: theme.spacing(0.5),
            '&:hover': {
                backgroundColor: '#f5f5f5',
            },
        },
        '& .imageContainer': {
            width: 90,
            height: 90,
            overflow: 'hidden',
        },
        '& .infoContainer': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: theme.spacing(0.5),
        },
    });
});
var OptionSearchProductDialogComponent = function (props) {
    var navigation = useNavigate();
    var handleClick = function (e) {
        e.preventDefault();
        navigation("product/".concat(props.id));
        isECSearchOpen(false);
    };
    return (React.createElement(Link, { color: 'inherit', className: 'root', onClick: handleClick },
        React.createElement("div", { className: 'imageContainer' },
            React.createElement(ItemImageProductImageUtilsComponent, { fileId: props.Files[0], name: props.name })),
        React.createElement("div", { className: 'infoContainer' },
            React.createElement(Typography, { variant: 'body2' }, props.name),
            React.createElement(Typography, { variant: 'button' },
                React.createElement("strong", null,
                    "MXN ",
                    currencyFormat(props.price))))));
};
export default OptionSearchProductDialogComponent;
