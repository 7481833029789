import { setCurrentProductIdDialog } from '../../../../../../../apollo/cache';
var getMarginRightByProductId = function (productId, _a) {
    var isSmallScreen = _a.isSmallScreen;
    var customizedProductId = setCurrentProductIdDialog();
    switch ((customizedProductId[0] || productId || '').toString()) {
        case '20782':
            return 50;
        case '20864':
            return 40;
        case '20785':
            return 20;
        case '20783':
            return 10;
        case '20780':
            return isSmallScreen ? 5 : 10;
        case '20867':
            return isSmallScreen ? 50 : 60;
        case '21068':
            return isSmallScreen ? 70 : 80;
        case '21067':
            return isSmallScreen ? 70 : 100;
        default:
            return 0;
    }
};
export default getMarginRightByProductId;
