import React from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
var SignedUpDialogSignupSessionEcommerce = function (props) {
    var navigate = useNavigate();
    var handleClick = function () {
        navigate('login');
    };
    return (React.createElement(Dialog, { open: props.isOpen },
        React.createElement("div", { className: 'p-12' },
            React.createElement(Typography, { variant: 'h4', gutterBottom: true }, "Tu usuario fue creado exitosamente."),
            React.createElement(Typography, { variant: 'body1', className: 'text-lg mb-4' }, "Ahorita podr\u00E1s iniciar sesi\u00F3n, dar seguimiento a tus pedidos y obtener beneficios exclusivos."),
            React.createElement(Button, { variant: 'contained', color: 'success', className: 'px-8 py-4', onClick: handleClick }, "Ir a Iniciar sesi\u00F3n"))));
};
export default SignedUpDialogSignupSessionEcommerce;
