var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/system';
import { Grid, Typography, Card, Divider, FormControlLabel, RadioGroup, Collapse, Radio, Switch, } from '@mui/material';
import CONFIG from '../../../../../config/config.json';
import { ECPaymentForm, ECPaymentFormSelected, inputError } from '../../../../../apollo/cache';
import getCountryCode from '../../../../../utils/getCountryCode';
import getAddressFormat from '../../../../../utils/getAddressFormat';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
var GridContainer = styled(Grid)(function (_a) {
    var _b, _c;
    var theme = _a.theme;
    return ({
        '&.root': {
            padding: theme.spacing(0, 4, 0, 4),
        },
        '& .spaceBetween': {
            marginTop: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& .addButton': (_b = {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(3),
                padding: theme.spacing(1.5, 6, 1.5, 6),
                backgroundColor: '#2196f3',
                boxShadow: 'none',
                color: '#fff',
                fontSize: 14,
                '&:hover': {
                    backgroundColor: '#0e8df2',
                }
            },
            _b[theme.breakpoints.down('sm')] = {
                width: '100%',
            },
            _b),
        '& .footer': (_c = {
                display: 'flex',
                padding: theme.spacing(2, 4, 3, 4),
                textAlign: 'right',
                alignItems: 'center',
                justifyContent: 'space-between'
            },
            _c[theme.breakpoints.down('sm')] = {
                display: 'flex',
                flexDirection: 'column-reverse',
                justifyContent: 'normal',
                marginBottom: theme.spacing(5),
            },
            _c),
        '& .linkBack': {
            display: 'flex',
            alignItems: 'center',
            color: '#808080',
        },
        '& .subtitle': {
            color: '#808080',
        },
        '& .gridContainer': {
            padding: theme.spacing(1),
        },
        '& .gridShippingContainer': {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        '& .gripOptionContainer': {
            padding: theme.spacing(2),
            backgroundColor: '#fafafa',
        },
        '& .formLabel': {
            fontSize: 14,
        },
        '& .cardError': {
            borderColor: '#ff0000',
        },
        '& .labelError': {
            marginTop: theme.spacing(1),
            color: '#ff0000',
        },
        '& .img': {
            width: 80,
        },
        '& .greenText': {
            color: '#006c00',
        },
        '&. conektaForm': {
            width: '100%',
            height: 700,
            backgroundColor: '#ff0000',
        },
    });
});
var useCfdiOptions = [
    { value: 'G01', label: 'Adquisición de mercancias' },
    { value: 'G03', label: 'Gastos en general' },
    { value: 'P01', label: 'Por definir' },
];
var cardPaymentFormOptions = [
    { value: '04', label: 'Tarjeta de crédito' },
    { value: '28', label: 'Tarjeta de débito' },
    { value: '06', label: 'Dinero Electrónico' },
];
var cashPaymentFormOptions = [{ value: '01', label: 'Efectivo' }];
var defaultValues = {
    payment: '',
    name: '',
    number: '',
    expMonth: '',
    expYear: '',
    cvc: '',
    focused: '',
    bussinesName: '',
    identifier: '',
    useCfdi: 'G03',
    paymentForm: '04',
    userCreditCardId: null,
    coupon: '',
};
var Form3BodyPaymentComponent = function () {
    var ECPaymentFormVar = useReactiveVar(ECPaymentForm);
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useState(false), bill = _b[0], setBill = _b[1];
    var _c = useState(cardPaymentFormOptions), paymentFormOptions = _c[0], setPaymentFormOptions = _c[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
        if (name === 'userCreditCardId')
            setValues(function (values) { return (__assign(__assign({}, values), { cvc: '' })); });
        if (name === 'payment') {
            ECPaymentFormSelected(value);
            switch (parseInt(value)) {
                case 2:
                    setPaymentFormOptions(cashPaymentFormOptions);
                    setValues(function (values) { return (__assign(__assign({}, values), { paymentForm: '01' })); });
                    break;
                case 4:
                    setValues(function (values) { return (__assign(__assign({}, values), { paymentForm: '06' })); });
                    break;
                default:
                    setPaymentFormOptions(cardPaymentFormOptions);
                    setValues(function (values) { return (__assign(__assign({}, values), { paymentForm: '04' })); });
                    break;
            }
        }
    };
    var handleRadioChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        handleChange(name, value);
    };
    var handleSwitchChange = function (e) {
        setBill(function (bill) { return !bill; });
        if (e.target.checked)
            setValues(function (values) { return (__assign(__assign({}, values), { bussinesName: '', identifier: '' })); });
    };
    return (React.createElement(GridContainer, { container: true, className: 'root', alignItems: 'center', spacing: 1 },
        React.createElement(Grid, { item: true, xs: 12, className: 'spaceBetween' },
            React.createElement(Typography, { variant: 'h6' }, "Informaci\u00F3n del contacto")),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Card, { variant: 'outlined' },
                React.createElement(Grid, { container: true, className: 'gridContainer' },
                    React.createElement(Grid, { item: true, md: 2, xs: 3 },
                        React.createElement(Typography, { variant: 'body2', className: 'subtitle' }, "Contacto")),
                    React.createElement(Grid, { item: true, md: 10, xs: 9 },
                        React.createElement(Typography, { variant: 'body2' }, ECPaymentFormVar.name))),
                React.createElement(Divider, null),
                React.createElement(Grid, { container: true, className: 'gridContainer' },
                    React.createElement(Grid, { item: true, md: 2, xs: 3 },
                        React.createElement(Typography, { variant: 'body2', className: 'subtitle' }, "Tel\u00E9fono")),
                    React.createElement(Grid, { item: true, md: 10, xs: 9 },
                        React.createElement(Typography, { variant: 'body2' }, "".concat(getCountryCode(ECPaymentFormVar.country)).concat(ECPaymentFormVar.phone)))),
                ECPaymentFormVar.addressLine1.trim() !== '' && (React.createElement(React.Fragment, null,
                    React.createElement(Divider, null),
                    React.createElement(Grid, { container: true, className: 'gridContainer' },
                        React.createElement(Grid, { item: true, md: 2, xs: 3 },
                            React.createElement(Typography, { variant: 'body2', className: 'subtitle' }, "Enviar a")),
                        React.createElement(Grid, { item: true, md: 10, xs: 9 },
                            React.createElement(Typography, { variant: 'body2' }, getAddressFormat(ECPaymentFormVar)))))))),
        React.createElement(Grid, { item: true, xs: 12, className: 'spaceBetween' },
            React.createElement(Typography, { variant: 'h6' },
                "Forma de Pago ",
                React.createElement("small", null, "Tus datos est\u00E1n encriptados y son seguros"))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Card, { variant: 'outlined' },
                React.createElement(RadioGroup, { "aria-label": 'payment', name: 'payment', value: parseInt(values.payment), onChange: handleRadioChange },
                    React.createElement(Grid, { container: true, justifyContent: 'space-between', alignItems: 'center', className: 'gridShippingContainer' },
                        React.createElement(Grid, { item: true },
                            React.createElement(FormControlLabel, { value: 1, classes: { label: 'formLabel' }, control: React.createElement(Radio, { color: 'primary' }), label: 'Tarjeta de cr\u00E9dito/d\u00E9bito, OXXO o tranferencia bancaria' })),
                        React.createElement(Grid, { item: true },
                            React.createElement("img", { src: "".concat(CONFIG['googleDrive'].fileUri, "1nodFAqZWMbbI74pJJkYR2QRArDy_XCG5"), alt: 'Bank Brands', className: 'img' }))),
                    React.createElement(Collapse, { in: parseInt(values.payment) === 1 },
                        React.createElement(Divider, null),
                        React.createElement(Grid, { container: true, justifyContent: 'space-between', alignItems: 'center', className: 'gripOptionContainer' },
                            React.createElement(Grid, { item: true },
                                React.createElement("p", null, "Una vez que finalices el pedido, podr\u00E1s pagar con tu tarjeta de cr\u00E9dito/d\u00E9bito, en alguna sucursal OXXO o transferencia bancaria por SPEI."),
                                React.createElement("p", null, "El pago se reflejar\u00E1 al instante. No es necesario imprimir el formato.")))),
                    React.createElement(Divider, null),
                    React.createElement(Collapse, { in: parseInt(values.payment) === 2 },
                        React.createElement(Divider, null),
                        React.createElement(Grid, { container: true, justifyContent: 'space-between', alignItems: 'center', className: 'gripOptionContainer' },
                            React.createElement(Grid, { item: true },
                                React.createElement("p", null, "Una vez que finalices el pedido, podr\u00E1s pagar en OXXO con una referencia que te ser\u00E1 proporcionada."),
                                React.createElement("p", null, "Tienes 24 horas para hacer el pago, de lo contrario, el pedido ser\u00E1 cancelado. No es necesario imprimir el formato.")))),
                    React.createElement(Divider, null),
                    React.createElement(Grid, { container: true, justifyContent: 'space-between', alignItems: 'center', className: 'gridShippingContainer' },
                        React.createElement(Grid, { item: true },
                            React.createElement(FormControlLabel, { value: 4, classes: { label: 'formLabel' }, control: React.createElement(Radio, { color: 'primary' }), label: 'PayPal' }))))),
            React.createElement("div", { className: 'labelError' }, errors.payment)),
        React.createElement(Grid, { item: true, xs: 12, className: 'spaceBetween' },
            React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: bill, onChange: handleSwitchChange, name: 'storePickup', color: 'primary' }), label: 'Requiero emitir factura electr\u00F3nica' })),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Collapse, { in: bill },
                React.createElement(Grid, { container: true, spacing: 1 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(TextFieldComponent, { label: 'Raz\u00F3n Social', name: 'bussinesName', error: errors.bussinesName, value: values.bussinesName, onChange: handleChange })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(TextFieldComponent, { label: 'RFC', name: 'identifier', error: errors.identifier, value: values.identifier, onChange: handleChange })),
                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                        React.createElement(SelectFieldComponent, { label: 'Uso de CFDi', name: 'useCfdi', value: values.useCfdi, onChange: handleChange, options: useCfdiOptions, size: 'small' })),
                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                        React.createElement(SelectFieldComponent, { label: 'Forma de Pago', name: 'paymentForm', value: values.paymentForm, onChange: handleChange, options: paymentFormOptions, size: 'small' })))))));
};
export default Form3BodyPaymentComponent;
