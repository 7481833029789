import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { type7ProductCart } from '../../../../../apollo/cache';
import currencyFormat from '../../../../../utils/currencyFormat';
import DeleteType7ProductButtonComponent from './DeleteButton';
var Type7CartComponent = function (props) {
    var cart = useReactiveVar(type7ProductCart);
    var _a = useState(0), total = _a[0], setTotal = _a[1];
    useEffect(function () {
        var total = cart.reduce(function (total, el) { return total + el.price; }, 0);
        setTotal(total);
    }, [cart]);
    return (React.createElement("div", { className: 'flex flex-1 flex-col py-2 px-4' },
        React.createElement("div", { className: 'flex flex-1 flex-col' }, props.steps.map(function (stepName, i) { return (React.createElement("div", { key: stepName },
            React.createElement("div", { className: 'mt-2 border-0 border-b border-solid border-[#dadada] font-medium' }, stepName),
            cart.map(function (el, ii) {
                return el.step === i && (React.createElement("div", { key: el.productIngredientId, className: 'flex justify-between py-2' },
                    React.createElement("div", { className: 'flex flex-1' }, "".concat(el.productName, " ").concat(el.productPresentationName)),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: 'mr-1' }, "MXN ".concat(currencyFormat(el.price))),
                        React.createElement(DeleteType7ProductButtonComponent, { productIngredientId: el.productIngredientId, disabled: ii === 0 }))));
            }))); })),
        React.createElement("div", { className: 'flex justify-between text-2xl font-medium' },
            React.createElement("div", null, "Total"),
            React.createElement("div", null, "MXN ".concat(currencyFormat(total))))));
};
export default Type7CartComponent;
