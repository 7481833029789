import { useReactiveVar } from '@apollo/client';
import { Drawer } from '@mui/material';
import React from 'react';
import { isECMenuOpen } from '../../../../apollo/cache';
import CloseIconButtonDialogMenuHeaderMainContentComponent from './CloseButton';
import MenuOptionDialogMenuHeaderMainContentComponent from './Options';
var MenuDialogComponent = function () {
    var isECMenuOpenVar = useReactiveVar(isECMenuOpen);
    var handleClose = function () {
        isECMenuOpen(false);
    };
    return (React.createElement(Drawer, { anchor: 'left', onMouseLeave: handleClose, open: isECMenuOpenVar, onClose: handleClose, classes: { paperAnchorLeft: 'w-full p-4 shadow-none h-screen flex sm:w-2/3' }, ModalProps: { hideBackdrop: true }, PaperProps: { onMouseLeave: handleClose } },
        React.createElement("div", { className: 'flex flex-1' },
            React.createElement("div", { className: 'w-full sm:w-1/2 flex flex-col' },
                React.createElement("div", { className: 'flex flex-row justify-end' },
                    React.createElement(CloseIconButtonDialogMenuHeaderMainContentComponent, null)),
                React.createElement(MenuOptionDialogMenuHeaderMainContentComponent, null)))));
};
export default MenuDialogComponent;
