var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
// import { useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import GQL_ECOMMERCE from '../../../apollo/gql/ecommerce';
// import { QueryEcClassification } from '../../../types/Ecommerce';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ClassificationItemProductListComponent from './ClassificationItem';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import useApolloQuery from '../../../hooks/useApolloQuery';
var BodyProductListComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_ECOMMERCE.GET_EC_PRODUCTS, {
        variables: {
            ecName: 'lachime',
            id: params.classificationId,
        },
    }), data = _a.data, loading = _a.loading;
    console.log(params, data);
    if (loading)
        return React.createElement(LoadingProgress, { darkTheme: true });
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col px-4 sm:px-16 pb-4 sm:pb-16 overflow-auto basis-0' }, data.map(function (el) { return (React.createElement(ClassificationItemProductListComponent, __assign({ key: el.id }, el))); })));
};
export default BodyProductListComponent;
