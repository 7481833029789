import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import IconButtonComponent from '../../../../../../../../presentationals/IconButton';
import TextFieldComponent from '../../../../../../../../presentationals/FormComponents/TextField';
var SearchProductFieldComponent = function (props) {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var handleChange = function (name, value) {
        setValue(value);
    };
    var handleClick = function () {
        props.onSearch(value);
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter')
            handleClick();
    };
    return (React.createElement("div", { className: 'flex flex-row' },
        React.createElement("div", { className: 'flex flex-1' },
            React.createElement(TextFieldComponent, { onKeyDown: handleKeyDown, autoFocus: true, name: 'search', value: value, onChange: handleChange })),
        React.createElement("div", { className: 'flex ml-2' },
            React.createElement(IconButtonComponent, { className: 'bg-green-600 hover:bg-green-800 text-white', onClick: handleClick, icon: React.createElement(SearchIcon, null) }))));
};
export default SearchProductFieldComponent;
